import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Card, Col, Form, Input, notification, Row, Table, Mentions, Icon } from 'antd'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import FormUtils from '../../components/_utils/formUtils'
import { apiUrl as apiurl } from '../../settings'
import { InputBox, formItemLayout, mentionsFeeds, CustomCkEditor, FullCkEditor } from '../../components/_utils/appUtils'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { addEmailTemplate, getSingleEmailTemplate } from './actions'
import moment from 'moment'
import _ from 'lodash'
import { getUrlParams } from '../../routes'
import { hideLoader, showLoader } from '../../modules/actions'
import Request from '../../request'
import MentionInfo from './mentionInfo'

const { Option, getMentions } = Mentions

@Form.create()

class Email extends Component {
  state = {
    edit: false,
    fileKey: moment(),
    fileAttachment: [],
    message: ''
  }

  constructor() {
    super()
  }

  chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files })
    }
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let fd = new FormData()
        valData.message = this.state.message
        fd.append('obj', JSON.stringify(valData))
        _.each(this.state.fileAttachment, (item) => {
          fd.append('attachments', item)
        })
        // fd.append('attachments', this.state.fileAttachment)
        let { success, message } = await dispatch(addEmailTemplate(fd))

        if (success) {
          notification.success({
            message: message
          })
          form.resetFields()
          this.setState({ authors: [], fileKey: moment(), message: '' })
        }
      }
    })
  }

  setFormValues = async (id) => {

    const { dispatch, form } = this.props
    dispatch(showLoader())
    let { error, data } = await dispatch(getSingleEmailTemplate(id))
    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      form.setFieldsValue(data)
      this.setState({ message: data.message })
    }
    dispatch(hideLoader())

  }

  componentDidMount() {
    let data = getUrlParams('template.edit', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setState({
        edit: true,
        id: id
      })
      this.setFormValues(id)
    }
  }

  render() {
    const { edit } = this.state
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue, loading } } = this.props

    const formProps = {
      getFieldDecorator, getFieldValue, apiurl, loading
    }

    const inputSchema = {
      fields: [
        { label: 'Title ', key: 'title', required: true, placeholder: 'Title', span: 24 },
        // { label: 'Subject ', key: 'subject', required: true, placeholder: 'Subject', span: 24 },
        {
          label: 'Subject ', key: 'subject', required: true, span: 24,
          customField: (
            <Form.Item label='Subject'>
              {getFieldDecorator('subject')
              (<Mentions rows='1' placeholder={'Subject'}>
                {mentionsFeeds.map((item, key) => {
                  return (
                    <Option value={item} key={key}>{item}</Option>
                  )
                })}
              </Mentions>)}
            </Form.Item>
          )
        },
        {
          label: 'Message', key: 'message', required: true, placeholder: 'Message', span: 24,
          customField: (
            <>
              <CustomCkEditor title={'Message'}
                              extraBox={(
                                <MentionInfo><a><Icon type={'info-circle'} /></a></MentionInfo>
                              )}
                              feeds={mentionsFeeds}
                              html={this.state.message}
                              onChange={(data) => {
                                this.setState({ message: data })
                              }}
              /></>)
        },
        {
          label: 'document ', key: 'document', span: 24, customField: (
            <InputBox title={`File Attachments`}>
              <Input type={'file'} name={'fileAttachment'} id={'fileAttachment'}
                     className={'form-control'} key={this.state.fileKey} multiple={true}
                     onChange={(e) => {
                       this.chooseDocument(e)
                     }} />
            </InputBox>
          )
        }

      ]
    }
    return (
      <PageHeaderWrapper
        title={edit ? 'Edit Email Template' : 'Add Email Template'}>

        <Card bordered={true}>
          <Form hideRequiredMark style={{ marginTop: 8 }} className={'inputForm'} onSubmit={this.handleSubmit}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                       key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />}
                  </Col>
                )
              })}
              {/*<Col span={24}>*/}
              {/*  {this.state.message}*/}
              {/*  <FullCkEditor title={'Message'}*/}
              {/*                  feeds={feeds}*/}
              {/*                  html={this.state.message}*/}
              {/*                  onChange={(data) => {*/}
              {/*                    this.setState({ message: data })*/}
              {/*                  }}*/}
              {/*  />*/}
              {/*</Col>*/}

            </Row>


            <Form.Item style={{ marginTop: 32 }}>
              <Button type='primary' htmlType='submit' loading={this.props.loading}>
                {edit ? 'UPDATE' : 'SAVE'}
              </Button>
            </Form.Item>
          </Form>
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Email)

