import { apiUrl } from '../../../settings'

export const articleUrl = () => {
  return apiUrl + '/article'
}
export const singleArticleUrl = (id) => {
  return apiUrl + `/article/${id}`
}
export const allArticleUrl = () => {
  return apiUrl + `/allArticle`
}
export const processPaymentUrl = () => {
  return apiUrl + `/processPayment`
}
export const addReviewerInArticleUrl = () => {
  return apiUrl + `/api/addReviewerInArticle`
}
export const getArticleByIdUrl = () => {
  return apiUrl + `/api/getArticleById`
}
export const getMemberHistoryUrl = () => {
  return apiUrl + `/api/getMemberHistory`
}
export const sendReceiveDateUrl = () => {
  return apiUrl + `/api/sendReceiveDate`
}
export const sendCommentsUrl = () => {
  return apiUrl + `/api/sendComments`
}
export const lastArticleNoUrl = () => {
  return apiUrl + `/api/lastArticleNo`
}

export const editReceiveDateUrl = () => {
  return apiUrl + `/api/editReceiveDate`
}

export const commentsToAuthorUrl = () => {
  return apiUrl + `/api/commentsToAuthor`
}
export const ResendCommentsURL = () => {
  return apiUrl + `/api/resendComments`
}
export const reviewerAwardUrl = () => {
  return apiUrl + `/api/reviewerAward`
}
export const RevisedArticleURL = () => {
  return apiUrl + `/api/revisedArticle`
}
export const ChangeReviewerHistoryUrl = () => {
  return apiUrl + `/api/setHistory`
}
export const ResendArticleUrl = () => {
  return apiUrl + `/api/resendArticle`
}
export const ApproveArticleURL = () => {
  return apiUrl + `/api/approveArticle`
}
export const AcceptanceArticleURL = () => {
  return apiUrl + `/api/acceptanceArticle`
}
export const ComposeArticleURL = () => {
  return apiUrl + `/api/composeArticle`
}
export const GalleyProofArticleURL = () => {
  return apiUrl + `/api/galleyProofArticle`
}
export const DOIArticleURL = () => {
  return apiUrl + `/api/DOIArticle`
}
export const OnlinePublishArticleURL = () => {
  return apiUrl + `/api/OnlinePublish`
}
export const CompletePublishArticleURL = () => {
  return apiUrl + `/api/CompletePublish`
}
export const ReviewerCommentsURL = () => {
  return apiUrl + `/api/ReviewerComments`
}
export const getReviewerPendingArticleURL = () => {
  return apiUrl + `/api/reviewerPendingArticle`
}
export const generateAndDepositXmlUrl = () => {
  return apiUrl + `/api/generateXml`
}
export const viewGeneratedXmlUrl = () => {
  return apiUrl + `/api/viewGeneratedXml`
}
export const getReviewerReviewedArticleURL = () => {
  return apiUrl + `/api/reviewerReviewedArticle`
}
export const articleSearchByIdUrl = () => {
  return apiUrl + `/api/articleSearchById`
}
export const DeleteArticleURL = () => {
  return apiUrl + `/api/deleteArticle`
}
export const WithdrawalArticleURL = () => {
  return apiUrl + `/api/withdrawalArticle`
}
export const DeleteReviewerURL = () => {
  return apiUrl + `/deleteMember`
}
export const GetDoiNumberURL = () => {
  return apiUrl + `/getDoiNumber`
}
