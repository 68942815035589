import { apiUrl } from '../../../settings'

export const fetchArticleUrl = () => {
  return `${apiUrl}/api/fetchArticle`
}

export const publishArticleUrl = () => {
  return `${apiUrl}/api/publishArticle`
}

export const fetchAllArticlesUrl = () => {
  return `${apiUrl}/api/fetchAllArticles`
}
export const doiArticleUrl = () => {
  return `${apiUrl}/api/doiArticleUrl`
}

export const singleArticlePublishUrl = () => {
  return `${apiUrl}/api/singleArticlePublish`
}
