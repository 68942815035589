import React, { Component } from 'react'
import { singleArticle, getArticleById } from '../actions'
import { Button, Icon, notification, Card } from 'antd'
import PageHeaderWrapper from '../ArticleHeader'
import {
  ArticleStatus,
  ArticleDetails,
  ProcessingCharges,
  ReviewerDetails,
  ReviewedArticles,
  CommentsToAuthor,
  RevisedArticleTable,
  ApprovedArticleDetails,
  AcceptanceArticleDetails,
  ComposingDetails,
  GalleyProofDetails,
  DOIDetails,
  OnlineArticleDetails,
  PrintPublication,
  PublishArticleDetails
} from './articlePages'
import { ArticleSearch } from '../../../components/ArticleSearch'
import ComposingDrawer from '../drawers/ComposingDrawer'
import DeleteArticleDrawer from '../drawers/DeleteArticleDrawer'
import WithdrawalArticleDrawer from '../drawers/WithdrawalArticleDrawer'
import { getUrlParams } from '../../../routes'
import { connect, useDispatch } from 'react-redux'

const $ = window.$

class ArticleView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      article: {},
      visibleDrawer: false,
      visibleWithdrawalDrawer: false
    }
  }


  componentDidMount() {
    this.loadSingleArticle('didMount')
    this.reducerStop()
    this.stickyHeader()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps && nextProps.ArticleReducer && nextProps.ArticleReducer.loadArticle) {
      this.loadSingleArticle('reloadArticle')
      this.props.dispatch({ type: 'STOP_LOAD_ARTICLE' })
    }
    if (nextProps && nextProps.ArticleReducer && nextProps.ArticleReducer.refreshArticle) {
      this.loadSingleArticle('refreshArticle')
      this.props.dispatch({ type: 'REFRESH_ARTICLE', refreshArticle: false })
    }
  }

  reducerStop = () => {
    this.props.dispatch({ type: 'STOP_LOAD_ARTICLE' })
    this.props.dispatch({ type: 'REFRESH_ARTICLE', refreshArticle: false })
  }
  loadSingleArticle = async (type) => {
    console.log(type, '++++++++++')
    this.reducerStop()
    let urlObj = getUrlParams('articleView', window.location.pathname)
    if (urlObj && urlObj.id) {
      let { error, data } = await this.props.dispatch(getArticleById(urlObj.id))
      if (error) {
        notification.error({
          message: 'Error Loading Data'
        })
      } else {
        this.setState({ article: data })
        document.title = data.articleNo
      }
    } else {
      notification.error({
        message: 'Error Loading Data'
      })
    }
  }
  stickyHeader = () => {
    $(window).scroll(function() {
      let classCheck = $('aside').hasClass('ant-layout-sider-collapsed') ? 'fixedColl' : 'fixed'
      if ($(window).scrollTop() >= 250) {
        $('.sticky-header').addClass(classCheck)
        $('.headerPanel').addClass('sticky-header-bg')
      } else {
        $('.sticky-header').removeClass('fixedColl fixed')
        $('.headerPanel').removeClass('sticky-header-bg')
      }
    })
  }

  render() {
    let article = this.state.article
    return (
      <PageHeaderWrapper className={'articleHeader'} title={
        <div className={'sticky-header'}>
          <div className={'headerPanel'}>
            <div className={'titleDiv'}>
              <ArticleSearch />
              Article Details | Article No - {article.articleNo} | {article.processType}
            </div>
            <div>
              <Button key='1' type='danger' className={'marginH'}
                      onClick={() => this.setState({ visibleDrawer: true })}>
                <Icon type={'delete'} />
                Delete
              </Button>
              <Button key='1' type='warning' className={'marginH'}
                      onClick={() => this.setState({ visibleWithdrawalDrawer: true })}>
                <Icon type={'rollback'} />
                Withdrawal
              </Button>
            </div>
          </div>
        </div>
      }>


        <Card>
          <ArticleStatus article={article} />
          <ArticleDetails article={article} />
          <ProcessingCharges article={article} />

          {article.paymentDate ? <ReviewerDetails article={this.state.article} /> : null}
          {article.underReviewDetails && article.underReviewDetails.receiveDate ?
            <ReviewedArticles article={article} /> : null}
          {article.underReviewDetails && article.underReviewDetails.receiveDate ?
            <CommentsToAuthor article={article} /> : null}
          {article.sendToAuthorDate ?
            <RevisedArticleTable article={article} /> : null}
          {article.revisedArticleDate ?
            <ApprovedArticleDetails article={article} /> : null}
          {article.approvedArticleDate ?
            <AcceptanceArticleDetails article={article} /> : null}
          {article.acceptedArticlePaymentDate ?
            <ComposingDetails article={article} /> : null}
          {article.composingArticleDate || article.doiProcessDate ?
            <GalleyProofDetails article={article} /> : null}
          {article.sendgalleyProofDate ?
            <DOIDetails article={article} /> : null}
          {article.doiProcessDate ?
            <OnlineArticleDetails article={article} /> : null}
          {/*{article.onlinePublishedDate ?*/}
          {/*  <PrintPublication article={article} /> : null}*/}
          {article.onlinePublishedDate ?
            <PublishArticleDetails article={article} /> : null}
        </Card>
        {this.state.visibleDrawer ? <DeleteArticleDrawer
          visible={this.state.visibleDrawer}
          articleId={article._id}
          article={article}
          Id={article._id}
          onClose={() => this.setState({ visibleDrawer: false })}
        /> : null}
        {this.state.visibleWithdrawalDrawer ? <WithdrawalArticleDrawer
          visible={this.state.visibleWithdrawalDrawer}
          articleId={article._id}
          article={article}
          Id={article._id}
          onClose={() => this.setState({ visibleWithdrawalDrawer: false })}
        /> : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router, ArticleReducer }) => ({
  search: router.location.search,
  pathname: router.location.pathname,
  ArticleReducer: ArticleReducer

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleView)
