import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  notification,
  Card,
  Icon
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { hideLoader, showLoader } from '../../../modules/actions'
import { apiUrl as apiurl } from '../../../settings'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import { getUrlParams } from '../../../routes'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { FormUtils } from 'sz-react-utils-lite'

@Form.create()
class AddNewsLetter extends PureComponent {

  state = {
    edit: false
  }

  constructor(props) {
    super(props)
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { id: _id } = this.state
        dispatch(showLoader())
        let x = {}
        if (!!_id) {
          x = await Request.updateNewsLetter(valData, { _id })
        } else {
          x = await Request.addNewsLetter(valData)
        }

        let { error, message } = x

        dispatch(hideLoader())

        if (!error) {
          notification.success({
            message: message
          })

          if (!_id) form.resetFields()


        } else {
          notification.error({
            message: message,
            description: message
          })
        }


      }
    })
  }

  setFormValues = async (id) => {

    const { dispatch, form } = this.props

    dispatch(showLoader())

    let { error, data } = await Request.getNewsLetter({ id })

    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      form.setFieldsValue(data)
    }

    dispatch(hideLoader())

  }

  componentDidMount() {
    let data = getUrlParams('newsLetter.edit', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setState({
        edit: true,
        id: id
      })
      this.setFormValues(id)

    }

  }

  render() {

    const { form: { getFieldDecorator, getFieldValue }, loading } = this.props
    const { edit } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, apiurl, loading
    }

    const inputSchma = {
      fields: [
        { label: 'Full Name', key: 'fullName', required: true, placeholder: 'Full Name' },
        { label: 'Email', key: 'email', required: true, placeholder: 'Email' }
      ]

    }

    return (
      <PageHeaderWrapper
        title={'Edit News Letter'}>

        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>

            <FormUtils inputSchema={inputSchma} {...formProps} />

            <Form.Item style={{ marginTop: 32 }}>
              <Button type='primary' htmlType='submit' loading={this.props.loading}>
                UPDATE
              </Button>
            </Form.Item>

          </Form>


        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewsLetter)
