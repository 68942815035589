import React, { useEffect, useRef, useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { Drawer, Tooltip, Card, Button, Form, Row, Col, Radio, Input, Icon, notification } from 'antd'
import {
  formItemLayout,
  InputBox
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { GetWebPage, UpdateWebPage } from '../actions'
import moment from 'moment'
import { CustomCkEditor } from '../../../components/_utils/appUtils'
import MentionInfo from '../../email/mentionInfo'

const WebPagesDrawer = (props) => {
  let {
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    visible,
    onClose,
    page,
    callback
  } = props
  const dispatch = useDispatch()
  let [description, setDescription] = useState(null)


  const setFormData = (data) => {
    setTimeout(() => {
      props.form.setFieldsValue({
        description: data.description
      })
    }, 100)

  }


  useEffect(() => {
    getGuideline()
  }, [])

  const getGuideline = async () => {
    let { data, success } = await dispatch(GetWebPage({ page }))
    if (success) {
      setDescription(data.description)
    }
  }


  const descriptionField = {
    label: 'Description',
    key: 'description',
    placeholder: 'description',
    span: 24,
    type: 'ckeditor'
  }

  const handleSubmitOld = e => {
    const { form } = props
    e.preventDefault()
    let user = JSON.parse(localStorage.getItem('user'))
    form.validateFieldsAndScroll(async (err, valData) => {
      if (err) {
        notification.error({
          message: err.message || 'Error'
        })
      } else {
        let fd = new FormData()
        let obj = {
          description: valData.description,
          page: page
        }
        let resp = await dispatch(UpdateWebPage(obj))

        let { success, data } = resp
        if (success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
          onClose()
        }
      }
    })
  }
  const handleSubmit = async () => {
    if (!description) {
      notification.warning({
        message: 'Enter content.'
      })
      return
    }
    let obj = {
      description,
      page: page
    }
    let resp = await dispatch(UpdateWebPage(obj))

    let { success, data } = resp
    if (success) {
      dispatch({ type: 'START_LOAD_ARTICLE' })
      setDescription('')
      onClose()
    }
  }

  return (
    <>
      <Drawer
        title={page}
        width={'45%'}
        visible={visible}
        maskClosable={true}
        placement='right'
        onClose={onClose}>
        <Card className={'GuideLineDrawer'}>
          <Form hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              <Col span={24}>
                <CustomCkEditor html={description}
                                customCls={'lgCk'}
                                onChange={(data) => {
                                  setDescription(data)
                                }}
                />
              </Col>
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' onClick={handleSubmit}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(WebPagesDrawer)

export default WrappedForm
