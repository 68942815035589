import { notification } from 'antd'
import {
  articleUrl,
  singleArticleUrl,
  allArticleUrl,
  processPaymentUrl,
  addReviewerInArticleUrl,
  getArticleByIdUrl,
  getMemberHistoryUrl,
  sendReceiveDateUrl,
  sendCommentsUrl,
  lastArticleNoUrl,
  editReceiveDateUrl,
  commentsToAuthorUrl,
  reviewerAwardUrl,
  RevisedArticleURL,
  ChangeReviewerHistoryUrl,
  ResendArticleUrl,
  ApproveArticleURL,
  AcceptanceArticleURL,
  ComposeArticleURL,
  GalleyProofArticleURL,
  DOIArticleURL,
  OnlinePublishArticleURL,
  CompletePublishArticleURL,
  ReviewerCommentsURL,
  getReviewerPendingArticleURL,
  generateAndDepositXmlUrl,
  viewGeneratedXmlUrl,
  getReviewerReviewedArticleURL,
  articleSearchByIdUrl,
  DeleteArticleURL, WithdrawalArticleURL, DeleteReviewerURL, ResendCommentsURL, GetDoiNumberURL
} from '../apis'
import { getToken, customAxios as axios } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(articleUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const singleArticle = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleArticleUrl(id), {}, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateArticle = (valData, id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(singleArticleUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getAllArticle = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(allArticleUrl(), config)
  dispatch(hidePageLoad())
  /* if (data.error) {
     notification.error({
       message: data.message || 'Error'
     })
   }*/
  return data
}

export const processPaymentApi = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(processPaymentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addReviewerInArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addReviewerInArticleUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getArticleById = (articleId, selected = {}) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getArticleByIdUrl(), { articleId, selected }, getToken())
  dispatch(hidePageLoad())

  return data
}

export const getMemberHistory = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getMemberHistoryUrl(), config)
  dispatch(hidePageLoad())
  console.log(data)
  return data
}


export const SendReceiveDate = (valdata) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendReceiveDateUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}


export const SendComments = (valdata) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendCommentsUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}


export const LastArticleNo = (valdata) => async (dispatch) => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(lastArticleNoUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}


export const EditReceiveDate = (valdata) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(editReceiveDateUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const commentToAuthor = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(commentsToAuthorUrl(), valData, getToken())
  console.log(data, '+++++++++++')
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const ResendComments = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ResendCommentsURL(), valData, getToken())
  console.log(data, '+++++++++++')
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const ReviewerAward = (valdata) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(reviewerAwardUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}
export const RevisedArticle = (valdata) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(RevisedArticleURL(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const ChangeReviewerHistory = (valdata) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ChangeReviewerHistoryUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const ResendArticle = (valdata) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ResendArticleUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const ApproveArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(ApproveArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Email send Successfully'
    })
  }
  return data
}

export const AcceptanceArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(AcceptanceArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const ComposeArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(ComposeArticleURL(), valData, getToken())
  console.log('Actions data', data)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const GalleyProofArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(GalleyProofArticleURL(), valData, getToken())
  console.log('Actions data', data)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const DOIArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(DOIArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const OnlinePublishArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(OnlinePublishArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const CompletePublishArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(CompletePublishArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}


export const ReviewerComments = (valdata) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ReviewerCommentsURL(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const getReviewerPendingArticle = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getReviewerPendingArticleURL(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const generateAndDepositXml = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(generateAndDepositXmlUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}
export const viewGeneratedXml = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(viewGeneratedXmlUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}


export const getReviewerReviewedArticle = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getReviewerReviewedArticleURL(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const ArticleSearchById = async (valData) => {
  let { data } = await axios.post(articleSearchByIdUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const DeleteArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(DeleteArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Email send Successfully'
    })
  }
  return data
}
export const WithdrawalArticle = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(WithdrawalArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'success'
    })
  }
  return data
}
export const DeleteReviewer = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(DeleteReviewerURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Reviewer Deleted successfully'
    })
  }
  return data
}
export const getDoiNumberFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(GetDoiNumberURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
