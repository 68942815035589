import { apiUrl } from '../../../settings'

export const listAllOnlineSubmissionUrl = () => {
  return apiUrl + '/onlineSubmission'
}

export const singleOnlineSubmissionUrl = (id) => {
  return apiUrl + `/onlineSubmission/${id}`
}
export const getSingleCompanyEmailUrl = () => {
  return apiUrl + `/companyEmails`
}
export const onlineSubmissionEmailUrl = () => {
  return apiUrl + `/onlineSubmission/email`
}
export const onlineSubmissionExportUrl = () => {
  return apiUrl + `/export/onlineSubmission`
}

