import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Card, Button, Form, Row, Col, notification } from 'antd'
import {
  formItemLayout, discountCoupon, ConditionOptions
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { ApproveArticle } from '../actions'
import ApprovedArticle from '../views/ApprovedArticle'
import moment from 'moment'
import { currencyList } from '../../../components/_utils/appUtils'
import _ from 'lodash'

const ApprovalArticleDrawer = (props) => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue, getFieldValue },
    callback = null,
    visible,
    onClose,
    edit,
    article
  } = props
  const dispatch = useDispatch()


  let setFormData = () => {

    let { article } = props
    if (edit) {
      props.form.setFieldsValue({
        manuScriptTitle: article.manuScriptTitle,
        approvalAmount: article.approvalAmount,
        approvalInvoiceName: article.correspondingAuthor,
        approvalInvoiceAddress: article.approvalInvoiceAddress,
        invoiceDate: moment(article.invoiceDate),
        currencyTypeOfApprovalAmount: article.currency,
        approvalType: article.approvalType,
        approvalSpecialNote: article.approvalSpecialNote,
        approvalInvoiceAuthorName: article.approvalInvoiceAuthorName,
        approvedArticleDate: moment(article.approvedArticleDate),
        couponAdded: article.couponAdded ? article.couponAdded : false
      })
      if (article.couponAdded) {
        setTimeout(() => {
          props.form.setFieldsValue({ couponCode: article.couponCode })
        }, 500)
      }
    } else {
      let address = ''
      if (article) {
        if (article.correspondingAuthorAffiliation) {
          address = article.correspondingAuthorAffiliation
        }
        if (article.correspondingAuthorAddress) {
          address = address + ', ' + article.correspondingAuthorAddress
        }
      }
      props.form.setFieldsValue({
        manuScriptTitle: article.manuScriptTitle,
        approvalAmount: article.publicationCharges,
        approvalInvoiceName: article.correspondingAuthor,
        approvalInvoiceAddress: address,
        invoiceDate: moment(),
        currencyTypeOfApprovalAmount: article.currency,
        approvalType: 'Full Article',
        approvedArticleDate: moment(),
        approvalInvoiceAuthorName: nameValue(),
        couponAdded: article.couponAdded ? article.couponAdded : false
      })
      if (article.couponAdded) {
        setTimeout(() => {
          props.form.setFieldsValue({ couponCode: article.couponCode })
        }, 500)
      }
    }
  }

  let nameValue = () => {
    let str = article.correspondingAuthor
    if (article.authors) {
      _.forEach(article.authors, (item) => {
        str = str + ', ' + item.name
      })
    }
    return str
  }

  useEffect(() => {
    setTimeout(() => {
      setFormData()
    }, 1000)

  }, [])

  let [visibleDrawer, setVisibleDrawer] = useState(false)

  const events = {
    show: (event) => {
      setVisibleDrawer(true)
      setTimeout(() => {
        setFormData()
      }, 1000)

    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Article Title',
        key: 'manuScriptTitle',
        type: 'editor',
        required: true,
        span: 24
      },
      {
        label: 'Approved Date',
        key: 'approvedArticleDate',
        required: true,
        placeholder: 'Enter Approval Date',
        type: 'date'
      },
      {
        label: 'Invoice Date',
        key: 'invoiceDate',
        required: true,
        placeholder: 'Date of Enter',
        type: 'date'
      },
      {
        label: 'Currency',
        key: 'currencyTypeOfApprovalAmount',
        required: true,
        placeholder: 'Currency',
        type: 'select',
        options: currencyList,
        onChange: (e) => {
          setFieldsValue({ paymentType: e })
        }
      },
      {
        label: 'Approval Amount',
        key: 'approvalAmount',
        required: true,
        placeholder: 'Approval Amount',
        type: 'number'
      },
      {
        label: 'Invoice Name',
        key: 'approvalInvoiceName'
      },
      {
        label: 'Address',
        key: 'approvalInvoiceAddress',
        type: 'textArea',
        required: true,
        span: 16
      },
      {
        label: 'All Author Names',
        key: 'approvalInvoiceAuthorName',
        required: true,
        placeholder: 'all author names',
        type: 'textArea',
        span: 24
      },

      {
        label: 'Approval Type',
        key: 'approvalType',
        type: 'radioGroup',
        required: true,
        span: 24,
        options: ['Full Article', 'Short Communication', 'Review Article'],
        onChange: x => {
          props.form.setFieldsValue({
            approvalType: x.target.value
          })
        }
      },
      {
        label: 'Discount Coupon',
        key: 'couponAdded',
        type: 'radioGroup',
        required: true,
        span: 4,
        options: ConditionOptions,
        onChange: x => {
          props.form.setFieldsValue({
            couponAdded: x.target.value
          })
        }
      },
      {
        label: 'Coupon Code',
        key: 'couponCode',
        required: getFieldValue('couponAdded'),
        span: 4,
        type: 'select',
        hidden: !(getFieldValue('couponAdded')),
        options: discountCoupon,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.label} Discount`,
        onChange: (e) => {
          setFieldsValue({ couponCode: e })
        }
      },
      {
        label: 'Special Note',
        key: 'approvalSpecialNote',
        placeholder: 'Notes',
        span: 24,
        type: 'textArea'
      }
    ]
  }


  const handleSubmit = e => {
    const { form, article } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        chooseEmailModalFxn({
          title: 'Approve Article',
          callback: async (email) => {
            valData.fromEmail = email
            valData.articleId = article._id
            valData.template = 'approvedArticle'
            if (!edit) {
              valData.articleStatus = 'Approved Article'
              valData.approvedArticleDate = new Date()
            }
            if (!valData.couponAdded) {
              valData.couponCode = undefined
            }
            let { success } = await dispatch(ApproveArticle(valData))
            if (success) {
              callback()
              onClose()
              dispatch({ type: 'START_LOAD_ARTICLE' })
              events.hide()
            }
          }
        })
      } else {
        notification.error({
          message: 'Required',
          description: 'Fill all required fields.'
        })
      }
    })
  }

  return (
    <>
      <div onClick={() => events.show()}>
        {children}
      </div>
      <Drawer
        title={`Approve Article | Article No. ${article.articleNo}`}
        width={'70%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 8} key={key}>
                    <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                       item={item} formItemLayout={formItemLayout} />
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(ApprovalArticleDrawer)

export default WrappedForm
