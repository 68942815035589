const initialState = {
  currentUser: {
    email: null
  },
  token: null,
  notices: [],
  buttonLoading: false,
  categories: [
    'Information Technology',
    'Education',
    'Finance',
    'E-Commerce',
    'Web Application',
    'Cooperate',
    'Personal',
    'Medical'
  ],
  pageLoading: false
}

export default (state = initialState, action) => {
  console.log(action)
  switch (action.type) {

    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.user
      }

    case 'LOGOUT':
      return {
        ...state,
        currentUser: {}
      }

    case 'SHOW_BTN_LOADING':
      return {
        ...state,
        buttonLoading: true
      }

    case 'HIDE_BTN_LOADING':
      return {
        ...state,
        buttonLoading: false
      }


    case 'SET_AUTH_TOKEN':
      return {
        ...state,
        token: action.token
      }


    case 'SHOW_PAGE_LOADING':
      return {
        ...state,
        pageLoading: true
      }

    case 'HIDE_PAGE_LOADING':
      return {
        ...state,
        pageLoading: false
      }

    default:
      return state
  }
}
