import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { addUserUrl, singleUserUrl } from '../apis'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addUser = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addUserUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getUser = id => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleUserUrl(id), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}


export const updateUser = (valData, id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(singleUserUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
