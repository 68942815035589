import { apiUrl } from '../../../settings'

export const JournalListUrl = () => {
  return `${apiUrl}/journalsAjax`
}
export const CountriesUrl = () => {
  return `${apiUrl}/countries`
}
export const journalUrl = () => {
  return `${apiUrl}/journal`
}
export const getWebPageUrl = () => {
  return `${apiUrl}/api/singlePage`
}
export const updateWebPageUrl = () => {
  return `${apiUrl}/api/updateWebPage`
}