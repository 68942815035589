import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Card, Col, Form, notification, Row, List, Input } from 'antd'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'

import { CustomCkEditor, formItemLayout, InputBox, mentionsFeeds } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { fetchArticleFxn, publishArticleFxn, fetchAllArticlesFxn, singleArticlePublishFxn } from '../actions'
import moment from 'moment'
import { JournalList } from '../../journals/actions'
import { LastArticleNo } from '../../article/actions'
import Request from '../../../request'
import _ from 'lodash'

let fieldsArr = [
  { name: 'Article Title', key: 'doiArticleTitle' },
  { name: 'Article No', key: 'articleNo' },
  { name: 'Article Abstract', key: 'doiAbstract' },
  { name: 'Journal', key: 'journal' },
  { name: 'Volume', key: 'volume' },
  { name: 'Issue', key: 'issues' },
  { name: 'DOI Number', key: 'doiNumber' },
  { name: 'Page Range', key: 'pageRange' },
  { name: 'Author Name', key: 'doiAuthorName' },
  { name: 'Authors Email', key: 'correspondingAuthorsEmail' },
  { name: 'Author Affiliation', key: 'correspondingAuthorAffiliation' },
  { name: 'Keywords', key: 'doiKeywords' },
  { name: 'Publication Date', key: 'displayDate' }
]

@Form.create()
class EditEmailTemplate extends Component {
  state = {
    extraKey: {},
    urlList: []
  }

  constructor(props) {
    super(props)
  }

  fetchArticleData = async () => {
    const { dispatch, form } = this.props
    let { setFieldsValue } = form
    let { url } = this.state
    if (!url) {
      notification.warn({ message: 'Please enter URL.' })
      return
    }
    let { success, message, data } = await dispatch(fetchAllArticlesFxn({ url }))
    if (success) {
      let extraKey = {}
      _.each(data, (item, key) => {
        extraKey[key] = false
      })
      this.setState({
        urlList: data,
        extraKey
      })
    }
  }

  componentDidMount() {
  }


  fetchSingleArticleAndPublish = async (url, index) => {
    let { dispatch } = this.props
    let { data } = await dispatch(fetchArticleFxn({ url }))
    if (data && data.articleNo) {
      data.articleUrl = url
      let { success } = await dispatch(singleArticlePublishFxn(data))
      if (success) {
        let cloneExt = _.clone(this.state.extraKey)
        cloneExt[index] = true
        this.setState({ extraKey: cloneExt })
      }
    }
  }


  render() {
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue, loading } } = this.props
    let { extraKey, urlList } = this.state
    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }

    return (
      <PageHeaderWrapper
        title={'Publish Article'}>

        <Card bordered={true}>
          <Form hideRequiredMark style={{ marginTop: 8 }} className={'inputForm'}>
            <Row gutter={16}>
              <Col span={24}>
                <InputBox title={'URL *'}>
                  <Input
                    value={this.state.url}
                    placeholder={'URL *'}
                    onChange={(e) => {
                      this.setState({ url: e.target.value })
                    }} />
                </InputBox>

              </Col>
              <Col span={24}>
                <Button type='primary' htmlType='submit' loading={this.props.loading} onClick={this.fetchArticleData}>
                  {'Fetch Article'}
                </Button>
              </Col>

            </Row>

          </Form>
          <br />
          <List
            style={{ width: '100%' }}
            bordered
            size='small'
            className='demo-loadmore-list'
            itemLayout='horizontal'
            dataSource={urlList}
            renderItem={(item, index) => (
              <List.Item extra={
                !extraKey[index] &&
                <a onClick={() => this.fetchSingleArticleAndPublish(item, index)}>Fetch & Publish Article</a>
              }>
                {item}
              </List.Item>
            )}
          />
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmailTemplate)

