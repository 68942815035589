import moment from 'moment'
import countryJson from '../../assets/jsonFiles/countries'
import { apiUrl } from '../../settings'

export { default as InputBox } from './InputBox'
export { default as CustomCkEditor } from './ckEditor'
export { default as FullCkEditor } from './fullCkEditor'
export { default as NotificationBox } from './notificationBox'
export { default as ChooseEmailModal } from './chooseEmailModal'
export { default as chooseEmailModalFxn } from './chooseEmailModalFxn'

export const DefaultTablePagination = (newParams, defaultPageSize = 10, pageSizeOptions = ['50', '100', '200', '500']) => {
  let params = { defaultPageSize, pageSizeOptions, ...newParams }
  return params
}

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  }
}

export const DateFormat = 'DD-MMM-YYYY'
export const filterDateFormat = 'MM-DD-YYYY'

export const DisplayDate = (date) => {
  if (date) {
    return moment(date).format(DateFormat)
  } else {
    return null
  }
}

export const ProcessList = [
  'Normal Review Process', 'Rapid Online Publication', 'Rapid Complete Publication'
]

export const ArticleTypeList = [
  'Full Research Article', 'Review Article', 'Short Communication'
]

export const DesignationList = [
  'Student MSc',
  'Student PhD',
  'Asst. Professor',
  'Professor',
  'Associate Professor',
  'Scientist',
  'Jr. Scientist',
  'Sr. Scientist',
  'Pr. Scientist',
  'HOD',
  'Retd.',
  'Dean',
  'Director',
  'Vice Chancellor',
  'Others'
]
export const AuthorTitle = [
  'Mr.',
  'Mrs.',
  'Miss',
  'Dr.',
  'Dr.(Mrs.)',
  'Prof.',
  'Prof. Dr.'
]

export const Countries = [...countryJson]

export const MemberTypes = [
  'Reviewer', 'Editorial Board' /*'Executive Editorial Board'*/
]
export const MemberPositions = [
  'Chief Editor', 'Associate chief editor', 'Editor', 'Member', 'Executive Editorial Board'
]
export const MemberRatings = () => {
  let ratingList = []
  for (let i = 0; i <= 10; i++) {
    ratingList.push({ label: `${i} star`, value: `${i}` })
  }
  ratingList.push({ label: `10+ star`, value: `10+` })
  return ratingList
}

export const Genders = [
  'Male', 'Female'
]
export const Discipline = [
  'Agronomy', 'Soil Science', 'Crop Sciences', 'Crop Production', 'Plant Protection', 'Biotechnology', 'Food Science', 'Agri-Business', 'Others'
]

export const mentionsFeeds = [
  'articleType',
  'correspondingAuthor',
  'correspondingAuthorsEmail',
  'manuScriptTitle',
  'title',
  'name',
  'journal',
  'abstract',
  'volume',
  'issues',
  // 'articleTitle',
  'authorName',
  'authorAddress',
  'doi',
  'articleOrder',
  'articleNo',
  'publishDate',
  'keywords',
  'email',
  'references',
  'isPublished',
  'articleFile',
  'correspondingAuthor',
  'correspondingAuthorContact',
  'correspondingAuthorAddress',
  'correspondingAuthorCountry',
  'correspondingAuthorAffiliation',
  'correspondingAuthorDesignation',
  'printIssn',
  'onlineIssn',
  'ranking',
  'factor',
  'frequency',
  'rating',
  'orderNumber',
  'publication',
  'description',
  'subjects',
  'slugJournal',
  'journalFile',
  'volume',
  'issues',
  'currency',
  'amount',
  'modeOfPayment',
  'reviewerId',
  'userName',
  'manageSchedule',
  //'manageIndianSchedule',
  //'manageForeignSchedule',
  'anotherJournalName',
  'processingCharges',
  'otherAuthorName',
  'earlyComments',
  'specialNote',
  'approvalSpecialNote',
  'approvalAmount',
  'acceptedArticlePayment',
  'publicationCharges',
  'onlineSubmission',
  'onlineSubmissionReviewer',
  'approvalType',
  'acceptedArticlePaymentMode',
  'articleLink',
  'sendDate',
  'reminderDate',
  'receiveDate',
  'commentsDate',
  'webCommentsDate',
  'sendToAuthorDate',
  'revisedArticleDate',
  'approvedArticleDate',
  'acceptedArticlePaymentDate',
  'sendgalleyProofDate',
  'doiProcessDate',
  'onlinePublishedDate',
  'sendForPublicationDate',
  'withdrawnDate',
  'reminderDate',
  'acknowledgmentDate',
  'finalReminderDate',
  'reminderDateCommentsToAuthor',
  'approvalReminderDate',
  'galleyReminderDate',
  'couponHeadLine'
]

export const ArticleStatus = [
  { status: 'Submitted Article' },
  { status: 'Under Process' },
  { status: 'Under Reviewer' },
  { status: 'Reviewed Comments' },
  { status: 'Comments To Author' },
  { status: 'Revised Article' },
  { status: 'Approved Article' },
  { status: 'Accepted Article' },
  { status: 'Composing Article' },
  { status: 'Galley Proof' },
  { status: 'DOI' },
  { status: 'Online Publish' },
  { status: 'For Print Publication' },
  { status: 'Published Article', label: 'Deleted Article', dateField: 'submittedDate' },
  { status: 'Deleted' },
  { status: 'Withdrawled' }
]

export const GetDownloadLink = (link) => {
  return `${apiUrl}${link}`
}

export const PaymentModes = [
  // 'CC Avenue',
  'Online Payment',
  'NEFT',
  'Cash',
  'Draft or Cheque',
  'PayPal',
  'Others'
]
export const MemberStatus = [
  'Available',
  'Not Available'
  // 'Deleted'
]

export const MonthArr = [
  {
    name: 'January',
    value: 1
  },
  {
    name: 'February',
    value: 2
  },
  {
    name: 'March',
    value: 3
  },
  {
    name: 'April',
    value: 4
  },
  {
    name: 'May',
    value: 5
  },
  {
    name: 'June',
    value: 6
  },
  {
    name: 'July',
    value: 7
  },
  {
    name: 'August',
    value: 8
  },
  {
    name: 'September',
    value: 9
  },
  {
    name: 'October',
    value: 10
  },
  {
    name: 'November',
    value: 11
  },
  {
    name: 'December',
    value: 12
  }
]

export const YearArr = () => {
  // let currentYear = moment().year()
  let toYear = 2024
  let years = []
  for (let i = 2013; i <= toYear; i++) {
    years.push(i)
  }
  return years
}


export const DateByMonth = (month) => {
  let year = moment().year()
  return moment(month + '-' + 1 + '-' + year, filterDateFormat)
}

export const DateByYear = (year) => {
  return moment(1 + '-' + 1 + '-' + year, filterDateFormat)
}

export const DateByMonthAndYear = (month, year) => {
  return moment(month + '-' + 1 + '-' + year, filterDateFormat)
}

export const TemplateTitleList = [
  'requestToNewReviewer',
  'processingChargesAcknowledgement',
  'acknowledgementWithCharges',
  'acknowledgementWithoutCharges',
  'reviewerRegister',
  'reviewerThanksAfterReceivingComments'
]

export const RemarksList = [
  'Not his field',
  'Received from another reviewer',
  'Withdraw by author',
  'Not Reviewer field',
  'Do not have time right now',
  'Other text box'
]

export const currencyList = [
  'Rs.', 'USD'
]

export const AssignToName = [
  'Nehal',
  'Dr. Vedpal'
]

export const getSlugUrl = (originalName) => {
  return originalName.replace(/[^\w\s-]/g, '').trim().toLowerCase().replace(/[-\s]+/g, '-')
}
export const BooleanOptions = [
  { name: 'Enable', value: true },
  { name: 'Disable', value: false }
]

export const discountCoupon = [
  { label: '10%', value: 10 },
  { label: '20%', value: 20 },
  { label: '30%', value: 30 },
  { label: '40%', value: 40 },
  { label: '50%', value: 50 }
]
export const ConditionOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]
