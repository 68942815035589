import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  Select,
  Button, Col, Row, notification, Icon, Card
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { hideLoader, hidePageLoad } from '../../../modules/actions'
import { connect } from 'react-redux'
import {
  InputBox, BooleanOptions
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { addUser } from '../actions'
import { TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getUser, updateUser } from '../actions'
import { getUrlParams } from '../../../routes'

let users = [
  {
    name: 'Admin',
    value: 'admin'
  },
  {
    name: 'Branch Manager',
    value: 'branchManager'
  },
  {
    name: 'Branch User',
    value: 'branchUser'
  },
  {
    name: 'Agent',
    value: 'agent'
  }
  /*{
    name: 'Student',
    value: 'student'
  }*/
]


const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

@Form.create()
class AddUser extends PureComponent {


  constructor(props) {
    super(props)
    this.state = {
      allBranch: [],
      universityList: [],
      countryList: [],
      mainBranchArr: [],
      logo: {},
      uploadKey: moment(),
      agentList: [],
      agents: [],
      countryCode: '',
      openAccess: true
    }

  }

  async getUserData(id) {
    let { pathname, dispatch, form: { setFieldsValue } } = this.props
    let resp = await dispatch(getUser(id))
    if (resp) {
      this.setState({
        name: resp.name,
        userId: resp._id,
        address: resp.address,
        email: resp.local.email
      })
      setFieldsValue({
        name: resp.name,
        mobileNo: resp.mobileNo,
        address: resp.address,
        openAccess: resp.openAccess
      })
    }
  }

  componentDidMount() {
    let data = getUrlParams('users.editUser', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setState({
        userId: id
      })
      this.getUserData(id)
    }
  }


  handleSubmit = e => {
    const { dispatch, form } = this.props
    let { getFieldValue } = form
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { address, userId } = this.state
        valData.address = address
        valData._id = userId
        let data = await dispatch(updateUser(valData, userId))
      }
    })
  }

  render() {

    const { submitting, dispatch } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props

    const { editorState, agentList, agentName, agents } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }


    let inputTypes = {
      fields: [
        {
          label: 'Name',
          key: 'name',
          required: true
        },

        {
          label: 'Mobile no',
          key: 'mobileNo',
          required: true,
          type: 'number'
        },
        {
          key: 'openAccess',
          label: 'Open Access',
          type: 'select',
          required: true,
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: BooleanOptions,
          onChange: x => {
            setFieldsValue({
              openAccess: x
            })
          }
        },

        {
          key: 'address',
          span: 24,
          customField: (
            <InputBox title={'Address'}>
              <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                this.setState({ address: e.target.value })
              }}>
              </TextArea>
            </InputBox>
          )
        }


      ]
    }


    return (

      <PageHeaderWrapper
        title={`Edit User -  ${this.state.email}`}>

        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={16} className={'rowWrap'}>
              {inputTypes.fields.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    {item.customField ?
                      <Col span={item.span ? item.span : 8}>
                        {item.customField}
                      </Col> :
                      <Col span={item.span ? item.span : 8} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout} />

                      </Col>}
                  </React.Fragment>
                )
              })}


            </Row>


            <Form.Item>
              <Button type='primary' htmlType='submit'
                      className={'btn'}>
                UPDATE
              </Button>
            </Form.Item>
          </Form>
        </Card>

      </PageHeaderWrapper>

    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  pathname: router.location.pathname,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser)
