import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Drawer, Form, Button, Icon } from 'antd'
import { DisplayDate, formItemLayout, GetDownloadLink } from '../../components/_utils/appUtils'
import ReactHtmlParser from 'react-html-parser'
import { RowTable, CustomCardTitle } from '../article/articleView/RowTable'
import moment from 'moment'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { connect } from 'react-redux'

const mentionInfo = (props) => {

  let [visible, setVisible] = useState(false)
  let events = {
    showDrawer: () => {
      setVisible(true)
    },
    hideDrawer: () => {
      setVisible(false)
    }
  }

  let { children } = props
  return (
    <React.Fragment>

      <span onClick={() => events.showDrawer()}> {children}</span>

      {visible ?
        <Drawer visible={visible}
                width={'70%'}
                placement='right'
                onClose={events.hideDrawer}
                title={'Mention Information'}>
          <Card>
            <div className={'tableBox striped'}>
              <RowTable title={'articleType'} value={''} />
              <RowTable title={'correspondingAuthor'} value={''} />
              <RowTable title={'correspondingAuthorsEmail'} value={''} />
              <RowTable title={'title'} value={''} />
              <RowTable title={'manuScriptTitle'} value={''} />
              <RowTable title={'name'} value={''} />
              <RowTable title={'journal'} value={''} />
              <RowTable title={'Country'} value={''} />
              <RowTable title={'abstract'} value={''} />
              <RowTable title={'volume'} value={''} />
              <RowTable title={'issues'} value={''} />
              <RowTable title={'articleTitle'} value={''} />
              <RowTable title={'authorName'} value={''} />
              <RowTable title={'authorAddress'} value={''} />
              <RowTable title={'doi'} value={''} />
              <RowTable title={'articleOrder'} value={''} />
              <RowTable title={'articleId'} value={''} />
              <RowTable title={'publishDate'} value={''} />
              <RowTable title={'email'} value={''} />
              <RowTable title={'references'} value={''} />
              <RowTable title={'isPublished'} value={''} />
              <RowTable title={'articleFile'} value={''} />
              <RowTable title={'correspondingAuthor'} value={''} />
              <RowTable title={'correspondingAuthorContact'} value={''} />
              <RowTable title={'correspondingAuthorAddress'} value={''} />
              <RowTable title={'correspondingAuthorCountry'} value={''} />
              <RowTable title={'correspondingAuthorAffiliation'} value={''} />
              <RowTable title={'correspondingAuthorDesignation'} value={''} />
              <RowTable title={'printIssn'} value={''} />
              <RowTable title={'onlineIssn'} value={''} />
              <RowTable title={'ranking'} value={''} />
              <RowTable title={'factor'} value={''} />
              <RowTable title={'frequency'} value={''} />
              <RowTable title={'rating'} value={''} />
              <RowTable title={'orderNumber'} value={''} />
              <RowTable title={'publication'} value={''} />
              <RowTable title={'description'} value={''} />
              <RowTable title={'subjects'} value={''} />
              <RowTable title={'slugJournal'} value={''} />
              <RowTable title={'journalFile'} value={''} />
              <RowTable title={'volume'} value={''} />
              <RowTable title={'issues'} value={''} />
              <RowTable title={'currency'} value={''} />
              <RowTable title={'amount'} value={''} />
              <RowTable title={'modeOfPayment'} value={''} />
              <RowTable title={'reviewerId'} value={''} />
              <RowTable title={'userName'} value={''} />
              <RowTable title={'manageSchedule'} value={''} />
            </div>
          </Card>
        </Drawer>
        : null}
    </React.Fragment>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapDispatchToProps
)(mentionInfo)

// export default ViewMember
