import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Popconfirm
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import { TableComp, FormUtils } from 'sz-react-utils-lite'

const regExpFilters = ['name', 'local.email', 'userType']

class AllUsers extends Component {

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let data = await Request.getAllUser({ ...params, userType: 'User', regExFilters: regExpFilters })
      resolve(data)
    })

  }

  deleteUser = (params) => {
    Request.deleteUserApi(params).then((data) => {
      if (data.type) {
        notification.success({
          message: data.msg
        })
        this.reload()
      } else {
        notification.error({
          message: data.msg
        })
      }
    })

  }

  reload = () => {
    this.table.current.reload()
  }

  componentDidMount() {
  }

  render() {
    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name',
        filterByRegex: true,
        fixed: 'left',
        width: 130
      },
      {
        title: 'Email',
        dataIndex: 'local.email',
        key: 'local.email',
        filterByRegex: true,
        searchTextName: 'local.email',
        fixed: 'left',
        width: 150

      },
      /* {
         title: 'User Type',
         dataIndex: 'userType',
         key: 'userType',
         searchTextName: 'userType',
         filterByRegex: true
       },*/
      {
        title: 'Reviewer Id',
        dataIndex: 'reviewerId',
        key: 'reviewerId',
        searchTextName: 'userType',
        filterByRegex: true
      },
      {
        title: 'Password',
        dataIndex: 'displayPassword',
        key: 'displayPassword'
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address'
      },
      {
        title: 'Institute',
        dataIndex: 'instituteAddress',
        key: 'instituteAddress'
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation'
      },
      {
        title: 'MobileNo',
        dataIndex: 'mobileNo',
        key: 'mobileNo'
      },

      {
        title: 'userName',
        dataIndex: 'userName',
        key: 'userName'
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country'
      },
      {
        key: 'actions', width: 100,
        render: (item, record) => {
          return (
            <div>
              {
                item.userType == 'User' ?
                  <React.Fragment>
                    <Tooltip title='Delete'>
                      <Popconfirm placement='topLeft' title={`Are yoy sure, You want to delete ${record.name}?`}
                                  onConfirm={() => this.deleteUser(item)} okText='Yes' cancelText='No'>
                        <Button className={styles.btn}
                                shape='circle' type='danger'
                                icon='delete' />
                      </Popconfirm>
                    </Tooltip>

                    <Tooltip title='Edit User'>
                      <Button className={styles.btn}
                              onClick={() => {
                                this.props.dispatch(getPushPathWrapper('users.editUser', { id: record._id }))
                              }}
                              shape='circle' icon={'edit'}>
                      </Button>
                    </Tooltip>
                  </React.Fragment> : null
              }


            </div>
          )
        }
      }

    ]
    return (
      <PageHeaderWrapper
        title={'All Users'}>

        <Card bordered={true}>
          <TableComp ref={this.table}
                     extraProps={{ scroll: { x: 1350 } }}
                     columns={columns} apiRequest={this.apiRequest} />
        </Card>

      </PageHeaderWrapper>
    )

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
