import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Card, Col, Form, notification, Row, List, Input } from 'antd'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'

import { CustomCkEditor, formItemLayout, InputBox, mentionsFeeds } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { fetchArticleFxn, publishArticleFxn } from '../actions'
import moment from 'moment'
import { JournalList } from '../../journals/actions'
import { LastArticleNo } from '../../article/actions'
import Request from '../../../request'
import _ from 'lodash'

let fieldsArr = [
  { name: 'Article Title', key: 'doiArticleTitle' },
  { name: 'Article No', key: 'articleNo' },
  { name: 'Article Abstract', key: 'doiAbstract' },
  { name: 'Journal', key: 'journal' },
  { name: 'Volume', key: 'volume' },
  { name: 'Issue', key: 'issues' },
  { name: 'DOI Number', key: 'doiNumber' },
  { name: 'Page Range', key: 'pageRange' },
  { name: 'Author Name', key: 'doiAuthorName' },
  { name: 'Authors Email', key: 'correspondingAuthorsEmail' },
  { name: 'Author Affiliation', key: 'correspondingAuthorAffiliation' },
  { name: 'Keywords', key: 'doiKeywords' },
  { name: 'Publication Date', key: 'displayDate' }
]

@Form.create()
class EditEmailTemplate extends Component {
  state = {
    data: {},
    articleFile: {},
    journalListArr: [],
    issueList: [],
    volumeList: []
  }

  constructor(props) {
    super(props)
  }

  chooseDocument = (e) => {
    let { files } = e.target
    if (files && files.length) {
      this.setState({ articleFile: files[0] })
    }
  }
  getJournalsVolumeData = async (id) => {
    let { data } = await Request.getJournalsVolumeList({ journalId: id })
    this.setState({ volumeList: data })
  }
  getJournalsIssueData = async (volumeId) => {
    const { form: { getFieldValue } } = this.props
    let { data } = await Request.getJournalIssue({ journalId: getFieldValue('journalId'), volumeId: volumeId })

    this.setState({ issueList: data })
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    let { setFieldsValue } = form
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { success, message, data } = await dispatch(fetchArticleFxn(valData))
        if (success) {
          this.setState({
            data: data,
            doiAbstract: data.doiAbstract
          })
          setFieldsValue({
            journalId: data.journalId,
            issueId: data.issueId,
            volumeId: data.volumeId,
            articleNo: data.articleNo,
            doiNumber: data.doiNumber,
            doiArticleTitle: data.doiArticleTitle,
            doiAuthorName: data.doiAuthorName,
            doiKeywords: data.doiKeywords,
            doiNumbercorrespondingAuthorAffiliation: data.doiNumbercorrespondingAuthorAffiliation,
            pageRange: data.pageRange,
            correspondingAuthorsEmail: data.correspondingAuthorsEmail,
            correspondingAuthorAffiliation: data.correspondingAuthorAffiliation,
            onlinePublishedDate: data.onlinePublishedDate ? moment(data.onlinePublishedDate) : '',
            doiAbstract: data.doiAbstract
          }, () => {
            if (data.journalId) {
              this.loadLastArticleNo()
              this.getJournalsVolumeData(data.journalId)
            }
            if (data.volumeId) {
              this.getJournalsIssueData(data.volumeId)
            }
          })
        }
      }
    })
  }
  fetchArticleData = async () => {
    const { dispatch, form } = this.props
    let { setFieldsValue } = form
    let { url } = this.state
    if (!url) {
      notification.warn({ message: 'Please enter URL.' })
      return
    }
    let { success, message, data } = await dispatch(fetchArticleFxn({ url }))
    if (success) {
      this.setState({
        data: data,
        doiAbstract: data.doiAbstract
      })
      setFieldsValue({
        journalId: data.journalId,
        issueId: data.issueId,
        volumeId: data.volumeId,
        articleNo: data.articleNo,
        doiNumber: data.doiNumber,
        doiArticleTitle: data.doiArticleTitle,
        doiAuthorName: data.doiAuthorName,
        doiKeywords: data.doiKeywords,
        doiNumbercorrespondingAuthorAffiliation: data.doiNumbercorrespondingAuthorAffiliation,
        pageRange: data.pageRange,
        correspondingAuthorsEmail: data.correspondingAuthorsEmail,
        correspondingAuthorAffiliation: data.correspondingAuthorAffiliation,
        onlinePublishedDate: data.onlinePublishedDate ? moment(data.onlinePublishedDate) : '',
        doiAbstract: data.doiAbstract
      }, () => {
        if (data.journalId) {
          this.loadLastArticleNo()
          this.getJournalsVolumeData(data.journalId)
        }
        if (data.volumeId) {
          this.getJournalsIssueData(data.volumeId)
        }
      })
    }
  }

  componentDidMount() {
    this.loadJournalList()
  }

  loadJournalList = async () => {
    const { dispatch, form } = this.props
    let { data } = await JournalList()
    this.setState({ journalListArr: data })
  }

  submitArticle = async () => {
    const { dispatch, form } = this.props

    let { data, articleFile } = this.state
    if (!articleFile || (articleFile && !articleFile.name)) {
      notification.warn({ message: 'Choose Article File.' })
      return
    }
    let fd = new FormData()
    fd.append('obj', JSON.stringify(data))
    fd.append('articleFile', articleFile)
    let { success } = await dispatch(publishArticleFxn(fd))
    if (success) {
      form.resetFields()
      this.setState({
        data: {},
        articleFile: {},
        fileKey: moment()
      })
    }
  }

  loadLastArticleNo = async () => {
    let { form: { getFieldValue }, dispatch } = this.props
    let obj = {
      journalId: getFieldValue('journalId') ? getFieldValue('journalId') : null,
      country: getFieldValue('country') ? getFieldValue('country') : null
    }
    let resp = await dispatch(LastArticleNo(obj))
    this.setState({ lastArticleNo: resp })
  }

  handlePublishSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { articleFile, journalListArr, volumeList, issueList, doiAbstract } = this.state
        if (!doiAbstract) {
          notification.warn({ message: 'Enter Abstract' })
          return
        }
        if (!articleFile || (articleFile && !articleFile.name)) {
          notification.warn({ message: 'Choose Article File.' })
          return
        }

        let findJournal = _.find(journalListArr, (item) => {
          return item._id == valData.journalId
        })
        if (findJournal) {
          valData.journal = findJournal.name
          valData.journalId = findJournal._id
          valData.slugJournal = findJournal.slugJournal
        }

        let findVolume = _.find(volumeList, (item) => {
          return item._id == valData.volumeId
        })
        if (findVolume) {
          valData.volume = findVolume.volumeName
          valData.volumeName = findVolume.volumeName
          valData.sortByVolume = findVolume.volumeNumber
          valData.slugVolume = findVolume.slugVolume
        }

        let findIssue = _.find(issueList, (item) => {
          return item._id == valData.issueId
        })
        if (findIssue) {
          valData.issues = findIssue.issueName
          valData.issueName = findIssue.issueName
          valData.sortByIssue = findIssue.issueNumber
          valData.slugIssue = findIssue.slugIssue
        }
        valData.doiAbstract = this.state.doiAbstract
        valData.abstract = this.state.doiAbstract
        valData.doiProcessDate = valData.onlinePublishedDate
        valData.submittedDate = valData.onlinePublishedDate
        valData.acceptedArticlePaymentDate = valData.onlinePublishedDate
        valData.sendgalleyProofDate = valData.onlinePublishedDate
        valData.manuScriptTitle = valData.doiArticleTitle
        valData.authorName = valData.doiAuthorName
        valData.doiAuthorNameBackup = valData.doiAuthorName
        valData.keywords = valData.doiKeywords
        valData.doiAuthorAddress = valData.correspondingAuthorAffiliation


        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        fd.append('articleFile', articleFile)
        let { success } = await dispatch(publishArticleFxn(fd))
        if (success) {
          form.resetFields()
          this.setState({
            data: {},
            articleFile: {},
            fileKey: moment(),
            url: '',
            doiAbstract: ''
          })
        }
      }
    })
  }


  render() {
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue, loading } } = this.props
    let { data, journalListArr, lastArticleNo } = this.state
    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }

    const inputSchma = {
      fields: [
        {
          label: 'Article Title',
          key: 'doiArticleTitle',
          required: true,
          placeholder: 'Article Title',
          span: 24,
          type: 'editor'
        },
        {
          label: 'Journal',
          key: 'journalId',
          required: true,
          showSearch: true,
          options: journalListArr,
          keyAccessor: x => x._id,
          valueAccessor: x => x.name,
          placeholder: 'Journal',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ journalId: val, volumeId: '', issueId: '' })
            this.loadLastArticleNo()
            this.getJournalsVolumeData(val)
          }
        },
        {
          label: 'Volume',
          key: 'volumeId',
          required: true,
          showSearch: true,
          options: this.state.volumeList,
          keyAccessor: x => x._id,
          valueAccessor: x => x.volumeName,
          placeholder: 'Volume',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ volumeId: val, issueId: '' })
            this.getJournalsIssueData(val)
          }
        },
        {
          label: 'Issue',
          key: 'issueId',
          required: true,
          showSearch: true,
          options: this.state.issueList,
          keyAccessor: x => x._id,
          valueAccessor: x => x.issueName,
          placeholder: 'Issue',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ issueId: val })
          }
        },
        {
          label: 'Article No',
          key: 'articleNo',
          required: true,
          placeholder: 'Article No',
          extraBox: (
            <React.Fragment>
              {lastArticleNo ? <label className={'label label-success'}>{lastArticleNo}</label> : null}
            </React.Fragment>
          ),
          onChange: (val) => {
            setFieldsValue({ articleNo: val.target.value.toUpperCase() })
          }
        },
        {
          label: 'Author Name',
          key: 'doiAuthorName',
          required: true,
          placeholder: 'Author Name',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        {
          label: 'Keywords',
          key: 'doiKeywords',
          required: true,
          placeholder: 'Keywords',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        {
          label: 'Author Affiliation',
          key: 'correspondingAuthorAffiliation',
          required: true,
          placeholder: 'Author Affiliation',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        {
          label: 'DOI Number',
          key: 'doiNumber',
          required: true,
          placeholder: 'DOI Number'
        },
        {
          label: 'Page Range',
          key: 'pageRange',
          required: true,
          placeholder: 'Page Range'
        },
        {
          label: 'Authors Email',
          key: 'correspondingAuthorsEmail',
          required: true,
          placeholder: 'Authors Email',
          type: 'text'
        },
        {
          label: 'Publication Date',
          key: 'onlinePublishedDate',
          required: true,
          placeholder: 'Publication Date',
          type: 'date'
        },

        {
          label: 'Abstract',
          key: 'doiAbstract',
          required: true,
          placeholder: 'Abstract',
          rows: 2,
          span: 24,
          customField: (
            <Col>
              <CustomCkEditor title={'Abstract'}
                              feeds={mentionsFeeds}
                              html={this.state.doiAbstract}
                              onChange={(data) => {
                                this.setState({ 'doiAbstract': data })
                              }}
              />
            </Col>)
        },
        {
          label: 'articleFile',
          customField: (
            <InputBox title={`Article File`}>
              <Input type={'file'} name={'articleFile'} id={'articleFile'}
                     className={'form-control'} key={this.state.fileKey}
                     onChange={(e) => {
                       this.chooseDocument(e)
                     }} />
            </InputBox>
          )
        }


      ]

    }

    const inputSchema = {
      fields: [
        { label: 'URL ', key: 'url', required: true, placeholder: 'URL', span: 24 }
      ]
    }
    return (
      <PageHeaderWrapper
        title={'Publish Article'}>

        <Card bordered={true}>
          <Form hideRequiredMark style={{ marginTop: 8 }} className={'inputForm'}>
            <Row gutter={16}>
              <Col span={24}>
                <InputBox title={'URL *'}>
                  <Input
                    value={this.state.url}
                    placeholder={'URL *'}
                    onChange={(e) => {
                      this.setState({ url: e.target.value })
                    }} />
                </InputBox>

              </Col>
              <Col span={24}>
                <Button type='primary' htmlType='submit' loading={this.props.loading} onClick={this.fetchArticleData}>
                  {'Fetch Article'}
                </Button>
              </Col>
              {/*{inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                       key={key}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout} />
                  </Col>
                )
              })}*/}
            </Row>

          </Form>

          {data && data.journal ?
            <>
              <Form onSubmit={this.handlePublishSubmit} hideRequiredMark className={'inputForm'}
                    style={{ marginTop: 8 }}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Card title='Article Details' bordered={true}>
                      <Row gutter={16}>
                        {inputSchma.fields.map((item, key) => {
                          return (
                            <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                                 key={key}>
                              {item.customField ? item.customField :
                                <GetEachFormFields
                                  item={item}
                                  getFieldDecorator={getFieldDecorator}
                                  formItemLayout={formItemLayout} />}
                            </Col>
                          )
                        })}
                      </Row>
                    </Card>
                  </Col>

                </Row>

                <Form.Item style={{ marginTop: 32 }}>
                  <Button type='primary' htmlType='submit'>
                    SUBMIT
                  </Button>
                </Form.Item>

              </Form>

              {/* <div className={'ant-table ant-table-small ant-table-bordered ant-table-scroll-position-left'}>
                  <table>
                    <tbody className={'ant-table-tbody'}>
                    <tr>
                      <th style={{ width: 150 }}>Name</th>
                      <th>Value</th>
                    </tr>
                    {fieldsArr.map((item, key) => {
                      return (
                        <tr key={key} className={'ant-table-row ant-table-row-level-0'}>
                          <td>{item.name}</td>
                          <td>{data[item.key] ? data[item.key] : ''}</td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                </div>
                <Row gutter={16}>
                  <Col span={12}>
                    <InputBox title={`Article File`}>
                      <Input type={'file'} name={'articleFile'} id={'articleFile'}
                             className={'form-control'} key={this.state.fileKey} multiple={false}
                             onChange={(e) => {
                               this.chooseDocument(e)
                             }} />
                    </InputBox>
                  </Col>
                  <Col span={12}>
                    <a className={'ant-btn ant-btn-primary'}
                       style={{ marginTop: 43 }}
                       onClick={() => {
                         this.submitArticle()
                       }}>
                      Publish Article
                    </a>
                  </Col>
                </Row>
*/}
            </> : null}
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmailTemplate)

