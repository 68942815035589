import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  Button,
  notification,
  Card,
  Row, Col
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { connect } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { JournalList } from '../../journals/actions'
import {
  InputBox,
  CustomCkEditor, mentionsFeeds, Countries
} from '../../../components/_utils/appUtils'
import { LastArticleNo } from '../../article/actions'
import Request from '../../../request'
import { doiArticleFxn } from '../actions'

@Form.create()
class AddDirectArticle extends PureComponent {

  state = {
    edit: false,
    journalListArr: [],
    volumeList: [],
    issueList: [],
    visibleAddAuthor: false,
    authors: [],
    reviewerArticleAttachment: {},
    reviewerOtherAttachment: {},
    fileKey: moment(),
    onlineSubmissionId: '',
    lastArticleNo: '',
    abstract: '',
    articleFile: {}
  }

  constructor(props) {
    super(props)
  }

  loadLastArticleNo = async () => {
    let { form: { getFieldValue }, dispatch } = this.props
    let obj = {
      journalId: getFieldValue('journalId') ? getFieldValue('journalId') : null,
      country: getFieldValue('country') ? getFieldValue('country') : null
    }
    let resp = await dispatch(LastArticleNo(obj))
    console.log('resp', resp)
    this.setState({ lastArticleNo: resp })
  }


  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { articleFile, journalListArr, volumeList, issueList } = this.state
        /*  if (!articleFile || (articleFile && !articleFile.name)) {
            notification.warn({ message: 'Choose Article File.' })
            return
          }*/

        let findJournal = _.find(journalListArr, (item) => {
          return item._id == valData.journalId
        })
        if (findJournal) {
          valData.journal = findJournal.name
          valData.journalId = findJournal._id
          valData.slugJournal = findJournal.slugJournal
        }

        let findVolume = _.find(volumeList, (item) => {
          return item._id == valData.volumeId
        })
        if (findVolume) {
          valData.volume = findVolume.volumeName
          valData.volumeName = findVolume.volumeName
          valData.sortByVolume = findVolume.volumeNumber
          valData.slugVolume = findVolume.slugVolume
        }

        let findIssue = _.find(issueList, (item) => {
          return item._id == valData.issueId
        })
        if (findIssue) {
          valData.issues = findIssue.issueName
          valData.issueName = findIssue.issueName
          valData.sortByIssue = findIssue.issueNumber
          valData.slugIssue = findIssue.slugIssue
        }
        valData.doiAbstract = this.state.doiAbstract
        valData.abstract = this.state.doiAbstract
        valData.doiReferences = this.state.doiReferences
        valData.doiProcessDate = valData.displayDate
        valData.submittedDate = valData.displayDate
        valData.composingArticleDate = valData.displayDate
        valData.acceptedArticlePaymentDate = valData.displayDate
        // valData.onlinePublishedDate = valData.displayDate
        valData.sendgalleyProofDate = valData.displayDate
        valData.manuScriptTitle = valData.doiArticleTitle
        valData.authorName = valData.doiAuthorName
        valData.keywords = valData.doiKeywords
        valData.doiAuthorAddress = valData.correspondingAuthorAffiliation
        valData.composeJournalName = valData.journal
        valData.composeVolumes = valData.volumeName
        valData.composeIssues = valData.issueName

        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        // fd.append('articleFile', articleFile)
        let { success } = await dispatch(doiArticleFxn(fd))
        if (success) {
          form.resetFields()
          this.setState({
            data: {},
            articleFile: {},
            fileKey: moment()
          })
        }
      }
    })
  }

  componentDidMount() {
    this.loadJournalList()
  }

  loadJournalList = async () => {
    const { dispatch, form } = this.props
    let { data } = await JournalList()
    this.setState({ journalListArr: data })
  }

  chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  getJournalsVolumeData = async (id) => {
    let { data } = await Request.getJournalsVolumeList({ journalId: id })
    this.setState({ volumeList: data })
  }
  getJournalsIssueData = async (volumeId) => {
    const { form: { getFieldValue } } = this.props
    let { data } = await Request.getJournalIssue({ journalId: getFieldValue('journalId'), volumeId: volumeId })

    this.setState({ issueList: data })
  }

  render() {

    const { form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading } = this.props
    const { edit, journalListArr, lastArticleNo } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputSchma = {
      fields: [
        {
          label: 'Article Title',
          key: 'doiArticleTitle',
          required: true,
          placeholder: 'Article Title',
          span: 24,
          type: 'editor'
        },
        {
          label: 'Journal',
          key: 'journalId',
          required: true,
          showSearch: true,
          options: journalListArr,
          keyAccessor: x => x._id,
          valueAccessor: x => x.name,
          placeholder: 'Journal',
          type: 'select',
          span: 8,
          onChange: (val) => {
            setFieldsValue({ journalId: val })
            this.loadLastArticleNo()
            this.getJournalsVolumeData(val)
          }
        },
        {
          label: 'Volume',
          key: 'volumeId',
          required: true,
          showSearch: true,
          options: this.state.volumeList,
          keyAccessor: x => x._id,
          valueAccessor: x => x.volumeName,
          placeholder: 'Volume',
          type: 'select',
          span: 8,
          onChange: (val) => {
            setFieldsValue({ volumeId: val })
            this.getJournalsIssueData(val)
          }
        },
        {
          label: 'Issue',
          key: 'issueId',
          required: true,
          showSearch: true,
          options: this.state.issueList,
          keyAccessor: x => x._id,
          valueAccessor: x => x.issueName,
          placeholder: 'Issue',
          type: 'select',
          span: 8,
          onChange: (val) => {
            setFieldsValue({ issueId: val })
          }
        },

        {
          label: 'Country',
          key: 'country',
          required: true,
          showSearch: true,
          options: Countries,
          keyAccessor: x => x.name,
          valueAccessor: x => x.name,
          placeholder: 'Country',
          span: 8,
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ country: val })
            this.loadLastArticleNo()
          }
        },
        {
          label: 'Article No',
          key: 'articleNo',
          required: true,
          placeholder: 'Article No',
          span: 8,
          extraBox: (
            <React.Fragment>
              {lastArticleNo ? <label className={'label label-success'}>{lastArticleNo}</label> : null}
            </React.Fragment>
          ),
          onChange: (val) => {
            setFieldsValue({ articleNo: val.target.value.toUpperCase() })
          }
        },
        {
          label: 'Corresponding Author',
          key: 'correspondingAuthor',
          required: true,
          span: 8,
          placeholder: 'Corresponding Author'
        },
        {
          label: 'All Author Name',
          key: 'doiAuthorName',
          required: true,
          placeholder: 'All Author Name',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        {
          label: 'Keywords',
          key: 'doiKeywords',
          required: true,
          placeholder: 'Keywords',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        {
          label: 'Author Affiliation',
          key: 'correspondingAuthorAffiliation',
          required: true,
          placeholder: 'Author Affiliation',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        /*{
          label: 'DOI Number',
          key: 'doiNumber',
          required: true,
          placeholder: 'DOI Number'
        },*/
        /*{
          label: 'Page Range',
          key: 'pageRange',
          required: true,
          placeholder: 'Page Range'
        },*/
        {
          label: 'Authors Email',
          key: 'correspondingAuthorsEmail',
          required: true,
          placeholder: 'Authors Email',
          type: 'email'
        },
        {
          label: 'DOI Process Date',
          key: 'displayDate',
          required: true,
          placeholder: 'DOI Process Date',
          type: 'date'
        },

        {
          label: 'Abstract',
          key: 'doiAbstract',
          required: true,
          placeholder: 'Abstract',
          rows: 2,
          span: 24,
          customField: (
            <Col>
              <CustomCkEditor title={'Abstract'}
                              feeds={mentionsFeeds}
                              html={this.state.doiAbstract}
                              onChange={(data) => {
                                this.setState({ 'doiAbstract': data })
                              }}
              />
            </Col>)
        },
        {
          label: 'References',
          key: 'doiReferences',
          required: true,
          span: 24,
          customField: (
            <CustomCkEditor title={'References'}
                            key={'doiReferences'}
                            feeds={mentionsFeeds}
                            html={this.state.doiReferences}
                            onChange={(data) => {
                              this.setState({ 'doiReferences': data })
                            }}
            />)
        }
        /*  {
            label: 'articleFile',
            customField: (
              <InputBox title={`Article File`}>
                <Input type={'file'} name={'articleFile'} id={'articleFile'}
                       className={'form-control'} key={this.state.fileKey}
                       onChange={(e) => {
                         this.chooseDocument(e)
                       }} />
              </InputBox>
            )
          }*/


      ]

    }


    return (
      <PageHeaderWrapper
        title={'Add DOI Article'}>
        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              <Col span={24}>
                <Card title='Article Details' bordered={true}>
                  <Row gutter={16}>
                    {inputSchma.fields.map((item, key) => {
                      return (
                        <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                             key={key}>
                          {item.customField ? item.customField :
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />}
                        </Col>
                      )
                    })}
                  </Row>
                </Card>
              </Col>

            </Row>

            <Form.Item style={{ marginTop: 32 }}>
              <Button type='primary' htmlType='submit'>
                SUBMIT
              </Button>
            </Form.Item>

          </Form>


        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDirectArticle)
