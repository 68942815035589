import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Tooltip, Card, Button, Form, Row, Col, Radio, Input, Icon, notification } from 'antd'
import {
  DisplayDate,
  formItemLayout, InputBox,
  NotificationBox
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { ComposeArticle } from '../actions'
import moment from 'moment'
import { currencyList, PaymentModes, AssignToName } from '../../../components/_utils/appUtils'
import Request from '../../../request'
import _ from 'lodash'

const ComposingDrawer = (props) => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue, getFieldValue },
    callback = null,
    visible,
    onClose,
    article,
    edit
  } = props
  const dispatch = useDispatch()

  let [attachment, setAttachment] = useState({
    composingArticleAttachment: ''
  })
  let [journalVolumeList, setJournalVolumeList] = useState([])
  let [journalIssueList, setJournalIssueList] = useState([])
  let [volumeData, setVolumeData] = useState({})
  let [journalData, setJournalData] = useState({})
  let [issueData, setIssueData] = useState({})

  useEffect(() => {
    getJournalList()
    setTimeout(() => {
      setFormData()
    }, 1000)
  }, [])

  let setFormData = () => {
    let { article, edit } = props
    if (edit) {
      props.form.setFieldsValue({
        acceptedArticlePaymentDate: moment(article.acceptedArticlePaymentDate),
        assignToName: article.assignToName,
        composeJournalName: article.composeJournalName,
        composeVolumes: article.composeVolumes,
        composeIssues: article.composeIssues,
        journalId: article.journalId,
        volumeName: article.volumeName,
        issues: article.issues,
        composingArticleDate: moment(article.composingArticleDate),
        composed: moment(article.composed)
      })
      setTimeout(() => {
        if (article.journalId) {
          events.loadVolumes(article.journalId)
        }
      }, 500)
      setAttachment({
        composingArticleAttachment: article.composingArticleAttachment && article.composingArticleAttachment.path ? article.composingArticleAttachment.path : ''
      })
    } else {
      props.form.setFieldsValue({
        composingArticleDate: moment()
      })
    }
  }

  let [visibleDrawer, setVisibleDrawer] = useState(false)
  let [composeRevisedArticle, setComposeRevisedArticle] = useState({})
  let [journalNameList, setJournalNameList] = useState([])


  const events = {
    show: (event) => {
      setVisibleDrawer(true)
      setTimeout(() => {
        setFormData()
      }, 1000)

    },
    hide: (event) => {
      setVisibleDrawer(false)
    },
    loadVolumes: async (journalId) => {
      // let journalId = getFieldValue('journalId')
      let { data } = await Request.getJournalsVolumeList({ journalId: journalId })
      let journalObj = _.find(journalNameList, (item) => {
        return item._id == journalId
      })
      if (journalObj) {
        setJournalData({
          slugJournal: journalObj.slugJournal,
          journalId: journalObj._id,
          composeJournalName: journalObj.value
        })
      }
      let volumeList = []
      _.each(data, (item) => {
        volumeList.push({
          _id: item._id,
          volumeName: item.volumeName,
          slugVolume: item.slugVolume,
          volumeNumber: item.volumeNumber
        })
      })
      setJournalVolumeList(volumeList)
    },
    loadIssue: async (volumeName) => {
      console.log(volumeName, journalVolumeList)
      // let volumeName = getFieldValue('volumeName')
      let volume = _.find(journalVolumeList, (item) => {
        return item.volumeName.toLowerCase() == volumeName.toLowerCase()
      })
      if (volume && volume._id) {
        setVolumeData({
          volumeName: volume.volumeName,
          slugVolume: volume.slugVolume,
          sortByVolume: volume.volumeNumber,
          composeVolumes: volume.volumeName
        })
        let { data } = await Request.getJournalIssue({ journalId: getFieldValue('journalId'), volumeId: volume._id })
        let issueList = []
        _.each(data, (item) => {
          issueList.push({
            _id: item._id,
            issueName: item.issueName,
            issueNumber: item.issueNumber,
            slugIssue: item.slugIssue
          })
        })
        setJournalIssueList(issueList)
      }
    },
    chooseIssue: async (issueName) => {
      let volume = _.find(journalIssueList, (item) => {
        return item.issueName.toLowerCase() == issueName.toLowerCase()
      })
      if (volume && volume._id) {
        setIssueData({
          issues: volume.issueName,
          slugIssue: volume.slugIssue,
          sortByIssue: volume.issueNumber,
          composeIssues: volume.issueName
        })

      }
    }
  }

  useEffect(() => {
    events.loadIssue(getFieldValue('volumeName'))
  }, [getFieldValue('volumeName'), journalVolumeList])


  const inputSchema = {
    fields: [
      {
        label: 'Attachments',
        customField: (
          <InputBox title={`Upload Revised Article`} className={'rowFlex'}>
            <Input type={'file'} name={'revisedArticle'} id={'revisedArticle'}
                   className={'form-control'}
                   onChange={(e) => {
                     let { name, files } = e.target
                     if (files && files.length) {
                       setComposeRevisedArticle(files[0])
                     }
                   }} />
            {edit && attachment && attachment.composingArticleAttachment ?
              <a className={'linkBtn'} target={'_blank'} href={`${attachment.composingArticleAttachment}`}>
                <Icon type={'eye'} />
              </a> : null}
          </InputBox>
        )
      },
      {
        label: 'Date',
        span: 6,
        key: 'composingArticleDate',
        required: true,
        placeholder: 'Enter Composing Date',
        type: 'date'
      },
      {
        label: 'Assign To',
        key: 'assignToName',
        required: true,
        placeholder: 'Select',
        options: AssignToName,
        type: 'radioGroup',
        span: 10,
        onChange: (e) => {
          setFieldsValue({ assignToName: e.target.value })
        }
      },
      {
        label: 'Journals',
        key: 'journalId',
        placeholder: 'Select',
        type: 'select',
        options: journalNameList,
        keyAccessor: x => x._id,
        valueAccessor: x => x.value,
        onChange: (e) => {
          setFieldsValue({ journalId: e, volumeName: '', issues: '' })
          events.loadVolumes(e)
        }
      },
      {
        label: 'Volumes',
        key: 'volumeName',
        placeholder: 'volumes',
        type: 'select',
        required: true,
        keyAccessor: x => x.volumeName,
        valueAccessor: x => x.volumeName,
        options: journalVolumeList,
        onChange: (e) => {
          setFieldsValue({ volumeName: e, issues: '' })
          // events.loadIssue()
        }
      },
      {
        label: 'Issues',
        key: 'issues',
        placeholder: 'Issues',
        type: 'select',
        required: true,
        keyAccessor: x => x.issueName,
        valueAccessor: x => x.issueName,
        options: journalIssueList,
        onChange: (e) => {
          setFieldsValue({ issues: e })
          events.chooseIssue(e)
        }
      },
      {
        label: 'Composed',
        span: 6,
        key: 'composed',
        placeholder: 'Enter Composed Date',
        type: 'date',
        hidden: !edit
      }
    ]
  }

  const getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, (item) => {
      journalList.push({
        _id: item._id,
        value: item.name,
        display: item.name,
        slugJournal: item.name
      })
    })
    setJournalNameList(journalList)
  }


  const handleSubmit = e => {
    const { form, article } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {

      if (!err) {
        let fd = new FormData()
        let obj = {
          articleId: article._id,
          assignToName: valData.assignToName,
          composeJournalName: valData.composeJournalName,
          composeVolumes: valData.composeVolumes,
          composeIssues: valData.composeIssues,
          composed: valData.composed,
          ...journalData,
          ...volumeData,
          ...issueData
        }
        if (!edit) {
          obj.composingArticleDate = new Date()
          obj.articleStatus = 'Composing Article'
        } else {
          obj.composingArticleDate = valData.composingArticleDate
        }
        // console.log('obj', obj)
        fd.append('obj', JSON.stringify(obj))
        fd.append('composingArticleAttachment', composeRevisedArticle)
        // console.log('fd', fd)
        let { success } = await dispatch(ComposeArticle(fd))
        // console.log('success', success)
        if (success) {
          onClose()
          if (callback) {
            callback()
          }
          dispatch({ type: 'START_LOAD_ARTICLE' })
          events.hide()
        }
      } else {
        notification.error({
          message: 'Required',
          description: 'Fill all required fields.'
        })
      }
    })
  }

  return (
    <>
      <div onClick={() => events.show()}>
        {children}
      </div>
      <Drawer
        title={`Composing Article | Article No. ${article.articleNo}`}
        width={'70%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 8} key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />
                    }
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(ComposingDrawer)

export default WrappedForm
