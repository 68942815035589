import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Form
} from 'antd'
import Request from '../../../request'
import { connect } from 'react-redux'
import { TableComp, FormUtils } from 'sz-react-utils-lite'
import ReactHtmlParser from 'react-html-parser'
import { ChooseEmailModal, chooseEmailModalFxn, DisplayDate, formItemLayout } from '../../../components/_utils/appUtils'
import { getAllArticle } from '../actions'
import chooseEmailModal from '../../../components/_utils/chooseEmailModal'
import { ArticleFilters } from '../articleView/articlePages'
import { SendTemplateEmail } from '../../email/actions'
import AcceptanceDrawer from '../drawers/AcceptanceDrawer'
import GalleyProofDrawer from '../drawers/GalleyProofDrawer'


const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class ApprovedArticle extends Component {
  state = {
    edit: false,
    journalList: [],
    volumeList: [],
    issueList: [],
    isPublished: null,
    slugJournal: null,
    slugVolume: null,
    slugIssue: null,
    articleId: null,
    filters: {},
    visibleDrawer: false,
    article: {},
    total: ''
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let { dispatch, form: { getFieldValue } } = this.props
      let obj = {
        ...params,
        ...this.state.filters,
        articleStatus: 'Approved Article'
      }
      let regExpFilters = []

      let data = await dispatch(getAllArticle({ ...obj, regExFilters: regExpFilters }))
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }
  reloadTable = (data) => {
    if (data.fromDate && data.toDate) {
      data['submittedDate'] = {
        $gte: data.fromDate,
        $lte: data.toDate
      }
      delete data['fromDate']
      delete data['toDate']
    }
    this.setState({ filters: data }, () => {
      this.reload()
    })
  }


  deleteArticle = async (data) => {
    let resp = await Request.deleteArticle(data)
    this.reload()
  }


  handleResendApprove = (record) => {
    let { dispatch } = this.props
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          id: record._id,
          fromEmail: email,
          template: 'approvedArticle',
          type: 'article',
          attachmentArr: ['invoice']
        }
        let resp = await dispatch(SendTemplateEmail({
          email: obj.fromEmail,
          id: record._id,
          obj,
          template: obj.template
        }))
        if (resp && resp.success) {
          this.reload()
        }
      }
    })
  }


  sendMailFxn = (record) => {
    let { dispatch } = this.props
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          articleId: record._id,
          fromEmail: email,
          emailType: 'acknowledgement',
          template: record.processingCharges > 0 ? 'acknowledgementWithCharges' : 'acknowledgementWithoutCharges',
          type: 'article'
        }
        let resp = await dispatch(SendTemplateEmail({
          email,
          id: record._id,
          obj,
          template: obj.template
        }))
        if (resp && resp.success) {
          notification.success({
            message: resp.message
          })
          this.reload()
        }

      }
    })
  }

  render() {

    const { dispatch, form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading } = this.props
    const { journalList, volumeList, issueList } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }


    const columns = [
      {
        key: 'actions',
        title: 'Article Id',
        width: '85px',
        render: (val, record) => (
          <Tooltip title='Edit Details'>
            <a href={`/articleView/${record._id}`}>
              {record.articleNo}
            </a>
            {/*<a*/}
            {/*  onClick={() => {*/}
            {/*    dispatch(getPushPathWrapper('articleView', { id: record._id }))*/}
            {/*  }}>*/}
            {/*  {record.articleNo}*/}
            {/*</a>*/}
          </Tooltip>

        )
      },
      {
        title: 'Submitted Date',
        key: 'submittedDate',
        dataIndex: 'submittedDate',
        width: '100px',
        render: (item) => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'ManuScript Title',
        key: 'manuScriptTitle',
        width: '200px',
        render: (item) => {
          return item ? ReactHtmlParser(item) : null
        }
      },
      {
        title: 'Country',
        key: 'country'
      },
      {
        title: 'Author',
        key: 'correspondingAuthor'
      },
      {
        title: 'Email',
        key: 'correspondingAuthorsEmail'
      },
      {
        title: 'Approval Date',
        key: 'approvedArticleDate',
        width: 100,
        dataIndex: 'approvedArticleDate',
        render: (item) => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Reminder Date',
        key: 'approvalReminderDate',
        width: 100,
        dataIndex: 'approvalReminderDate',
        render: (item) => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Approval Amount',
        key: 'approvalAmount',
        dataIndex: 'approvalAmount'
      },
      {
        title: 'Action',
        key: 'Action',
        render: (item, record) => {
          return (
            <>
              {/*<ChooseEmailModal id={record._id} template={'approvedArticle'} obj={{ type: 'article' }}>*/}
              <Button onClick={() => this.handleResendApprove(record)} className={'roundSmallBtn'}>Resend
                Approve</Button>
              {/*</ChooseEmailModal>*/}
              <Button className={'roundSmallBtn'}
                      onClick={() => this.setState({ visibleDrawer: true, article: record })}>
                Acceptance</Button>
            </>
          )
        }
      }


    ]

    const fil =
      {
        labelCol: {
          xs: {
            span: 24
          }
          ,
          sm: {
            span: 24
          }
          ,
          md: {
            span: 24
          }
        }
        ,
        wrapperCol: {
          xs: {
            span: 24
          }
          ,
          sm: {
            span: 24
          }
          ,
          md: {
            span: 24
          }
        }
      }

    return (
      <PageHeaderWrapper
        title={`Approved Articles (${this.state.total})`}>
        <Card bordered={true}>
          <ArticleFilters callback={(data) => {
            this.reloadTable(data)
          }} />
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest} />
        </Card>
        {this.state.visibleDrawer ? <AcceptanceDrawer
          visible={this.state.visibleDrawer}
          articleId={this.state.article._id}
          article={this.state.article}
          Id={this.state.article._id}
          callback={this.reload}
          edit={false}
          onClose={() => this.setState({ visibleDrawer: false })}
        /> : null}
      </PageHeaderWrapper>
    )

  }
}


const mapStateToProps = (
  {
    global
  }
) => (
  {
    categories: global.categories
  }
)
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovedArticle)
