export * from './userActions'

export const showLoader = () => {
  return { type: 'SHOW_BTN_LOADING' }
}

export const hideLoader = () => {
  return { type: 'HIDE_BTN_LOADING' }
}

export const showPageLoad = () => {
  return { type: 'SHOW_PAGE_LOADING' }
}

export const hidePageLoad = () => {
  return { type: 'HIDE_PAGE_LOADING' }
}

export default {}