import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminDashboard from './adminDashboard'
import ReviewerDashboard from './reviewerDashboard'


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {}
    }
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'))
    this.setState({ user })
  }

  render() {
    let { dispatch } = this.props
    let { user } = this.state
    return (
      user ? user.userType == 'Admin' ? <AdminDashboard /> : user.userType == 'Reviewer' ?
        <ReviewerDashboard /> : null : null
    )
  }

}


const mapStateToProps = ({ global, router }) => ({
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)


