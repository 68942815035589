import React, { useState } from 'react'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { Drawer, notification, Form, Button, Row, Col, Card, Table } from 'antd'
import _ from 'lodash'
import { formItemLayout } from '../../../components/_utils/appUtils'

const EditRating = (props) => {
  const { form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props

  let { visible, onClose, selectedRating, onSubmit } = props

  let sectionFields = [
    {
      key: 'name',
      label: 'Name',
      required: true,
      span: 4,
      placeholder: 'Name',
      type: 'text',
      name: 'name'
    },
    {
      key: 'value',
      label: 'Rating',
      span: 4,
      placeholder: 'Rating',
      type: 'text'
    }]

  let handleSubmit = e => {
    const { dispatch, form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        // let cloneList = _.clone(ratings)
        // cloneList.push({ ...valData })
        // onSubmit(cloneList)
      }
    })
  }

  return (
    <Drawer visible={visible} title={'Add Rating'}
            onClose={onClose} width={'30%'}>
      <div>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>

            <Row gutter={16}>
              {sectionFields.map((item, key) => {
                return (
                  <Col span={24} key={key}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout} />
                  </Col>
                )
              })}
            </Row>

            <Form.Item style={{ marginTop: 32 }}>
              <Button type='primary' htmlType='submit'>
                UPDATE
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Drawer>
  )
}
const WrappedEditRating = Form.create()(EditRating)
export default WrappedEditRating

