import React, { useState, useEffect } from 'react'
import { InputBox, mentionsFeeds } from './appUtils'
import { Form, Input } from 'antd'
import CKEditor from 'react-ckeditor-component'
import moment from 'moment'

const { TextArea } = Input


class FullCkEditor extends React.Component {
  constructor(props) {
    super()
    this.state = {
      isLoaded: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoaded: true })
    }, 500)
  }

  render() {
    let { title, onChange, feeds = [], html, extraBox = null } = this.props
    let { isLoaded } = this.state
    return (
      isLoaded && <InputBox title={title} extra={extraBox}>

        <CKEditor
          scriptUrl={'/ckeditorFull/ckeditor.js'}
          content={html}
          config={{
            mentions: [{ feed: mentionsFeeds, minChars: 0, marker: '@' }]
          }}
          events={{
            change: ({ editor }) => {
              onChange(editor.getData())
            }
          }}

        />
      </InputBox>
    )
  }
}

export default FullCkEditor

