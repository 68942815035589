import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Dropdown, Menu, Form, Drawer, Row, Col
} from 'antd'
import Request from '../../../request'
import { TableComp } from 'sz-react-utils-lite'
import { push } from 'connected-react-router'
import { hideLoader, showLoader } from '../../../modules/actions'
import { FormUtils } from 'sz-react-utils-lite'
import { DefaultTablePagination, formItemLayout } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

@Form.create()

class IssueJournalForm extends Component {
  issueTable = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      total: 0
    }
  }

  showDrawer = () => {
    this.setState({ visible: true })
  }
  onClose = () => {
    this.setState({ visible: false })
  }

  getJournalsIssueData = (params) => {
    return new Promise(async (resolve) => {
      let { volumeData: { journalId, volumeId } } = this.props
      let resp = await Request.getJournalIssue({ journalId: journalId, volumeId: volumeId, ...params })
      console.log('google.com', resp)
      this.setState({ total: resp.total })
      resolve(resp)
    })
  }

  loadVolume = () => {
    this.issueTable.current.reload()
  }

  handleSubmitIssue = (e) => {
    const { dispatch, form, volumeData } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { journalId, volumeId } = volumeData
        // dispatch(showLoader())
        let x = await Request.addJournalIssue({ ...valData, journalId: journalId, volumeId: volumeId })
        let { error, message } = x
        console.log(error, message)
        // dispatch(hideLoader())

        if (!error) {
          notification.success({
            message: message
          })
          this.loadVolume()
          form.resetFields()


        } else {
          notification.error({
            message: message,
            description: message
          })
        }
      }
    })
  }

  handleRemoveIssue = async (record) => {
    let x = await Request.removeJournalIssue({
      _id: record._id,
      journalId: record.journalId,
      slugIssue: record.slugIssue
    })
    let { error, msg } = x
    if (!error) {
      notification.success({
        message: msg
      })
      this.loadVolume()
    } else {
      notification.error({
        message: msg,
        description: msg
      })
    }
  }

  render() {
    let { volumeData } = this.props
    const columnsData = [
      {
        title: 'Serial No.',
        dataIndex: 'issueNumber',
        key: 'issueNumber'
        // render: text => <a>{text}</a>
      },
      {
        title: 'Name',
        dataIndex: 'issueName',
        key: 'issueName'
      },
      {
        title: 'Action',
        key: 'action',
        render: (val, record) => {
          return (<div>
            <Button icon={'close'} onClick={() => this.handleRemoveIssue(record)}>
              Remove
            </Button>
          </div>)
        }
      }
    ]
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading, visible, onClose } = this.props

    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }
    const inputSchema = {
      fields: [
        {
          label: 'Issue name', key: 'issueName', required: true, placeholder: 'Issue Name', onChange: (e) => {
            setFieldsValue({ issueName: e.target.value })
          }
        },
        { label: 'Issue Number', key: 'issueNumber', required: true, placeholder: 'Issue Number', type: 'number' }
      ]
    }
    return (
      <Drawer
        title={`Manage Issue - ${volumeData.journalName} (Volume - ${volumeData.volumeName})`}
        width={'900'}
        placement='right'
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <div style={{ padding: 10 }}>
          <Card bordered={true}>
            <Form onSubmit={this.handleSubmitIssue} layout={'inline'} hideRequiredMark className={'inputForm'}>
              <Row gutter={16}>
                {inputSchema.fields.map((item, key) => {
                  return (
                    <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />
                    </Col>
                  )
                })}
                <Col span={8}>
                  <Form.Item style={{ marginTop: 38 }}>
                    <Button type='primary' htmlType='submit' loading={this.props.loading}>
                      ADD
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              {/* <FormUtils inputSchema={inputSchema} {...formProps} />

              <Form.Item style={{ marginTop: 32 }}>
                <Button type='primary' htmlType='submit' loading={this.props.loading}>
                  ADD
                </Button>
              </Form.Item>*/}
            </Form>
            <h7>Total Volumes: {this.total}</h7>
            <TableComp columns={columnsData} ref={this.issueTable}
                       apiRequest={this.getJournalsIssueData}
                       pagination={DefaultTablePagination()} />

          </Card>
        </div>
      </Drawer>
    )
  }
}

export default IssueJournalForm
