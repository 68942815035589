import React, { useState, useEffect } from 'react'
import { InputBox, mentionsFeeds } from './appUtils'
import { Form, Input } from 'antd'
import CKEditor from 'react-ckeditor-component'

const { TextArea } = Input

class CustomCkEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoaded: true })
    }, 500)
  }

  render() {
    let { title, onChange, feeds = [], html, extraBox = null, customCls = '' } = this.props
    let { isLoaded } = this.state
    return (
      <InputBox title={title} extra={extraBox}>
        <CKEditor
          activeClass={customCls}
          // extraPlugins={['mentions']}
          scriptUrl={'/ckeditor/ckeditor.js'}
          content={html}
          /*config={{
            mentions: [{ feed: mentionsFeeds, minChars: 0, marker: '@' }]
          }}*/
          events={{
            change: ({ editor }) => {
              onChange(editor.getData())
            }
          }}
        />
      </InputBox>
    )
  }
}

export default CustomCkEditor

