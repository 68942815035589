import React, { Component } from 'react'
import Request from '../../request'
import { Button, Card, Icon, Tooltip, Drawer, Select, Modal, notification, Popconfirm } from 'antd'
import { getPushPathWrapper, getUrlParams } from '../../routes'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import { connect } from 'react-redux'
import { DisplayDate, InputBox, DesignationList, Countries } from '../../components/_utils/appUtils'
import { listAllReviewer, UpdateReviewerStatus } from './actions'


const { Option } = Select
const statusFilter = [
  'Pending',
  'Accepted',
  'Rejected'
]

class reviewer extends Component {


  constructor(props) {
    super(props)
    this.state = {
      journals: [],
      countryList: [],
      visible: false,
      otherJournalList: [],
      selectedSubmissionId: '',
      total: ''
    }
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let data = await listAllReviewer({
        ...params,
        regExFilters: ['email', 'correspondingAuthor', 'correspondingAuthorEmail', 'correspondingAuthorAffiliation']
      })
      console.log(data)
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }

  deleteNewsLetter = async (data) => {
    let resp = await Request.deleteNewsLetter(data)
    this.reload()
  }

  componentDidMount() {
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }
  handleReject = async (Id) => {
    const { dispatch } = this.props
    let obj = {
      id: Id,
      status: 'Rejected'
    }
    let resp = await dispatch(UpdateReviewerStatus(obj))
    if (resp && resp.success) {
      this.table.current.reload()
    }
  }

  render() {

    const { dispatch } = this.props

    const columns = [
      {
        title: 'Date',
        key: 'createdAt',
        dataIndex: 'createdAt',
        searchDateName: 'createdAt',
        width: 100,
        render: (item) => {
          return DisplayDate(item)
        }
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Designation',
        key: 'designation',
        dataIndex: 'designation',
        width: 100,
        filters: DesignationList.map((x) => ({ text: x, value: x }))
      },
      {
        title: 'Area of interest',
        key: 'areaOfInterest',
        dataIndex: 'areaOfInterest',
        searchTextName: 'areaOfInterest'
      },
      {
        title: 'Institute Affiliated with',
        key: 'institutionWebsite',
        dataIndex: 'institutionWebsite',
        searchTextName: 'institutionWebsite'
      },
      {
        title: 'Department',
        key: 'department',
        dataIndex: 'department',
        searchTextName: 'department'
      },
      {
        title: 'Country',
        key: 'country',
        dataIndex: 'country',
        filters: Countries.map((x) => ({ text: x.name, value: x.name }))
      },
      {
        title: 'Email ID',
        key: 'email',
        dataIndex: 'email',
        width: 150,
        searchTextName: 'email'

      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        filters: statusFilter.map((x) => ({ text: x, value: x }))
      },
      {
        title: 'Actions',
        key: 'actions',
        width: 85,
        render: (val, record) => (
          record && record.status === 'Pending' ? <React.Fragment>

            <Tooltip title={'Accept'}>
              <Button shape={'circle'} onClick={() => {
                dispatch(getPushPathWrapper('member.reviewer', { id: record._id }))
              }}>
                <Icon type={'check'} />
              </Button>{'  '}
            </Tooltip>

            <Tooltip title={'Reject'}>
              <Popconfirm placement='topLeft' title={`Are you sure you want to Reject?`}
                          onConfirm={() => this.handleReject(record._id)} okText='Yes' cancelText='No'>
                <Button shape={'circle'}>
                  <Icon type={'cross'} />
                </Button>
              </Popconfirm>
            </Tooltip>
          </React.Fragment> : null
        )
      }

    ]
    return (
      <PageHeaderWrapper
        title={`Become Reviewer (${this.state.total})`}>
        <Card bordered={true}>
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest} />
        </Card>
      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reviewer)
