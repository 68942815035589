import React, { useRef, useState, useEffect } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { Drawer, Tooltip, Card, Button, Popconfirm, Form, Row, Col, Input, notification, Icon } from 'antd'
import {
  DisplayDate, InputBox, formItemLayout
} from '../../../components/_utils/appUtils'
import { getAllMember } from '../../members/actions'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { EditReceiveDate } from '../actions'
import { apiUrl } from '../../../settings'
import moment from 'moment'

const SelectReceiveDate = (props) => {
  let { visible, onClose, article, reviewerId, form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props
  let [articleAttachment, setArticleAttachment] = useState({})
  let [commentsAttachment, setCommentsAttachment] = useState({})
  let [attachment, setAttachment] = useState({
    articleAttachment: '',
    commentsAttachment: ''
  })
  const dispatch = useDispatch()
  useEffect(() => {
    setDefaultData()
  }, [])

  let setDefaultData = () => {
    let { underReviewDetails } = article
    let { articleAttachment, commentsAttachment } = underReviewDetails
    let { form: { setFieldsValue } } = props
    setTimeout(() => {
      setFieldsValue({ receiveDate: underReviewDetails.receiveDate ? moment(underReviewDetails.receiveDate) : null })
    }, 500)
    setAttachment({
      articleAttachment: articleAttachment && articleAttachment.path ? articleAttachment.path : '',
      commentsAttachment: commentsAttachment && commentsAttachment.path ? commentsAttachment.path : ''
    })

  }
  const events = {}
  const inputSchema = {
    fields: [
      {
        label: 'Receive Date',
        key: 'receiveDate',
        type: 'date',
        required: true,
        placeholder: 'Receive Date'
      },
      {
        label: 'Comments attachment',
        customField: (
          <InputBox title={`Comments attachment*`} className={'rowFlex'}>
            <Input type={'file'} name={'commentsAttachment'} id={'commentsAttachment'}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseDocument(e)
                   }} />
            {attachment && attachment.commentsAttachment ?
              <a className={'linkBtn'} target={'_blank'} href={`${attachment.commentsAttachment}`}>
                {console.log(attachment.commentsAttachment)}
                <Icon type={'eye'}></Icon>
              </a> : null}
          </InputBox>
        )
      },
      {
        label: 'Article attachment',
        customField: (
          <InputBox title={`Article attachment*`} className={'rowFlex'}>
            <Input type={'file'} name={'articleAttachment'} id={'articleAttachment'}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseDocument(e)
                   }} />
            {attachment && attachment.articleAttachment ?
              <a className={'linkBtn'} target={'_blank'} href={`${attachment.articleAttachment}`}>
                <Icon type={'eye'}></Icon>
              </a> : null}
          </InputBox>
        )
      }
    ]

  }


  const chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      if (name == 'commentsAttachment') {
        setCommentsAttachment(files[0])
      }
      if (name == 'articleAttachment') {
        setArticleAttachment(files[0])
      }
    }
  }


  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let fd = new FormData()
        let obj = {
          ...valData,
          articleId: article._id
        }
        fd.append('obj', JSON.stringify(obj))
        if (articleAttachment && articleAttachment.name) {
          fd.append('articleAttachment', articleAttachment)
        }
        if (commentsAttachment && commentsAttachment.name) {
          fd.append('commentsAttachment', commentsAttachment)
        }

        let { success, data } = await dispatch(EditReceiveDate(fd))
        if (success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
          onClose()
        }
      }
    })
  }
  return (
    <Drawer
      title={'Select Receive Date'}
      width={'65%'}
      visible={visible}
      placement='right'
      onClose={onClose}>
      <Card>
        <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
          <Row gutter={16}>
            {inputSchema.fields.map((item, key) => {
              return (
                <Col span={8} key={key}>
                  {item.customField ? item.customField :
                    <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                       item={item}
                                       formItemLayout={formItemLayout} />}
                </Col>
              )
            })}
            <Col span={24}>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Drawer>
  )
}


const WrappedForm = Form.create()(SelectReceiveDate)
export default WrappedForm
