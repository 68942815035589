import React, { useEffect, useState } from 'react'
import { getSingleMember } from '../../members/actions/index'
import { Row, Col, Card, Drawer, Form, Button, Icon } from 'antd'
import { DisplayDate, formItemLayout, GetDownloadLink } from '../../../components/_utils/appUtils'
import ReactHtmlParser from 'react-html-parser'
import { RowTable, CustomCardTitle } from './RowTable'
import moment from 'moment'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { connect } from 'react-redux'
import { getPushPathWrapper } from '../../../routes'

const ViewMember = (props) => {

  let [visible, setVisible] = useState(false)
  let [revData, setRevData] = useState({})

  // useEffect(() => {
  //
  // }, [])
  const getMemberDetails = async (id) => {
    let { dispatch } = props
    let respData = await dispatch(getSingleMember(id))
    setRevData(respData.data)
    console.log('respData', respData)
  }

  let events = {
    showDrawer: (id) => {
      setVisible(true)
      getMemberDetails(id)
    },
    hideDrawer: () => {
      setVisible(false)
    }
  }

  let { revId, children, dispatch } = props
  return (
    <React.Fragment>

      <span onClick={() => {
        console.log(revId)
        events.showDrawer(revId)
      }}>{children}</span>

      {visible ?
        <Drawer visible={visible}
                width={'70%'}
                placement='right'
                onClose={events.hideDrawer}
                title={(
                  <div>
                    {`${revData.name}`}
                    <a className={'linkIcon'} onClick={() => {
                      dispatch(getPushPathWrapper('member.edit', { id: revData._id }))
                    }}>
                      {' '}<Icon type={'edit'} />
                    </a>
                  </div>
                )}>
          <Card>
            <div className={'tableBox striped'}>
              {/*<RowTable title={'Joining Date'} value={data.}/>*/}
              <RowTable title={'Rating'} value={revData.rating} />
              <RowTable title={'Country'} value={revData.country} />
              <RowTable title={'Name'} value={revData.name} />
              <RowTable title={'Area of Interest'} value={revData.areaOfInterest} />
              <RowTable title={'Address'} value={revData.address} />
              <RowTable title={'Country'} value={revData.country} />
              <RowTable title={'Contact Details'} value={revData.contactNo} />
              <RowTable title={'Journals'} value={
                <ul>
                  {
                    revData.journals && revData.journals.length ? revData.journals.map((item, key) => {
                      return (
                        <li key={key}>{item}</li>
                      )
                    }) : null
                  }
                </ul>
              } />
              <RowTable title={'gender'} value={revData.gender} />
              <RowTable title={'Designation'} value={revData.designation} />
              <RowTable title={'Department'} value={revData.department} />
              <RowTable title={'Institute Affiliated with'} value={revData.instituteAffiliatedWith} />
              <RowTable title={'Email'} value={revData.email} />
              <RowTable title={'Cert. send Date'} value={revData.certificateSentDate} />
              <RowTable title={'Description'} value={ReactHtmlParser(revData.description)} />
              <RowTable title={'Other Details'} value={revData.otherDetails} />
              <RowTable title={'Attachments'} value={''} />
            </div>
          </Card>
        </Drawer>
        : null}
    </React.Fragment>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapDispatchToProps
)(ViewMember)

// export default ViewMember
