import React, { useRef, useState, useEffect } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { Drawer, Tooltip, Card, Button, Popconfirm, Form, Row, Col, Input, notification, Checkbox } from 'antd'
import {
  formItemLayout,
  NotificationBox, InputBox
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { SendReceiveDate } from '../actions'
import moment from 'moment'

const SelectReceiveDate = (props) => {
  let { visible, onClose, article, reviewerId, form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props
  let [articleAttachment, setArticleAttachment] = useState({})
  let [commentsAttachment, setCommentsAttachment] = useState({})
  let [checked, setChecked] = useState(false)
  let [articleSeeAgain, setArticleSeeAgain] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      setFieldsValue({ receiveDate: moment() })
    }, 500)
  }, [])
  const events = {}
  const inputSchema = {
    fields: [
      {
        label: 'Receive Date',
        key: 'receiveDate',
        type: 'date',
        span: 12,
        required: true,
        placeholder: 'Receive Date'
      },
      {
        label: 'Comments attachment',
        span: 12,
        customField: (
          <InputBox title={`Comments attachment*`}>
            <Input type={'file'} name={'commentsAttachment'} id={'commentsAttachment'}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseDocument(e)
                   }} />
          </InputBox>
        )
      },
      {
        label: 'Article attachment',
        span: 12,
        customField: (
          <InputBox title={`Article attachment*`}>
            <Input type={'file'} name={'articleAttachment'} id={'articleAttachment'}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseDocument(e)
                   }} />
          </InputBox>
        )
      },
      {
        // label: 'Early Comments (Apart from this your early response on the article is highly appreciated)',
        type: 'checkBox',
        key: 'earlyComments',
        span: 18,
        customField: (
          <Checkbox onChange={(e) => {
            setChecked(!checked)
          }} title={`Article attachment*`}>
            Early Comments (Apart from this your early response on the article is highly appreciated)
          </Checkbox>
        )
      },
      {
        type: 'checkBox',
        key: 'earlyComments',
        span: 18,
        customField: (
          <Checkbox style={{ marginTop: 10 }} onChange={(e) => {
            setArticleSeeAgain(!articleSeeAgain)
          }} title={`Article attachment*`}>
            Reviewer wants to see the Article again.
          </Checkbox>
        )
      }

    ]

  }


  const chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      if (name == 'commentsAttachment') {
        setCommentsAttachment(files[0])
      }
      if (name == 'articleAttachment') {
        setArticleAttachment(files[0])
      }
      // this.setState({ [name]: files[0] })
    }
  }


  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (!articleAttachment || (articleAttachment && !articleAttachment.name)) {
          notification.error({
            message: 'Choose Article Attachment'
          })
          return
        }
        if (!commentsAttachment || (commentsAttachment && !commentsAttachment.name)) {
          notification.error({
            message: 'Choose Comments Attachment'
          })
          return
        }
        let fd = new FormData()
        let obj = {
          ...valData,
          articleId: article._id,
          earlyComments: checked,
          articleSeeAgain: articleSeeAgain,
          reviewerId
        }
        console.log('obj', obj)
        chooseEmailModalFxn({
          callback: async (email) => {
            obj.fromEmail = email
            fd.append('obj', JSON.stringify(obj))
            fd.append('articleAttachment', articleAttachment)
            fd.append('commentsAttachment', commentsAttachment)
            let { success, data } = await dispatch(SendReceiveDate(fd))
            if (success) {
              dispatch({ type: 'START_LOAD_ARTICLE' })
              onClose()
            }

          }
        })


        /* NotificationBox({
           title: 'Thank you for submitting the manuscript.',
           message: 'All acknowledgement email has been sent to your email address, please quote the manuscript number for all the future correspondences, You can track progress of your manuscript through author portal.',
           callback: () => {
             form.resetFields()
             this.setState({ authors: [], fileKey: moment() })
           }
         })*/

      }
    })
  }
  return (
    <Drawer
      title={'Select Receive Date'}
      width={'55%'}
      visible={visible}
      placement='right'
      onClose={onClose}>
      <Card>
        <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
          <Row gutter={16} className={'wrapRow'}>
            {inputSchema.fields.map((item, key) => {
              return (
                <Col span={item.span ? item.span : 8} key={key}>
                  {item.customField ? item.customField :
                    <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                       item={item}
                                       formItemLayout={formItemLayout} />}
                </Col>
              )
            })}
            <Col style={{ marginTop: 50 }} span={24}>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>

        </Form>

      </Card>
    </Drawer>
  )
}


const WrappedForm = Form.create()(SelectReceiveDate)
export default WrappedForm
