import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  notification,
  Card,
  Icon, Row, Col, Table
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { hideLoader, showLoader } from '../../../modules/actions'
import { apiUrl as apiurl } from '../../../settings'
import { connect } from 'react-redux'
import { getUrlParams } from '../../../routes'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import FormUtils from '../../../components/_utils/formUtils'
import { AddJournal, UpdateJournal, GetJournal } from '../actions'
import { getSlugUrl, InputBox, formItemLayout } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import AddRating from '../Ratings/addRating'
import EditRating from '../Ratings/editRating'

@Form.create()
class AddJournals extends PureComponent {

  state = {
    edit: false,
    fileKey: moment(),
    oldJournalFile: {},
    visible: false,
    editVisible: false,
    selectedRating: {},
    ratings: []
  }

  constructor(props) {
    super(props)
  }

  events = {
    addRatings: (data) => {
      this.setState({
        ratings: data,
        visible: false
      })
    },
    removeRating: (index) => {
      let { ratings } = this.state
      let cloneList = _.clone(ratings)
      cloneList.splice(index, 1)
      this.setState({
        ratings: cloneList
      })
    },
    editRating: (data, index) => {
      this.setState({
        editVisible: true,
        selectedRating: {
          ...data,
          index
        }
      })
    },
    closeEditRating: (data) => {
      this.setState({
        editVisible: false,
        selectedRating: {}
      })
    },
    updateRatings: (data) => {
      let { ratings } = this.state
      let findRating = _.find(ratings, (item, key) => {
        return key == data.index
      })
      if (findRating) {
        findRating.name = data.name
        findRating.rating = data.rating
      }
      this.setState({
        ratings: ratings
      })
    }
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { id: _id, journalFile, ratings } = this.state
        let x = {}
        let fd = new FormData()
        if (!!_id) {
          valData._id = _id
        }
        valData.ratings = ratings
        fd.append('obj', JSON.stringify(valData))

        if (journalFile && journalFile.name) {
          fd.append('journalFile', journalFile)
        }

        if (!!_id) {

          x = await dispatch(UpdateJournal(fd))
        } else {
          x = await dispatch(AddJournal(fd))
        }

        let { error, message } = x

        if (!error) {
          notification.success({
            message: message
          })

          if (!_id) {
            form.resetFields()
            this.setState({ fileKey: moment(), ratings: [] })
          }
        } else {
          notification.error({
            message: message,
            description: message
          })
        }
      }
    })
  }

  handleChange = (item) => {

  }
  chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  componentDidMount() {
    let data = getUrlParams('journals.edit', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setState({
        edit: true,
        id: id
      })
      this.setFormValues(id)
    }
  }

  setFormValues = async (id) => {

    const { dispatch, form } = this.props

    dispatch(showLoader())

    let { error, data } = await dispatch(GetJournal({ id }))

    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      setTimeout(() => {
        form.setFieldsValue(data)
        let obj = {}
        if (data.journalFile && data.journalFile.path) {
          obj.oldJournalFile = data.journalFile
        }
        if (data.ratings && data.ratings.length) {
          obj.ratings = data.ratings
        }
        this.setState({ ...obj })

      }, 500)
    }

    dispatch(hideLoader())

  }

  render() {

    const { edit, oldJournalFile, visible, ratings, editVisible, selectedRating } = this.state
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue, loading } } = this.props

    const formProps = {
      getFieldDecorator, getFieldValue, apiurl, loading
    }


    const inputSchma = {
      fields: [
        {
          label: 'Journal Name ', key: 'name', required: true, placeholder: 'Journal Name',
          onChange: ({ target }) => {
            let { value } = target
            setFieldsValue({
              name: value,
              slugJournal: getSlugUrl(value)
            })
          }
        },
        { label: 'Header', key: 'header', placeholder: 'Header' },
        { label: 'Tag Line', key: 'tagLine', placeholder: 'Tag Line' },
        // { label: 'Journal Tag', key: 'journalTag', placeholder: 'Journal Tag' },
        { label: 'Slug Journal ', key: 'slugJournal', required: true, placeholder: 'Slug Journal' },
        { label: 'Chief Editor', key: 'chiefEditor.name', placeholder: 'Chief Editor name' },
        { label: 'Print Issn', key: 'printIssn', placeholder: 'Print Issn' },
        { label: 'Online Issn', key: 'onlineIssn', placeholder: 'Online Issn' },
        // { label: 'Title', key: 'title', placeholder: 'Title' },
        //{ label: 'Order Number', key: 'orderNumber', type: 'number', placeholder: 'Order Number' },
        {
          label: 'Journal File ', key: 'journalFile', type: 'file',
          customField: (
            <InputBox title={`Journal File`} className={'rowFlex'}>
              <Input type={'file'} name={'journalFile'} id={'journalFile'}
                     className={'form-control'} key={this.state.fileKey}
                     onChange={(e) => {
                       this.chooseDocument(e)
                     }} />
              {oldJournalFile && oldJournalFile.path ?
                <a className={'linkBtn'} target={'_blank'} href={oldJournalFile.path}>
                  <Icon type={'eye'}></Icon>
                </a> : null}
            </InputBox>
          )

        },
        { label: 'Month(s) of publication', key: 'publication', placeholder: 'Month(s) of publication' },
        {
          label: 'Scientifical Journal Ranking (scopus)',
          key: 'ranking',
          placeholder: 'Scientifical Journal Ranking (scopus)'
        },
        {
          label: 'National Academy of Agricultural Services (NAAS)',
          key: 'rating',
          placeholder: 'National Academy of Agricultural Services (NAAS) '
        },
        {
          label: 'Impact Factor (ISI Thomson Reuters)',
          key: 'factor',
          placeholder: 'Impact Factor (ISI Thomson Reuters)'
        },
        { label: 'Print Frequency', key: 'frequency', placeholder: 'Print Frequency' },
        // { label: 'Details', key: 'details', type: 'textArea', placeholder: 'Details' },
        { label: 'Related Subjects', key: 'subjects', type: 'textArea', placeholder: 'Related Subjects', span: 24 },
        {
          label: 'Indexing services',
          key: 'indexingServices',
          type: 'textArea',
          placeholder: 'Indexing services',
          span: 24
        },
        // { label: 'Meta Journal', key: 'metaJournal', placeholder: 'Meta Journal' },
        // { label: 'MetaTitle', key: 'metaTitle', placeholder: 'Meta Title' },
        {
          label: 'Description',
          key: 'description',
          placeholder: 'Description',
          type: 'editor',
          rows: 8,
          height: 100,
          span: 24
        },
        {
          label: 'Aims & Scope',
          key: 'aims',
          placeholder: 'aims',
          type: 'textArea',
          rows: 8,
          height: 100,
          span: 24
        }
      ]

    }
    let column = [
      {
        title: ' Serial No. ',
        dataIndex: 'orderNo',
        //align: 'center',
        key: 'index',
        render: (text, record, index) => index + 1
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating'
      },
      {
        title: 'action',
        dataIndex: 'action',
        key: 'action',
        render: (item, record, index) => {
          return (
            <div key={index}>
              <Button key={index}
                      shape='circle' icon='delete'
                      onClick={() => this.events.removeRating(index)}>
              </Button>
            </div>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper
        title={edit ? 'Edit Journal' : 'Add Journal'}>

        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark className={'inputForm'}>

            <Row gutter={16} className={'wrapBox'}>
              {inputSchma.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                       key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />}
                  </Col>
                )
              })}
            </Row>
            <Row gutter={16} className={'wrapBox'}>
              <Col>
                <a className={'ant ant-btn mb10'} onClick={() => {
                  this.setState({ visible: true })
                }}>
                  Add Rating
                </a>
              </Col>
              <Col span={24}>
                <Table dataSource={ratings} size={'small'} pagination={false} columns={column} bordered={true} />
              </Col>
            </Row>
            {/*  <FormUtils inputSchema={inputSchma}
                       {...formProps}
            />*/}

            <Form.Item style={{ marginTop: 32 }}>
              <Button type='primary' htmlType='submit' loading={this.props.loading}>
                {edit ? 'UPDATE' : 'SAVE'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
        {visible ? <AddRating
          visible={visible}
          onClose={() => {
            this.setState({ visible: false })
          }}
          onSubmit={this.events.addRatings}
          ratings={ratings} /> : null}
        {/*   {editVisible ? <EditRating
          visible={editVisible}
          onClose={this.events.closeEditRating}
          onSubmit={this.events.updateRatings}
          selectedRating={selectedRating} /> : null}*/}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddJournals)
