import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Card,
  Tooltip,
  Button, Popconfirm
} from 'antd'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import styles from './styles.less'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'


class AllNews extends Component {

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let data = await Request.getAllNews(params)
      resolve(data)
    })
  }

  componentDidMount() {

  }

  reload = () => {
    this.table.current.reload()
  }
  deleteNews = async (data) => {
    let resp = await Request.deleteNews(data)
    this.reload()
  }

  render() {

    const { dispatch } = this.props

    const columns = [
      {
        title: 'Announcement',
        key: 'news',
        sorter: true,
        dataIndex: 'news',
        searchTextName: 'news'
      },
      {
        key: 'actions', render: (val) => (<React.Fragment>

          <Tooltip title='Edit Details'>
            <Button className={styles.btn}
                    shape='circle'
                    onClick={() => {
                      dispatch(getPushPathWrapper('news.edit', { id: val._id }))
                    }} icon='edit' />
          </Tooltip>

          <Tooltip title='Delete Announcement'>
            <Popconfirm placement='topLeft' title={`Are yoy sure, You want to delete this Announcement?`}
                        onConfirm={() => this.deleteNews({ _id: val._id })} okText='Yes' cancelText='No'>
              <Button className={styles.btn}
                      type='danger' shape='circle' icon='delete' />
            </Popconfirm>
          </Tooltip>
        </React.Fragment>)

      }
    ]
    return (
      <PageHeaderWrapper
        title={'All Announcement'}>

        <Card bordered={true}>
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest} />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllNews)
