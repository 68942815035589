import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  notification,
  Card,
  Icon,
  Row, Col, Table, Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { hideLoader, showLoader } from '../../../modules/actions'
import { apiUrl } from '../../../settings'
import Request from '../../../request'
import { connect } from 'react-redux'
import { getUrlParams } from '../../../routes'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { FormUtils } from 'sz-react-utils-lite'
import { CountriesList, JournalList } from '../../journals/actions'
import {
  DesignationList,
  Countries,
  InputBox,
  MemberTypes,
  MemberPositions,
  MemberRatings,
  AuthorTitle,
  Genders, Discipline
} from '../../../components/_utils/appUtils'
import { addMember, getNextReviewerId, checkMemberEmailsUpdate, getSingleMember, updateMember } from '../actions'
import { CustomCkEditor } from '../../../components/_utils/appUtils'
import MentionInfo from '../../email/mentionInfo'

@Form.create()
class EditArticle extends PureComponent {

  state = {
    edit: false,
    journalListArr: [],
    memberImage: {},
    memberCV: {},
    fileKey: moment(),
    reviewerId: '',
    reviewerIdNumber: '',
    ckeditorKey: moment() + 1,
    id: '',
    memberImageUrl: '',
    memberCVUrl: '',
    description: '',
    userDetails: {}
  }

  constructor(props) {
    super(props)
  }

  resetFormData = () => {
    const { dispatch, form } = this.props
    form.setFieldsValue({
      enterDate: moment(),
      memberType: '',
      journals: [],
      rating: '',
      position: '',
      department: '',
      title: '',
      areaOfInterest: '',
      // discipline: '',
      category: '',
      name: '',
      instituteAffiliatedWith: '',
      gender: '',
      address: '',
      designation: '',
      country: '',
      email: '',
      instituteWebsite: '',
      secondaryEmail: '',
      contactNo: '',
      otherDetails: '',
      description: ''
    })
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      let id = this.state.id
      if (!err) {
        let { memberImage, memberCV } = this.state

        let resp = await dispatch(checkMemberEmailsUpdate({ ...valData, id }))
        if (resp && resp.success) {
          let fd = new FormData()
          valData._id = id
          // valData.reviewerId = this.state.reviewerId
          valData.description = this.state.description
          fd.append('obj', JSON.stringify(valData))

          if (memberCV && memberCV.name) {
            fd.append('memberCV', memberCV)
          }
          if (memberImage && memberImage.name) {
            fd.append('memberImage', memberImage)
          }
          let { success, message } = await dispatch(updateMember(fd))
          if (success) {
            notification.success({
              message: message
            })

          }
        }

      }
    })
  }

  setFormValuesFxn = async (id) => {

    const { dispatch, form } = this.props
    let { error, data } = await dispatch(getSingleMember(id))
    console.log('%%%%%%%%%%DATA%%%%%%%%%%%', data)
    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      form.setFieldsValue({ ...data })
      if (data.designation == 'Others') {
        setTimeout(() => {
          form.setFieldsValue({ otherDesignation: data.otherDesignation })
        }, 500)
      }
      if (data.memberType == 'Editorial Board') {
        setTimeout(() => {
          form.setFieldsValue({ position: data.position })
        }, 500)
      }
      this.setState({
        reviewerId: data.reviewerId
      })
      let obj = {}
      if (data.memberImage) {
        obj.memberImageUrl = data.memberImage.path
      }
      if (data.memberCV) {
        obj.memberCVUrl = data.memberCV.path
      }
      if (data.description) {
        this.setState({ description: data.description })
      }
      if (data.userDetails) {
        this.setState({ userDetails: data.userDetails })
      }
      this.setState({ ...obj })
    }
  }

  componentDidMount() {
    this.loadJournalList()
    let data = getUrlParams('member.edit', this.props.pathname)
    // let data = getUrlParams('member.reviewer', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setState({ id: id })
      this.setFormValuesFxn(id)
    }
  }


  loadJournalList = async () => {
    const { dispatch, form } = this.props
    let { data } = await JournalList()
    this.setState({ journalListArr: data })
    form.setFieldsValue({ enterDate: moment() })
  }


  chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  render() {

    const { form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading } = this.props
    const { memberImageUrl, journalListArr, memberCVUrl } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputSchema = {
      fields: [
        {
          label: 'Date of Enter',
          key: 'enterDate',
          required: true,
          placeholder: 'Date of Enter',
          type: 'date',
          disabled: true
        },
        {
          label: 'Member Id',
          customField: (
            <InputBox title={`Member Id`} required={true}>
              <Input className={'form-control'} value={this.state.reviewerId} disabled={true}
                     onChange={(e) => {
                       this.setState({ reviewerId: e.target.value })
                     }} />
            </InputBox>
          )
        },
        {
          label: 'Password',
          customField: (
            <InputBox title={`Password`} required={true}>
              <Input className={'form-control'}
                     value={this.state.userDetails ? this.state.userDetails.displayPassword : null} disabled={true}
                     onChange={(e) => {
                       this.setState({ reviewerId: e.target.value })
                     }} />
            </InputBox>
          )
        },
        {
          label: 'Member Type',
          key: 'memberType',
          required: true,
          placeholder: 'Member Type',
          type: 'select',
          options: MemberTypes,
          onChange: (val) => {
            setFieldsValue({ memberType: val })
          }
        },
        {
          label: 'Journal of Interest',
          key: 'journals',
          required: true,
          showSearch: true,
          options: journalListArr,
          keyAccessor: x => x.name,
          valueAccessor: x => x.name,
          placeholder: 'Journal',
          type: 'select',
          mode: 'multiple',
          onChange: (val) => {
            setFieldsValue({ journals: val })
          }
        },
        {
          label: 'Member Position',
          key: 'position',
          placeholder: 'Member Position',
          type: 'select',
          hidden: !(getFieldValue('memberType') == 'Editorial Board'),
          required: (getFieldValue('memberType') == 'Editorial Board'),
          options: MemberPositions,
          onChange: (val) => {
            setFieldsValue({ position: val })
          }
        },
        {
          label: 'Rating',
          key: 'rating',
          required: true,
          placeholder: 'Rating',
          type: 'select',
          options: MemberRatings(),
          keyAccessor: x => x.value,
          valueAccessor: x => x.label,
          onChange: (val) => {
            setFieldsValue({ rating: val })
          }
        },
        {
          label: 'Discipline',
          key: 'category',
          required: true,
          placeholder: 'Discipline',
          options: Discipline,
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ category: val })
          }
        },
        {
          label: 'Area Of Interest',
          key: 'areaOfInterest',
          required: true,
          placeholder: 'Area Of Interest'
        },
        {
          label: 'Title',
          key: 'title',
          required: true,
          showSearch: true,
          options: AuthorTitle,
          placeholder: 'Title',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ title: val })
          }
        },
        {
          label: 'Department',
          key: 'department',
          required: true,
          placeholder: 'Department'

        },
        {
          label: 'Name',
          key: 'name',
          required: true,
          placeholder: 'Name'
        },
        {
          label: 'Institute Affiliated With',
          key: 'instituteAffiliatedWith',
          required: true,
          placeholder: 'Institute Affiliated With'
        },
        {
          label: 'Gender',
          key: 'gender',
          required: true,
          showSearch: true,
          options: Genders,
          placeholder: 'Gender',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ gender: val })
          }
        },
        {
          label: 'Address',
          key: 'address',
          required: true,
          placeholder: 'Address'
        },
        {
          label: 'Designation',
          key: 'designation',
          required: true,
          placeholder: 'Designation',
          options: DesignationList,
          type: 'select',
          showSearch: true,
          onChange: (val) => {
            setFieldsValue({ designation: val })
          }
        },
        {
          label: 'Other Designation',
          key: 'otherDesignation',
          required: getFieldValue('designation') == 'Others',
          hidden: getFieldValue('designation') !== 'Others',
          placeholder: 'Other Designation'
        },
        {
          label: 'Country',
          key: 'country',
          required: true,
          showSearch: true,
          options: Countries,
          keyAccessor: x => x.name,
          valueAccessor: x => x.name,
          placeholder: 'Country',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ country: val })
          }
        },

        {
          label: 'Primary Email',
          key: 'email',
          required: true,
          placeholder: 'Primary Email',
          type: 'email'
        },
        {
          label: 'Institute Website',
          key: 'institutionWebsite',
          placeholder: 'Institute Website'
        },
        {
          label: 'Secondary Email',
          key: 'secondaryEmail',
          placeholder: 'Secondary Email'
        },
        {
          label: 'Contact number',
          key: 'contactNo',
          placeholder: 'Contact number'
        },
        {
          label: 'Profile Image',
          customField: (
            <InputBox title={`Profile Image`} className={'rowFlex'}>
              <Input type={'file'} name={'memberImage'} id={'memberImage'}
                     className={'form-control'} key={this.state.fileKey}
                     onChange={(e) => {
                       this.chooseDocument(e)
                     }} />
              {memberImageUrl ?
                <a className={'linkBtn'} target={'_blank'} href={memberImageUrl}>
                  <Icon type={'eye'}></Icon>
                </a> : null}
            </InputBox>
          )
        },

        {
          label: 'CV Attachment',
          customField: (
            <InputBox title={`CV Attachment`} className={'rowFlex'}>
              <Input type={'file'} name={'memberCV'} id={'memberCV'}
                     className={'form-control'} key={this.state.fileKey}
                     onChange={(e) => {
                       this.chooseDocument(e)
                     }} />
              {memberCVUrl ?
                <a className={'linkBtn'} target={'_blank'} href={memberCVUrl}>
                  <Icon type={'eye'}></Icon>
                </a> : null}
            </InputBox>
          )
        },
        {
          label: 'Other Details (if any)',
          key: 'otherDetails',
          placeholder: 'Other Details (if any)',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        // {
        //   label: 'Complete descriptionss',
        //   key: 'description',
        //   placeholder: 'Complete description',
        //   type: 'ckeditor',
        //   editorKey: this.state.ckeditorKey,
        //   rows: 2,
        //   span: 24
        // }
        {
          label: 'Profile description',
          key: 'description',
          // type: 'ckeditor',
          // editorKey: this.state.ckeditorKey,
          rows: 2,
          span: 24,
          customField: (
            <>
              <CustomCkEditor title={'Profile description'}
                              extraBox={(
                                <MentionInfo><a><Icon type={'info-circle'} /></a></MentionInfo>
                              )}
                //feeds={mentionsFeeds}
                              html={this.state.description}
                              onChange={(data) => {
                                this.setState({ description: data })
                              }}
              /></>)
        }
      ]

    }


    return (
      <PageHeaderWrapper
        title={'Edit Member'}>

        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark className={'inputForm'} noValidate={true}>
            <Row gutter={16} className={'wrapRow'}>
              {inputSchema.fields.map((item, key) => {
                return (
                  !item.hidden && <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                                       key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />}
                  </Col>
                )
              })}
            </Row>

            <Form.Item style={{ marginTop: 32 }}>
              <Button type='primary' htmlType='submit'>
                Update
              </Button>
            </Form.Item>

          </Form>


        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditArticle)
