import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../request'
import { showPageLoad, hidePageLoad } from '../../modules/actions'
import { subscriptionUrl, journalsAjaxListUrl, allSubscriptionUrl } from './apis'


export const addSubscription = (valData) => async (dispatch) => {
  console.log('valData', valData)
  dispatch(showPageLoad())
  let { data } = await axios.post(subscriptionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateSubscription = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(subscriptionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const journalsAjaxList = () => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    ...getToken()
  }
  let { data } = await axios.get(journalsAjaxListUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const allSubscription = (obj) => async (dispatch) => {
  // dispatch(showPageLoad())
  let config = {
    params: obj,
    ...getToken()
  }
  console.log('obj all', obj)
  let { data } = await axios.get(allSubscriptionUrl(), config)
  // dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
