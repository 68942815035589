import React, { useState } from 'react'
import { Icon, Card, Row, Col, Collapse } from 'antd'
import { DisplayDate } from '../../../components/_utils/appUtils'
import whiteImage from '../../../assets/icons/white-circle.png'
import greenImage from '../../../assets/icons/green-circle.png'
import defaultImage from '../../../assets/icons/default-circle.png'

const { Panel } = Collapse

const ArticleStatusList = [
  { status: 'Submitted Article', label: 'Submitted Manuscript', key: 'submittedDate' },
  { status: 'Under Process', label: 'Under Process', key: 'paymentDate' },
  { status: 'Under Reviewer', label: 'Under Review', key: 'selectedReviewer', key1: 'sendDate' },
  { status: 'Reviewed Comments', label: 'Reviewed Manuscript', key: 'underReviewDetails', key1: 'receiveDate' },
  { status: 'Comments To Author', label: 'Comments To Author', key: 'sendToAuthorDate' },
  { status: 'Revised Article', label: 'Revised Manuscript', key: 'revisedArticleDate' },
  { status: 'Approved Article', label: 'Approved', key: 'approvedArticleDate' },
  { status: 'Accepted Article', label: 'Accepted', key: 'acceptedArticlePaymentDate' },
  { status: 'Composing Article', label: 'Under Composing', key: 'composingArticleDate' },
  { status: 'Galley Proof', label: 'Galley Proof / Reprints', key: 'sendgalleyProofDate' },
  { status: 'DOI', label: 'DOI Generation', key: 'doiProcessDate' },
  { status: 'Online Publish', label: 'Online Published', key: 'onlinePublishedDate' },
  // { status: 'For Print Publication', label: 'For Print Publication', key: 'forPrintPublicationDate' },
  { status: 'Published Article', label: 'Published Article', key: 'sendForPublicationDate' },
  { status: 'Deleted', label: 'Deleted', key: 'deletedDate' },
  { status: 'Withdrawled', label: 'Withdrawal', key: 'withdrawnDate' }
]


const ArticleStatus = (props) => {
  let { article } = props
  let { articleStatus } = article

  let getDateFxn = (item) => {
    if (item.key1) {
      if (item.key == 'selectedReviewer') {
        let data1 = article[item.key]
        if (data1 && data1.length) {
          return data1[0][item.key1] ? data1[0][item.key1] : null
        } else {
          return null
        }
      } else {
        let data1 = article[item.key]
        if (data1) {
          return data1[item.key1] ? data1[item.key1] : null
        }
      }
    } else {
      return article && article[item.key] ? article[item.key] : null
    }
  }

  let imageBox = (imgLink) => {
    return (
      <img src={imgLink} />
    )
  }

  return (
    <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
      <Panel header='Article Status' key='1'>
        <Row gutter={8} className={'statusRow'}>
          {ArticleStatusList.map((item, key) => {
            let dateField = getDateFxn(item)
            return (
              <Col span={3} key={key} className={'statusColumn'}>
                <Card className={`box ${item.status == articleStatus ? 'active' : ''}`}>
                  <div>
                    <div className={'status'}>
                      {item.label}
                    </div>
                    <div className={'iconDiv'}>
                      {item.status == articleStatus ? imageBox(whiteImage) : DisplayDate(dateField) ? imageBox(greenImage) : imageBox(defaultImage)}
                    </div>
                    <div className={'date'}>
                      {DisplayDate(dateField)}
                    </div>
                  </div>
                </Card>
              </Col>
            )
          })}

        </Row>
      </Panel>

    </Collapse>

  )
}

export default ArticleStatus
