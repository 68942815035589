import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import { apiUrl } from '../../../settings'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'


class AllNewsLetter extends Component {

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let data = await Request.getAllNewsLetter(params)
      resolve(data)
    })
  }

  componentDidMount() {

  }

  reload = () => {
    this.table.current.reload()
  }
  deleteNewsLetter = async (data) => {
    let resp = await Request.deleteNewsLetter(data)
    this.reload()

  }


  render() {

    const { dispatch } = this.props

    const columns = [
      {
        title: 'Full Name',
        key: 'fullName',
        sorter: true,
        dataIndex: 'fullName',
        searchTextName: 'fullName'
      },
      {
        title: 'Email',
        key: 'email'
      },
      {
        key: 'date',
        render: (val) => {
          return new Date(val).toLocaleDateString()
        }
      },
      {
        key: 'actions', render: (val) => (<React.Fragment>

          <Tooltip title='Edit Details'>
            <Button className={styles.btn}
                    shape='circle'
                    onClick={() => {
                      dispatch(getPushPathWrapper('newsLetter.edit', { id: val._id }))
                    }} icon='edit' />
          </Tooltip>

          <Tooltip title='Delete News Letter'>
            <Button className={styles.btn}
                    loading={val.deleteLoading}
                    onClick={() => {
                      this.deleteNewsLetter({ _id: val._id })
                    }}
                    type='danger' shape='circle' icon='delete' />
          </Tooltip>
        </React.Fragment>)

      }
    ]
    return (
      <PageHeaderWrapper
        title={'All News Letters'}>

        <Card bordered={true}>
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest} />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllNewsLetter)
