import Dashboard from './containers/dashboard'
import AllUsers from './containers/users/all'
import AddUsers from './containers/users/add'
import EditUsers from './containers/users/edit'


import AddJournal from './containers/journals/add'
import AllJournals from './containers/journals/all'
import AllOnlineSubmission from './containers/onlineSubmission/views/all'
import Email from './containers/email'
import AllEmailTemplate from './containers/email/views/all'
import EditEmailTemplate from './containers/email/views/edit'
import BecomeReviewer from './containers/reviewer'
import AddNews from './containers/news/add'
import AllNews from './containers/news/all'

import AddNewsLetter from './containers/newsLetter/add'
import AllNewsLetter from './containers/newsLetter/all'

import AddSubscription from './containers/subscription/add'
import EditSubscription from './containers/subscription/edit'
import AllSubscription from './containers/subscription/all'
import AllSubscriber from './containers/subscriber/all'

import AddArticle from './containers/article/views/add'
import EditArticle from './containers/article/views/edit'
import AllPayments from './containers/payments/all'
import AddMember from './containers/members/views/add'
import EditMember from './containers/members/views/edit'
import AllMember from './containers/members/views/all'
import ArticleView from './containers/article/articleView/indexClass'
import ResetPassword from './containers/login/ResetPassword'

import SubmittedArticles from './containers/article/views/submittedArticles'
import UnderProcessArticles from './containers/article/views/underProcessArticles'
import UnderReviewerArticles from './containers/article/views/underReviewerArticles'
import ReviewedArticles from './containers/article/views/reviewedArticles'
import CommentsToAuthor from './containers/article/views/CommentsToAuthor'
import RejectedArticle from './containers/article/views/RejectedArticle'
import RevisedArticle from './containers/article/views/RevisedArticle'
import ApprovedArticle from './containers/article/views/ApprovedArticle'
import AcceptedArticle from './containers/article/views/AcceptedArticles'
import ComposeArticle from './containers/article/views/ComposeArticle'
import galleryProof from './containers/article/views/GalleryProof'
import DOIArticle from './containers/article/views/DOIArticle'
import OnlinePublishArticle from './containers/article/views/OnlinePublishArticle'
import CompletePublication from './containers/article/views/CompletePublication'
import PendingArticles from './containers/ReviewerContainer/PendingArticles'
import ReviewerReviewedArticles from './containers/ReviewerContainer/ReviewedArticles'
import ChangePassword from './containers/ReviewerContainer/ChangePassword'
import EditReviewer from './containers/ReviewerContainer/EditReviewer'
import WithdrawArticles from './containers/article/views/WithdrawArticles'
import JournalPriceList from './containers/journals/all/JournalPriceList'
import OnlineSubscribers from './containers/journals/all/OnlineSubscribers'
import PrintSubscribers from './containers/journals/all/PrintSubscribers'
import FetchArticle from './containers/fetchArticle/views'
import FetchUrls from './containers/fetchArticle/views/fetchUrls'
import AddDirectArticle from './containers/fetchArticle/views/addDirectArticle'

import { push } from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'
import DeletedArticles from './containers/article/views/DeletedArticles'

const menu = [
  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'icon': 'dashboard',
    'key': 'dashboard',
    'title': 'Dashboard',
    'homepage': true,
    'component': Dashboard,
    'authority': [
      'Admin',
      'User',
      'Reviewer'
    ]
  },
  {
    'path': '/online-submission',
    'name': 'Online Submission',
    'icon': 'dashboard',
    'key': 'onlineSubmissions',
    'title': 'Online Submission',
    'homepage': true,
    'component': AllOnlineSubmission,
    'authority': [
      'Admin'
    ]
  },
  {
    'path': '/article',
    'name': 'Manage Articles',
    'icon': 'user',
    'key': 'article',
    'authority': [
      'Admin',
      'User'
    ],
    'children': [
      {
        'path': '/article/add',
        'name': 'Add Article',
        'title': 'Add Article',
        'component': AddArticle,
        'key': 'add',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/edit/:id',
        'name': 'Edit Article',
        'title': 'Edit Article',
        'component': EditArticle,
        'key': 'edit',
        'dontShowOnMenu': true,
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/onlineSubmission/:id',
        'name': 'Add Article',
        'title': 'Add Article',
        'component': AddArticle,
        'key': 'onlineSubmission',
        'dontShowOnMenu': true,
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/submittedArticles',
        'name': 'Submitted Manuscript',
        'title': 'Submitted Manuscript',
        'key': 'submittedArticles',
        'component': SubmittedArticles,
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/underProcess',
        'name': 'Under Process Article',
        'title': 'Under Process Article',
        'component': UnderProcessArticles,
        'key': 'underProcess',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/UnderReviewer',
        'name': 'Under Review',
        'title': 'Under Review',
        'component': UnderReviewerArticles,
        'key': 'underReviewer',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/reviewedArticles',
        'name': 'Reviewed Manuscript',
        'title': 'Reviewed Manuscript',
        'component': ReviewedArticles,
        'key': 'reviewedArticles',
        'authority': [
          'Admin',
          'User'
        ]
      },

      {
        'path': '/article/CommentsToAuthor',
        'name': 'Comments to Author',
        'title': 'Comments to Author',
        'component': CommentsToAuthor,
        'key': 'commentsToAuthor',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/rejectedArticle',
        'name': 'Rejected Article',
        'title': 'Rejected Article',
        'component': RejectedArticle,
        'key': 'rejection',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/revisedArticle',
        'name': 'Revised Article',
        'title': 'Revised Article',
        'component': RevisedArticle,
        'key': 'revisedArticle',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/approvedArticle',
        'name': 'Approved',
        'title': 'Approved',
        'component': ApprovedArticle,
        'key': 'approvedArticle',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/acceptedArticle',
        'name': 'Accepted',
        'title': 'Accepted',
        'component': AcceptedArticle,
        'key': 'acceptedArticles',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/composeArticle',
        'name': 'Under Composing',
        'title': 'Under Composing',
        'component': ComposeArticle,
        'key': 'composingArticle',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/galleryProof',
        'name': 'Galley Proof / Reprints',
        'title': 'Galley Proof / Reprints',
        'component': galleryProof,
        'key': 'galleyProof',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/DOIArticle',
        'name': 'DOI Generation',
        'title': 'DOI Generation',
        'component': DOIArticle,
        'key': 'DOIArticle',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/onlinePublishArticle',
        'name': 'Online Published',
        'title': 'Online Published',
        'component': OnlinePublishArticle,
        'key': 'OnlinePublishArticle',
        'authority': [
          'Admin',
          'User'
        ]

      },
      {
        'path': '/article/completePublication',
        'name': 'Complete Publication',
        'title': 'Complete Publication',
        'component': CompletePublication,
        'key': 'CompletePublication',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/withdrawArticles',
        'name': 'Withdrawal',
        'title': 'Withdrawal',
        'component': WithdrawArticles,
        'key': 'WithdrawArticles',
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/article/deletedArticles',
        'name': 'Deleted',
        'title': 'Deleted',
        'component': DeletedArticles,
        'key': 'DeletedArticles',
        'authority': [
          'Admin',
          'User'
        ]
      }
    ]

  },
  {
    'path': '/manage-journal',
    'name': 'Manage Journals',
    'icon': 'dashboard',
    'key': 'dashboard',
    'title': 'Manage Journals',
    // 'homepage': true,
    // 'component': AllJournals,
    'authority': [
      'Admin',
      'User'
    ],
    children: [
      {
        'path': '/manage-journal/all',
        'name': 'All Journals',
        'icon': 'dashboard',
        'key': 'manageAllJournals',
        'title': 'All Journals',
        'component': AllJournals,
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/manage-journal/printSubscribers',
        'name': 'Print Subscribers',
        'icon': 'dashboard',
        'key': 'PrintSubscribers',
        'title': 'Print Subscribers',
        'component': PrintSubscribers,
        'authority': [
          'Admin'
        ]
      },
      {
        'path': '/manage-journal/journalPriceList',
        'name': 'Journal Price List',
        'icon': 'dashboard',
        'key': 'JournalPriceList',
        'title': 'JournalPriceList',
        'component': JournalPriceList,
        'authority': [
          'Admin'
        ]
      },
      {
        'path': '/manage-journal/OnlineSubscribers',
        'name': 'Online Subscribers',
        'icon': 'dashboard',
        'key': 'OnlineSubscribers',
        'title': 'Online Subscribers',
        'component': OnlineSubscribers,
        'authority': [
          'Admin'
        ]
      }
    ]
  },
  {
    'path': '/journals',
    'name': 'Journals',
    'icon': 'user',
    'key': 'journals',
    dontShowOnMenu: true,
    'authority': [
      'Admin',
      'User'
    ],
    'children': [
      {
        'path': '/journals/add',
        'name': 'Add Journal',
        'title': 'Add Journal',
        'component': AddJournal,
        key: 'add',
        dontShowOnMenu: true,
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/journals/edit/:id',
        'name': 'Edit Journal',
        'title': 'Edit Journal',
        'component': AddJournal,
        key: 'edit',
        dontShowOnMenu: true,
        'authority': [
          'Admin',
          'User'
        ]
      },
      {
        'path': '/journals/all',
        'name': 'Manage journals',
        'title': 'Manage journals',
        'component': AllJournals,
        'authority': [
          'Admin',
          'User'
        ]
      }
    ]
  },
  {
    'path': '/articleView/:id',
    'name': 'Article View',
    'title': 'Article View',
    'key': 'articleView',
    'dontShowOnMenu': true,
    'component': ArticleView,
    'authority': [
      'Admin',
      'User'
    ]
  },

  {
    'path': '/manage-template',
    'name': 'Manage Templates',
    'icon': 'mail',
    'key': 'manageTemplate',
    'title': 'Manage Templates',
    'component': AllEmailTemplate,
    'authority': [
      'Admin'
    ]
  },
  {
    'path': '/template',
    'name': 'Email Template',
    'title': 'Email Template',
    'icon': 'mail',
    'dontShowOnMenu': true,
    'key': 'template',
    'authority': [
      'Admin'
    ],
    'children': [
      {
        'path': '/template/add',
        'component': Email,
        'name': 'Add Email Templates',
        'title': 'Add Email Template',
        'key': 'add',
        'authority': [
          'Admin'
        ]
      },
      {
        'path': '/template/edit/:id',
        'component': EditEmailTemplate,
        'name': 'Edit Email Template',
        'title': 'Edit Email Template',
        'key': 'edit',
        'dontShowOnMenu': true,
        'authority': [
          'Admin'
        ]
      }
    ]
  },
  {
    'path': '/manage-member',
    'name': 'Manage Members',
    'icon': 'user',
    //'key': 'manageMember',
    'title': 'Manage Members',
    //'component': AllMember,
    'authority': [
      'Admin'
    ],
    'children': [
      {
        'path': '/manage-member/all',
        'name': 'All Member',
        'icon': 'user',
        'key': 'AllMember',
        'title': 'All Member',
        'component': AllMember,
        'authority': [
          'Admin'
        ]
      },
      {
        'path': '/manage-member/becomeReviewer',
        'name': 'Online Reviewer Request',
        'icon': 'dashboard',
        'key': 'becomeReviewer',
        // 'homepage': true,
        'component': BecomeReviewer,
        'authority': [
          'Admin'
        ]
      }
    ]
  },

  {
    'path': '/member',
    'name': 'Member',
    'icon': 'user',
    'key': 'member',
    'dontShowOnMenu': true,
    'authority': [
      'Admin'
    ],
    'children': [
      {
        'path': '/member/add',
        'name': 'Add member',
        'title': 'Add member',
        'component': AddMember,
        'key': 'addMember',
        'authority': [
          'Admin'
        ]
      },
      {
        'path': '/member/edit/:id',
        'name': 'Edit Member',
        'title': 'Edit Member',
        'component': EditMember,
        'key': 'edit',
        'dontShowOnMenu': true,
        'authority': [
          'Admin'
        ]
      },
      {
        'path': '/member/reviewer/:id',
        'name': 'Add member',
        'title': 'Add member',
        'component': AddMember,
        'key': 'reviewer',
        'dontShowOnMenu': true,
        'authority': [
          'Admin'
        ]
      }
    ]
  },

  /* {
     'path': '/reviewer',
     'name': 'Reviewer',
     'icon': 'user',
     'key': 'reviewer',
     'title': 'Reviewer',
     'homepage': true,
     'component': ReviewerDashboard,
     'authority': [
       'Reviewer',
     ]
   },*/
  {
    'path': '/reviewerPendingArticles',
    'name': 'Pending Article',
    'title': 'Pending Article',
    'icon': 'file-sync',
    'component': PendingArticles,
    'key': 'pendingReviewerArticle',
    'authority': [
      'Reviewer'
    ]
  },
  {
    'path': '/reviewerReviewedArticles',
    'name': 'Reviewed Article',
    'title': 'Reviewed Article',
    'icon': 'check-circle',
    'component': ReviewerReviewedArticles,
    'key': 'reviewedReviewerArticle',
    'authority': [
      'Reviewer'
    ]
  },
  {
    'path': '/reviewerChangePassword',
    'name': 'Change Password',
    'title': 'Change Password',
    'icon': 'lock',
    'component': ChangePassword,
    'key': 'changePassword',
    'authority': [
      'Reviewer'
    ]
  },
  {
    'path': '/editReviewer',
    'name': 'Edit Reviewer',
    'title': 'Edit Reviewer',
    'component': EditReviewer,
    'key': 'editReviewer',
    'dontShowOnMenu': true,
    'authority': [
      'Reviewer'
    ]
  },

  {
    'path': '/users',
    'name': 'Users',
    'icon': 'user',
    'key': 'users',
    // 'component': Undercons,
    'authority': [
      'Admin',
      'User'
    ],
    'children': [
      {
        'path': '/users/add',
        'name': 'Add User',
        'title': 'Add User',
        'component': AddUsers
      },
      {
        'path': '/user/edit-user/:id',
        'name': 'Edit User',
        'title': 'Edit User',
        'dontShowOnMenu': true,
        'key': 'editUser',
        'component': EditUsers
      },
      {
        'path': '/users/all',
        'name': 'All Users',
        'title': 'All Users',
        'component': AllUsers
      }
    ]
  },
  {
    'path': '/announcement',
    'name': 'Announcement',
    'icon': 'user',
    'key': 'news',
    // 'component': Undercons,
    'authority': [
      'Admin',
      'User'
    ],
    'children': [
      {
        'path': '/announcement/add',
        'name': 'Add Announcement',
        'title': 'Add Announcement',
        'component': AddNews
      },
      {
        'path': '/announcement/edit/:id',
        'name': 'Edit Announcement',
        'title': 'Edit Announcement',
        'component': AddNews,
        'key': 'edit',
        'dontShowOnMenu': true
      },
      {
        'path': '/announcement/all',
        'name': 'All Announcement',
        'title': 'All Announcement',
        'component': AllNews
      }
    ]
  },
  {
    'path': '/newsLetter',
    'name': 'News Letter',
    'icon': 'user',
    'key': 'newsLetter',
    // 'component': Undercons,
    'authority': [
      'Admin',
      'User'
    ],
    'children': [
      {
        'path': '/newsLetter/edit/:id',
        'name': 'Edit News Letter',
        'title': 'Edit News Letter',
        'component': AddNewsLetter,
        'key': 'edit',
        'dontShowOnMenu': true
      },
      {
        'path': '/newsLetter/all',
        'name': 'All News Letter',
        'title': 'All News Letter',
        'component': AllNewsLetter
      }
    ]
  },
  {
    'path': '/subscription',
    'name': 'Subscription',
    'icon': 'user',
    'key': 'subscription',
    // 'component': Undercons,
    'authority': [
      'Admin',
      'User'
    ],
    'children': [
      {
        'path': '/subscription/add',
        'name': 'Add Subscription',
        'title': 'Add Subscription',
        'component': AddSubscription
      },
      {
        'path': '/subscription/edit/:id',
        'name': 'Edit Subscription',
        'title': 'Edit Subscription',
        'component': EditSubscription,
        'key': 'edit',
        'dontShowOnMenu': true
      },
      {
        'path': '/subscription/all',
        'name': 'All Subscription',
        'title': 'All Subscription',
        'component': AllSubscription
      }
    ]
  },
  {
    'path': '/subscriber',
    'name': 'Subscriber',
    'icon': 'user',
    'key': 'subscriber',
    // 'component': Undercons,
    'authority': [
      'Admin',
      'User'
    ],
    'children': [
      {
        'path': '/subscriber/all',
        'name': 'All Subscribers',
        'title': 'All Subscribers',
        'component': AllSubscriber
      }
    ]
  },
  {
    'path': '/payments',
    'name': 'Payments',
    'icon': 'user',
    'key': 'payments',
    // 'component': Undercons,
    'authority': [
      'Admin',
      'User'
    ],
    'children': [
      {
        'path': '/payments/all',
        'name': 'All Payments',
        'title': 'All Payments',
        'component': AllPayments
      }
    ]
  },

  {
    'path': '/fetch-article',
    'name': 'Fetch Article',
    'icon': 'mail',
    'key': 'fetchArticle',
    'title': 'Fetch Article',
    'dontShowOnMenu': true,
    'component': FetchArticle,
    'authority': [
      'Admin'
    ]
  },
  {
    'path': '/fetch-all-article',
    'name': 'Fetch All Article',
    'icon': 'mail',
    'key': 'fetchAllArticle',
    'title': 'Fetch All Article',
    'dontShowOnMenu': true,
    'component': FetchUrls,
    'authority': [
      'Admin'
    ]
  },
/*  {
    'path': '/add-direct-article',
    'name': 'Add DOI Article',
    'icon': 'import',
    'key': 'addDirectArticle',
    'title': 'Add DOI Article',
    'dontShowOnMenu': true,
    'component': AddDirectArticle,
    'authority': [
      'Admin'
    ]
  },*/
  {
    'path': '/add-doi-article',
    'name': 'Add DOI Article',
    'icon': 'import',
    'key': 'addDirectArticle',
    'title': 'Add DOI Article',
    // 'dontShowOnMenu': true,
    'component': AddDirectArticle,
    'authority': [
      'Admin'
    ]
  }
  /*{
    'path': '/websites',
    'icon': 'chrome',
    'name': 'Websites',
    'key': 'websites',
    'children': [
      {
        'path': '/websites/add-website',
        'name': 'Add Website',
        'title': 'Add Website',
        'key': 'add',
        'component': AddWebsite
      },
      {
        'path': '/websites/all-website',
        'name': 'All Website',
        'title': 'All Website',
        'component': AllWebsite
      },
      {
        'path': '/websites/edit-website',
        'name': 'Edit Website',
        'key': 'edit',
        'title': 'Edit Website',
        'component': AddWebsite,
        'dontShowOnMenu': true
      },
      {
        'path': '/websites/screen-website',
        'name': 'WebsiteScreenShot',
        'key': 'screenshots',
        'title': 'Website ScreenShots',
        'component': WebsiteScreenShot,
        'dontShowOnMenu': true
      }
    ]

  }*/
]

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}

export default menu
