import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Dropdown, Menu, Form, Drawer, Row, Col
} from 'antd'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import { apiUrl } from '../../../settings'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { push } from 'connected-react-router'
import { hideLoader, showLoader } from '../../../modules/actions'
import { FormUtils } from 'sz-react-utils-lite'
import ManageJournalForm from './ManageVolume'
import ManageScheduleForm from './ManageSchedule'
import AuthorGuidelineDrawer from '../../authorGuideLine/AuthorGuidelineDrawer'
import WebPagesDrawer from './webPagesDrawer'

@Form.create()
class AllJournals extends Component {

  events = {
    showDrawer: (data) => {
      this.setState({
        visibleSchedule: true,
        selectedJournal: data
      })
    },
    hideDrawer: () => {
      this.setState({
        visibleSchedule: false,
        selectedJournal: {}
      })
      this.table.current.reload()
    },
    showWebPageDrawer: (page) => {
      this.setState({
        visibleWebPageDrawer: true,
        page
      })
    },
    hideWebPageDrawer: (page) => {
      this.setState({
        visibleWebPageDrawer: false,
        page
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      visibleSchedule: false,
      selectedJournal: {},
      visibleWebPageDrawer: false,
      page: ''

    }
    this.table = React.createRef()
  }

  showDrawer = () => {
    this.setState({ visible: true })
  }

  onClose = () => {
    this.setState({ visible: false })
    this.table.current.reload()
  }

  componentDidMount() {

  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let data = await Request.getAllJournals(params)
      resolve(data)
    })
  }


  render() {

    const { dispatch } = this.props
    const { visibleSchedule, selectedJournal } = this.state
    const webButtons = [
      { page: 'Publication Ethics', icon: 'file-text' },
      { page: 'Peer Review Process Article', icon: 'file-text' },
      { page: 'Role of Editor', icon: 'file-text' },
      { page: 'Role of reviewers', icon: 'file-text' },
      { page: 'Role Of Author', icon: 'file-text' },
      { page: 'For Readers', icon: 'file-text' },
      { page: 'Terms & conditions', icon: 'file-text' }
    ]
    const columns = [
      {
        title: ' Serial No. ',
        dataIndex: 'orderNo',
        //align: 'center',
        key: 'index',
        render: (text, record, index) => index + 1
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name',
        render: (val, record) => (<div>
          <Tooltip title='Edit Details'>
            {val}
            <a className={'linkIcon'} onClick={() => {
              dispatch(getPushPathWrapper('journals.edit', { id: record._id }))
            }}>
              {' '} <Icon type={'edit'} />
            </a>
          </Tooltip></div>)
      },
      {
        key: 'printIssn',
        render: (val, record) => {
          return (<div>{val} <ManageJournalForm val={record} onClose={() => this.onClose()} /></div>)
        }
      },
      { key: 'onlineIssn' },
      {
        key: 'chiefEditor',
        render: (val) => {
          return (
            <div>{val ? val.name : null}</div>
          )
        }
      },
      {
        key: 'journalFile',
        render: (val, record) => {
          return (
            <div>
              {val && val.path ? <img className={styles.img} src={val.path} /> : null}
            </div>)
        }
      },
      {
        key: 'action',
        render: (val, record) => {
          return (
            <div>
              <Button className={'roundSmallBtn'} onClick={() => this.events.showDrawer(record)}>
                Manage Schedule
              </Button>

            </div>)
        }
      }
      // {
      //   key: 'actions',
      //   render: (val, record) => (<div key={val.name}>
      //     <Tooltip title='Edit Details'>
      //       <Button className={styles.btn}
      //               shape='circle'
      //               onClick={() => {
      //                 dispatch(getPushPathWrapper('journals.edit', { id: val._id }))
      //               }} icon='edit'/>
      //     </Tooltip>

      /* <Tooltip title="Delete Domain">
        <Button className={styles.btn}
                loading={val.deleteLoading}
                onClick={() => {
                  this.deleteWebsite({ urlSlug: val.urlSlug })
                }}
                type="danger" shape="circle" icon="delete"/>
      </Tooltip> */
      // </div>)

      // }
    ]
    return (
      <PageHeaderWrapper
        title={'Manage Journals'}
        content={[
          <>
            <Row gutter={16}>
              <Col span={24} className={'btnGroup'}>
                {
                  webButtons.map((item, key) => {
                    return (
                      <Button type='primary' key={key} onClick={() => {
                        this.events.showWebPageDrawer(item.page)
                      }}>
                        <Icon type={item.icon} />
                        {item.page}
                      </Button>
                    )
                  })
                }

                <Button key='1' type='primary' onClick={() => {
                  this.showDrawer()
                }}>
                  <Icon type={'file-text'} />
                  Author GuideLines
                </Button>
                <Button key='1' type='primary' onClick={() => {
                  dispatch(getPushPathWrapper('journals.add'))
                }}>
                  <Icon type={'plus'} />
                  Add Journal
                </Button>
              </Col>
            </Row>
          </>
        ]}
      >
        <Card bordered={true}>
          <TableComp columns={columns}
                     ref={this.table}
                     apiRequest={this.apiRequest}
                     pagination={false} />
        </Card>
        {this.state.visible ? <AuthorGuidelineDrawer
          visible={this.state.visible}
          onClose={() => this.onClose()}
        /> : null}

        {this.state.visibleWebPageDrawer ? <WebPagesDrawer
          visible={this.state.visibleWebPageDrawer}
          page={this.state.page}
          onClose={() => this.events.hideWebPageDrawer()}
        /> : null}

        {visibleSchedule ? <ManageScheduleForm
          visible={visibleSchedule}
          val={selectedJournal}
          onClose={() => this.events.hideDrawer()} /> : null}
      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllJournals)
