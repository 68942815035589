import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Form, Row, Col
} from 'antd'
import { getPushPathWrapper } from '../../../routes'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { TableComp, FormUtils } from 'sz-react-utils-lite'
import { getAllEmailTemplate } from '../actions'
import moment from 'moment'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'

const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class AllEmailTemplate extends Component {
  state = {}

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    const { dispatch } = this.props
    return new Promise(async (resolve) => {
      let data = await dispatch(getAllEmailTemplate({ ...params, regExFilters: ['title', 'subject'] }))
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }

  deleteTemplate = async (data) => {
    this.reload()
  }


  componentDidMount() {
  }

  render() {

    const { dispatch, form: { getFieldDecorator, getFieldValue }, loading } = this.props
    const { journalList, volumeList, issueList } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }

    const columns = [
      {
        key: 'title',
        dataIndex: 'title',
        filterRegex: true,
        searchTextName: 'title'
      },
      {
        key: 'subject',
        dataIndex: 'subject',
        filterRegex: true,
        searchTextName: 'subject'
      },
      {
        label: 'Attachments',
        key: 'attachments',
        render: (item) => {
          return (
            item.map((x, key) => {
              return (
                <>
                  <a href={x.path} key={key}>{x.fileName}</a>
                  <br />
                </>
              )
            })
          )
        }
      },
      {
        key: 'actions',
        title: 'Edit',
        render: (val, record) => (<React.Fragment>
          {/* <a className={styles.btn} href={`/template/edit/${record._id}`}>
            <Icon type={'edit'}/>
          </a>*/}
          <Button className={styles.btn}
                  shape='circle'
                  onClick={() => {
                    dispatch(getPushPathWrapper('template.edit', { id: record._id }))
                  }} icon='edit' />


        </React.Fragment>)

      }
    ]

    const fil = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    return (
      <PageHeaderWrapper
        title={'All Email Template'}
        content={[
          <Button key='1' type='primary' onClick={() => {
            dispatch(getPushPathWrapper('template.add'))
          }}>
            <Icon type={'plus'} />
            Add Template
          </Button>
        ]}
      >
        <Card bordered={true}>
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest} size={'small'}
                     pagination={DefaultTablePagination({}, 50)} />
        </Card>
      </PageHeaderWrapper>)
  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllEmailTemplate)
