import { Button, Card, Drawer, Form, notification, Row, Col } from 'antd'
import React, { Component } from 'react'
import Request from '../../../request'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import IssueJournalForm from './ManageIssue'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { DefaultTablePagination, formItemLayout } from '../../../components/_utils/appUtils'

@Form.create()
class ManageJournalForm extends Component {
  volumeTable = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      visibleIssueDrawer: false,
      volNumber: '',
      volName: '',
      total: 0,
      selectedVolume: {}
    }
  }

  showDrawer = () => {
    this.setState({ visible: true })
  }
  onClose = () => {
    this.setState({ visible: false })
  }


  getJournalsVolumeData = (params) => {
    return new Promise(async (resolve) => {
      let { val } = this.props
      let data = await Request.getJournalsVolumeList({ journalId: val._id, ...params })
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  loadVolume = () => {
    this.volumeTable.current.reload()
  }

  handleSubmitVolume = (e) => {
    const { dispatch, form, val } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        // dispatch(showLoader())
        let x = {}
        x = await Request.addJournalVolume({ ...valData, journalId: val._id })
        let { error, message } = x
        console.log(error, message)
        // dispatch(hideLoader())

        if (!error) {
          notification.success({
            message: message
          })
          this.loadVolume()
          form.resetFields()


        } else {
          notification.error({
            message: message,
            description: message
          })
        }
      }
    })
  }

  showManageIssue = (data) => {
    this.setState({
      selectedVolume: data,
      visibleIssueDrawer: true
    })
  }
  hideManageIssue = () => {
    this.setState({
      selectedVolume: {},
      visibleIssueDrawer: false
    })
  }

  render() {
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading, val } = this.props
    const columnsData = [
      {
        title: 'Serial No.',
        dataIndex: 'volumeNumber',
        key: 'volumeNumber'
        // render: text => <a>{text}</a>
      },
      {
        title: 'Name',
        dataIndex: 'volumeName',
        key: 'volumeName'
      },
      {
        title: 'Action',
        key: 'action',
        render: (item, record) => {
          return (<div>{item}
            <Button onClick={() => this.showManageIssue({ ...record, volumeId: record._id, journalName: val.name })}>
              Manage Issues
            </Button>
          </div>)
        }
      }
    ]


    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }
    const inputSchema = {
      fields: [
        {
          label: 'Volume name', key: 'volumeName', required: true, placeholder: 'Volume Name', onChange: (e) => {
            setFieldsValue({ volumeName: e.target.value })
          }
        },
        { label: 'Volume Number', key: 'volumeNumber', required: true, placeholder: 'Volume Number', type: 'number' }
      ]
    }

    let { selectedVolume, visibleIssueDrawer } = this.state
    return (
      <div key={val.name}>
        <Button onClick={this.showDrawer}>
          Manage Volume & Issues
        </Button>

        <Drawer
          title={`Manage Volumes - ${val.name}`}
          width={1000}
          placement='right'
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}>
          <div style={{ padding: 10 }}>
            <Card bordered={true}>
              <Form onSubmit={this.handleSubmitVolume} hideRequiredMark className={'inputForm'}>

                <Row gutter={16}>
                  {inputSchema.fields.map((item, key) => {
                    return (
                      <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout} />
                      </Col>
                    )
                  })}
                  <Col span={8}>
                    <Form.Item style={{ marginTop: 38 }}>
                      <Button type='primary' htmlType='submit' loading={this.props.loading}>
                        ADD
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                {/*<FormUtils inputSchema={inputSchema} {...formProps} />*/}


              </Form>
              <h7>Total Volumes: {this.state.total}</h7>
              <TableComp columns={columnsData} ref={this.volumeTable}
                         apiRequest={this.getJournalsVolumeData}
                         pagination={DefaultTablePagination()} />

            </Card>
          </div>
          {visibleIssueDrawer ? <IssueJournalForm visible={visibleIssueDrawer} volumeData={selectedVolume}
                                                  onClose={this.hideManageIssue} /> : null}
        </Drawer>


      </div>
    )
  }
}

export default ManageJournalForm
