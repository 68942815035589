import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, Popconfirm, Table, Tooltip } from 'antd'
import { DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import ReceiveDateDrawer from '../reviewer/editSelectReceiveDate'
import { RowTable } from './RowTable'
import ComposingDrawer from '../drawers/ComposingDrawer'

const { Panel } = Collapse
const ComposingDetails = (props) => {
  let { article } = props
  let {
    underReviewDetails,
    acceptedArticlePaymentDate,
    composingArticleDate,
    composingArticleAttachment
  } = article
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `Under Composing ${composingArticleDate ? `(${DisplayDate(composingArticleDate)})` : ''}`
  let [visibleComposingDrawer, setVisibleComposingDrawer] = useState(false)
  const table = useRef()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    show: () => {
      setVisibleComposingDrawer(true)
    },
    hide: () => {
      setVisibleComposingDrawer(false)
    }
  }

  let BtnAction = (event) => {
    events.show()
    event.stopPropagation()
  }

  let getExtraButton = () => {
    return (
      <React.Fragment>
        {acceptedArticlePaymentDate && !composingArticleDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Add</Button> : null}
        {composingArticleDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Edit</Button> : null}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key='1' extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Date'} value={
              composingArticleDate && DisplayDate(composingArticleDate)
            } />
            <RowTable title={'Revised Article'} value={
              composingArticleAttachment && composingArticleAttachment.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={composingArticleAttachment.path}>Download</a> : 'No Attachment'
            } />
            <RowTable title={'Assign To'} value={article.assignToName} />
            <RowTable title={'Journal'} value={article.composeJournalName} />
            <RowTable title={'Volumes'} value={article.composeVolumes} />
            <RowTable title={'Issues'} value={article.composeIssues} />
            <RowTable title={'Composed'} value={DisplayDate(article.composed)} />
          </div>
        </Panel>
      </Collapse>
      {visibleComposingDrawer ? <ComposingDrawer
        visible={visibleComposingDrawer}
        article={article}
        edit={composingArticleDate ? true : false}
        onClose={() => events.hide()}
      /> : null}
    </div>
  )
}

export default ComposingDetails
