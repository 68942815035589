import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, Popconfirm, Table, Tooltip } from 'antd'
import { DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import { RowTable } from './RowTable'
import ReactHtmlParser from 'react-html-parser'
import DOIDrawer from '../drawers/DOIDrawer'

const { Panel } = Collapse
const DIODetails = (props) => {
  let { article } = props
  let {
    sendgalleyProofDate,
    doiProcessDate
  } = article
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `DOI Generated ${doiProcessDate ? `(${DisplayDate(doiProcessDate)})` : ''}`
  let [visibleCommentToAuthorDrawer, setVisibleCommentToAuthorDrawer] = useState(false)
  const table = useRef()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    show: () => {
      setVisibleCommentToAuthorDrawer(true)
    },
    hide: () => {
      setVisibleCommentToAuthorDrawer(false)
    }
  }

  let BtnAction = (event) => {
    events.show()
    event.stopPropagation()
  }

  let getExtraButton = () => {
    return (
      <React.Fragment>
        {sendgalleyProofDate && !doiProcessDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Add</Button> : null}
        {doiProcessDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Edit</Button> : null}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key='1' extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Date'} value={
              doiProcessDate && DisplayDate(doiProcessDate)
            } />
            <RowTable title={'Article Title'} value={
              article.manuScriptTitle ? ReactHtmlParser(article.manuScriptTitle) : null
            } />
            <RowTable title={'Author Name'} value={
              article.doiAuthorName ? article.doiAuthorName : null
            } />
            <RowTable title={'Author Address'} value={
              article.doiAuthorAddress ? article.doiAuthorAddress : null
            } />
            <RowTable title={'Abstract'} value={
              article.doiAbstract ? <div style={{ width: '75%', marginLeft: '21%' }}>
                {ReactHtmlParser(article.doiAbstract)}
              </div> : null
            } />
            <RowTable title={'Keywords'} value={
              article.doiKeywords ? ReactHtmlParser(article.doiKeywords) : null
            } />
            <RowTable title={'Reference'} value={
              article.doiReferences ? ReactHtmlParser(article.doiReferences) : null
            } />
          </div>
        </Panel>
      </Collapse>
      {visibleCommentToAuthorDrawer ? <DOIDrawer
        visible={visibleCommentToAuthorDrawer}
        article={article}
        edit={doiProcessDate ? true : false}
        onClose={() => events.hide()}
      /> : null}
    </div>
  )
}

export default DIODetails
