import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, DatePicker
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import moment from 'moment'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'
import PackageInfo from '../packageInfo/index'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'
const defaultValue = [moment().startOf('month'), moment().endOf('month')]
const StatusFilter = [
  'Initiated',
  'Pending',
  'Cancel',
  'Fail',
  'Success'
]

class AllSubscribers extends Component {
  state = {
    showPackageInfo: false,
    packageInfo: {},
    time: {
      key: 'date',
      from: null,
      to: null
    }
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()

  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let objFilter = {
        ...params,
        regExFilters: ['name', 'country', 'status'],
        dateFilter: this.state.time,
        sortField: '_id',
        sortOrder: 'descend'
      }
      let data = await Request.getAllSubscriber(objFilter)
      resolve(data)
    })
  }

  openPackageInfo = (data) => {
    this.setState({ showPackageInfo: true, packageInfo: data })
  }

  closePackageInfo = () => {
    this.setState({ showPackageInfo: false })
  }

  packageInfoSubmit = (reminObj) => {
    this.closePackageInfo()
  }

  chooseRangerPicker = (date, dateString) => {
    this.setState({
      time: {
        key: 'date',
        from: date[0],
        to: date[1]
      }
    })
    if (date[0] == undefined) {
      setTimeout(() => {
        this.reload()
      }, 500)
    }
  }

  onOk = (value) => {
    setTimeout(() => {
      this.reload()
    }, 500)
  }

  reload = () => {
    this.table.current.reload()
  }

  componentDidMount() {
  }

  render() {
    const columns = [
      {
        title: 'Order',
        dataIndex: 'orderNo',
        key: 'orderNo',
        fixed: 'left',
        width: 100
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        filterRegex: true,
        fixed: 'left',
        // searchTextName: 'name',
        width: 200,
        render: (item, record) => {
          return record.userId && record.userId.name ? record.userId.name : ''
        }
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        filterRegex: true,
        sorter: true,
        searchTextName: 'country'
      },
      {
        title: 'Status',
        dataIndex: 'paymentStatus',
        key: 'paymentStatus',
        sorter: true,
        filters: StatusFilter.map(x => ({ value: x, text: x })),
        render: (item) => {
          return (
            <div>
              {item == 'Initiated' || item == 'Pending' ?
                <div className={'label label-primary'}>{item}</div> : null}
              {(item == 'Cancel' || item == 'Fail') ? <div className={'label label-danger'}>{item}</div> : null}
              {item == 'Success' ? <div className={'label label-success'}>{item}</div> : null}

            </div>
          )
        }
      },
      {
        title: 'Payment By',
        dataIndex: 'paymentBy',
        key: 'paymentBy'
      },
      {
        title: 'Nationality',
        dataIndex: 'nationality',
        key: 'nationality'
      },
      {
        title: 'Total Payment',
        dataIndex: 'totalPayment',
        key: 'totalPayment',
        render: (item, record) => {
          return (
            <div>{record.currencySymbol}{item}</div>
          )
        }
      },
      {
        title: 'User Info',
        dataIndex: 'contactNumber',
        key: 'contactNumber',
        width: 250,
        render: (item, record) => {
          return (
            <div>
              <div>Contact No : {record.contactNumber}</div>
              <div>Email : {record.email}</div>
            </div>
          )
        }
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (item, record) => {
          return (
            <span>{moment(item).format('DD-MMM-YYYY')}</span>
          )
        }
      }
      /* {
         key: 'actions', width: 100, render: (val) => (<React.Fragment>

           <Tooltip title='Show Details'>
             <Button className={styles.btn}
                     shape='circle'
                     onClick={() => {
                       this.openPackageInfo(val)
                     }} icon='eye' />
           </Tooltip>


         </React.Fragment>)

       }*/
    ]
    return (
      <PageHeaderWrapper
        title={'All Subscribes'}>
        {
          this.state.showPackageInfo ?
            <PackageInfo packageInfo={this.state.packageInfo}
                         visible={this.state.showPackageInfo}
                         onClose={this.closePackageInfo}
                         onSubmit={this.packageInfoSubmit}
            /> : null}

        <Card style={{ marginBottom: 10 }}>
          <h4>FILTER BY DATE</h4>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format={dateFormat}
            defaultValue={defaultValue}
            placeholder={['Start Time', 'End Time']}
            onChange={this.chooseRangerPicker}
            onOk={this.onOk}
          />
        </Card>
        <Card bordered={true}>
          <TableComp columns={columns}
                     extraProps={{ scroll: { x: 1200 } }}
                     ref={this.table} apiRequest={this.apiRequest} />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllSubscribers)
