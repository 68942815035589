import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Card,
  Tooltip,
  Button, Icon, Form
} from 'antd'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import _ from 'lodash'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import ReactHtmlParser from 'react-html-parser'
import { chooseEmailModalFxn, DisplayDate } from '../../../components/_utils/appUtils'
import { getAllArticle } from '../actions'
import { ArticleFilters } from '../articleView/articlePages'
import { SendTemplateEmail } from '../../email/actions'
import ProcessingChargesDrawer from '../drawers/ProcessChargesDrawer'

const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class AllArticle extends Component {
  state = {
    edit: false,
    journalList: [],
    volumeList: [],
    issueList: [],
    isPublished: null,
    slugJournal: null,
    slugVolume: null,
    slugIssue: null,
    articleId: null,
    filters: {},
    visibleDrawer: false,
    article: {},
    total: ''

  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let { dispatch, form: { getFieldValue } } = this.props
      let obj = {
        ...params,
        ...this.state.filters,
        articleStatus: 'Submitted Article'
      }
      let regExpFilters = []

      let data = await dispatch(getAllArticle({ ...obj, regExFilters: regExpFilters }))
      console.log(data)
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }
  reloadTable = (data) => {
    if (data.fromDate && data.toDate) {
      data['submittedDate'] = {
        $gte: data.fromDate,
        $lte: data.toDate
      }
      delete data['fromDate']
      delete data['toDate']
    }
    this.setState({ filters: data }, () => {
      this.reload()
    })
  }


  deleteArticle = async (data) => {
    let resp = await Request.deleteArticle(data)
    this.reload()
  }

  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, (item) => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    this.setState({
      journalList,
      volumeList: [],
      issueList: []
    })

  }

  getVolumeList = async (data) => {
    let obj = {
      slugJournal: data
    }
    let resp = await Request.journalVolume(obj)
    let volumeList = []
    _.each(resp, (item) => {
      volumeList.push({
        _id: item.slugVolume,
        value: item.slugVolume,
        display: item.name
      })
    })
    this.setState({
      volumeList,
      issueList: []
    })
  }

  getIssueList = async (data) => {
    let obj = {
      slugJournal: this.props.form.getFieldValue('slugJournal'),
      slugVolume: data
    }
    let resp = await Request.journalIssue(obj)
    let issueList = []
    _.each(resp, (item) => {
      issueList.push({
        _id: item.slugIssue,
        value: item.slugIssue,
        display: item.name
      })
    })
    this.setState({
      issueList
    })
  }


  componentDidMount() {
    this.getJournalList()
  }

  sendMailFxn = (record) => {
    let { dispatch } = this.props
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          id: record._id,
          fromEmail: email,
          emailType: 'acknowledgement',
          template: record.processingCharges > 0 ? 'acknowledgementWithCharges' : 'acknowledgementWithoutCharges',
          type: 'article'
        }
        let resp = await dispatch(SendTemplateEmail({
          email: obj.fromEmail,
          id: record._id,
          obj,
          template: obj.template
        }))
        if (resp && resp.success) {
          this.reload()
        }
      }
    })
  }

  render() {

    const { dispatch, form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading } = this.props
    const { journalList, volumeList, issueList } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }


    const columns = [
      {
        key: 'actions',
        width: 150,
        render: (val, record) => (
          <React.Fragment>
            {/*<Tooltip title='Edit Details'>*/}
            <a className={'roundSmallBtn'} href={`/articleView/${record._id}`}>
              View Details
            </a>
            {/*</Tooltip>*/}
            <br />
            {/*<a className={'roundSmallBtn'} href={`/article/edit/${record._id}`}>*/}
            {/*  Edit*/}
            {/*</a>*/}
            <Button className={'roundSmallBtn'} onClick={() => {
              dispatch(getPushPathWrapper('article.edit', { id: record._id }))
            }}>Edit</Button>
            {!record.acknowledgmentDate ?
              <Tooltip title={'Resend Acknowledgment'}>
                <Button className={'roundSmallBtn'} onClick={() => this.sendMailFxn(record)}>Resend Ack.</Button>
              </Tooltip> : null}
            {!record.paymentDate ?
              <Button className={'roundSmallBtn'}
                      onClick={() => this.setState({ visibleDrawer: true, article: record })}>
                Add Charges</Button> : null}
          </React.Fragment>)
      },
      {
        title: 'Submitted Date',
        key: 'submittedDate',
        dataIndex: 'submittedDate',
        width: 100,
        render: (item) => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Article Id',
        key: 'articleNo',
        dataIndex: 'articleNo'
      },
      {
        title: 'Title',
        key: 'manuScriptTitle',
        dataIndex: 'manuScriptTitle',
        render: (item) => {
          return item ? ReactHtmlParser(item) : null
        }
      },
      {
        title: 'Author Name',
        key: 'correspondingAuthor',
        dataIndex: 'correspondingAuthor'
      },
      {
        title: 'Author Email',
        key: 'correspondingAuthorsEmail',
        dataIndex: 'correspondingAuthorsEmail'
      },
      {
        title: 'Country',
        key: 'country',
        dataIndex: 'country'
      },
      {
        title: 'Resend Ack.',
        key: 'acknowledgmentDate',
        dataIndex: 'acknowledgmentDate',
        render: (item) => {
          return item ? DisplayDate(item) : null
        }
      }


    ]

    const fil = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    return (
      <PageHeaderWrapper
        title={`Submitted Article (${this.state.total})`}>
        <Card bordered={true}>
          <ArticleFilters callback={(data) => {
            this.reloadTable(data)
          }} />
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest} />
        </Card>
        {this.state.visibleDrawer ? <ProcessingChargesDrawer
          visible={this.state.visibleDrawer}
          articleId={this.state.article._id}
          article={this.state.article}
          Id={this.state.article._id}
          callback={this.reload}
          edit={false}
          onClose={() => this.setState({ visibleDrawer: false })}
        /> : null}
      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllArticle)
