import { apiUrl } from '../../settings'

export const subscriptionUrl = () => {
  return apiUrl + '/subscription'
}
export const journalsAjaxListUrl = () => {
  return apiUrl + '/journalsAjax'
}
export const allSubscriptionUrl = () => {
  return apiUrl + '/allSubscription'
}