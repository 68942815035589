import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  notification,
  Card,
  Icon,
  Row, Col, Table, Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { apiUrl as apiurl } from '../../../settings'
import Request from '../../../request'
import { connect } from 'react-redux'
import { getUrlParams } from '../../../routes'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { FormUtils } from 'sz-react-utils-lite'
import { CountriesList, JournalList } from '../../journals/actions'
import {
  ProcessList,
  ArticleTypeList,
  DesignationList,
  Countries,
  InputBox,
  CustomCkEditor, mentionsFeeds
} from '../../../components/_utils/appUtils'
import AddAuthor from '../drawers/addAuthor'
import { addArticle, singleArticle, updateArticle } from '../actions'
import { showPageLoad } from '../../../modules/actions'

@Form.create()
class UpdateArticle extends PureComponent {

  state = {
    articleId: false,
    journalListArr: [],
    volumeList: [],
    issueList: [],
    visibleAddAuthor: false,
    authors: [],
    reviewerArticleAttachment: {},
    reviewerOtherAttachment: {},
    reviewerArticleAttachmentTemp: '',
    reviewerOtherAttachmentTemp: '',
    fileKey: moment()
  }

  constructor(props) {
    super(props)
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { authors, articleId, reviewerArticleAttachment, reviewerOtherAttachment, abstract } = this.state
        delete valData['articleNo']
        let fd = new FormData()
        valData.authors = authors
        valData.abstract = abstract
        fd.append('obj', JSON.stringify(valData))
        if (reviewerArticleAttachment) {
          fd.append('reviewerArticleAttachment', reviewerArticleAttachment)
        }
        if (reviewerOtherAttachment) {
          fd.append('reviewerOtherAttachment', reviewerOtherAttachment)
        }
        let { success, message } = await dispatch(updateArticle(fd, articleId))
        if (success) {
          notification.success({
            message: message
          })
        }
      }
    })
  }

  setFormValuesFxn = async (id) => {
    const { dispatch, form } = this.props
    let { error, data } = await dispatch(singleArticle(id))
    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      setTimeout(() => {
        form.setFieldsValue({ ...data, submittedDate: moment(data.submittedDate) })
        this.setState({
          articleId: data._id,
          authors: data.authors,
          abstract: data.abstract,
          reviewerArticleAttachmentTemp: data.reviewerArticleAttachment,
          reviewerOtherAttachmentTemp: data.reviewerOtherAttachment
          //submittedDate: data.submittedDate ? moment(data.submittedDate) : null
        })
      }, 500)
    }
  }

  componentDidMount() {
    this.loadJournalList()
    let data = getUrlParams('article.edit', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setFormValuesFxn(id)
    }
  }

  loadJournalList = async () => {
    const { dispatch, form } = this.props
    let { data } = await JournalList()
    this.setState({ journalListArr: data })
  }

  addAuthorSubmit = async (data) => {
    const { form: { getFieldValue } } = this.props
    let cloneAuthors = _.clone(this.state.authors)
    let totalAuthors = getFieldValue('totalAuthors')
    if (parseInt(totalAuthors) > cloneAuthors.length) {
      cloneAuthors.push(data)
      this.setState({ authors: cloneAuthors, visibleAddAuthor: false })
    } else {
      notification.success({
        message: `You can add only ${totalAuthors} Authors!!`
      })
      this.setState({ visibleAddAuthor: false })
    }
  }
  removeAuthorSubmit = async (index) => {
    let cloneAuthors = _.clone(this.state.authors)
    cloneAuthors.splice(index, 1)
    this.setState({ authors: cloneAuthors, visibleAddAuthor: false })
  }

  addMoreAuthor = () => {
    const { form: { getFieldValue } } = this.props
    let totalAuthors = getFieldValue('totalAuthors')
    if (totalAuthors) {
      this.setState({ visibleAddAuthor: true })
    } else {
      notification.success({
        message: `Please enter Total Number Of Authors.`
      })
    }
  }

  chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  render() {

    const { form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading } = this.props
    const {
      edit,
      journalListArr,
      volumneList,
      issueList,
      visibleAddAuthor,
      reviewerArticleAttachmentTemp,
      reviewerOtherAttachmentTemp
    } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, apiurl, loading
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputSchma = {
      fields: [
        {
          label: 'Date of Submission',
          key: 'submittedDate',
          required: true,
          placeholder: 'Date of Submission',
          type: 'date'
          // onChange: (val) => {
          //   setFieldsValue({ submittedDate: val })
          // }
        },
        {
          label: 'Country',
          key: 'country',
          required: true,
          showSearch: true,
          options: Countries,
          keyAccessor: x => x.name,
          valueAccessor: x => x.name,
          placeholder: 'Country',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ country: val })
          }
        },
        {
          label: 'Journal',
          key: 'journalId',
          required: true,
          showSearch: true,
          options: journalListArr,
          keyAccessor: x => x._id,
          valueAccessor: x => x.name,
          placeholder: 'Journal',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ journalId: val })
          }
        },
        {
          label: 'Process',
          key: 'processType',
          required: true,
          options: ProcessList,
          placeholder: 'Process Type',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ processType: val })
          }
        },
        {
          label: 'Article No',
          key: 'articleNo',
          required: true,
          placeholder: 'Article No',
          disabled: true,
          onChange: (val) => {
            setFieldsValue({ articleNo: val.target.value.toUpperCase() })
          }
        },

        {
          label: 'Article Type',
          key: 'articleType',
          required: true,
          options: ArticleTypeList,
          placeholder: 'Article Type',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ articleType: val })
          }
        },
        {
          label: 'Article Title',
          key: 'manuScriptTitle',
          required: true,
          placeholder: 'Article Title',
          span: 24,
          type: 'editor'
        },
        {
          label: 'Manuscript Area of Research',
          key: 'areaOfResearch',
          required: true,
          placeholder: 'Manuscript Area of Research',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        {
          label: 'Processing Charges',
          key: 'processingCharges',
          required: true,
          placeholder: 'Processing Charges'
          //type: 'number'
        },
        {
          label: 'Publication Charges',
          key: 'publicationCharges',
          required: true,
          placeholder: 'Publication Charges'
          //type: 'number'
        },
        {
          label: 'Abstract',
          key: 'abstract',
          required: true,
          placeholder: 'Abstract',
          type: 'editor',
          rows: 2,
          span: 24,
          customField: (
            <Col>
              <CustomCkEditor title={'Abstract'}
                              feeds={mentionsFeeds}
                              html={this.state.abstract}
                              onChange={(data) => {
                                this.setState({ 'abstract': data })
                              }}
              />
            </Col>)
        },
        {
          label: 'Reviewer Article Attachment',
          customField: (
            <InputBox title={`Reviewer Article Attachment`} className={'rowFlex'}>
              <Input type={'file'} name={'reviewerArticleAttachment'} id={'reviewerArticleAttachment'}
                     className={'form-control'} key={this.state.fileKey}
                     onChange={(e) => {
                       this.chooseDocument(e)
                     }} />
              {reviewerArticleAttachmentTemp ?
                <a className={'linkBtn'} target={'_blank'} href={`${reviewerArticleAttachmentTemp.path}`}>
                  <Icon type={'eye'}></Icon>
                </a> : null}
            </InputBox>
          )
        },
        {
          label: 'Reviewer Other Attachment',
          customField: (
            <InputBox title={`Reviewer Other Attachment`} className={'rowFlex'}>
              <Input type={'file'} name={'reviewerOtherAttachment'} id={'reviewerOtherAttachment'}
                     className={'form-control'} key={this.state.fileKey}
                     onChange={(e) => {
                       this.chooseDocument(e)
                     }} />

              {reviewerOtherAttachmentTemp ?
                <a className={'linkBtn'} target={'_blank'} href={`${apiurl}/uploads/${reviewerOtherAttachmentTemp}`}>
                  <Icon type={'eye'}></Icon>
                </a> : null}
            </InputBox>
          )
        },
        {
          label: 'Special Note',
          key: 'specialNote',
          placeholder: 'Special Note',
          type: 'textArea',
          rows: 2,
          span: 24
        }

      ],
      authorFields: [
        {
          label: 'Total Number Of Authors',
          key: 'totalAuthors',
          required: true,
          placeholder: 'Total Number Of Authors'
        },
        {
          label: 'Corresponding Author',
          key: 'correspondingAuthor',
          required: true,
          placeholder: 'Corresponding Author'
        },
        {
          label: 'Corresponding Author Email',
          key: 'correspondingAuthorsEmail',
          required: true,
          placeholder: 'Authors Email'
        },
        {
          label: 'Corresponding Author Secondary Email',
          key: 'correspondingAuthorEmail2',
          placeholder: 'Secondary Email'
        },
        {
          label: 'Corresponding Author Contact',
          key: 'correspondingAuthorContact',
          placeholder: 'Corresponding Author Contact'
        },
        {
          label: 'Country',
          key: 'correspondingAuthorCountry',
          required: true,
          showSearch: true,
          placeholder: 'Country',
          options: Countries,
          keyAccessor: x => x.name,
          valueAccessor: x => x.name,
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ correspondingAuthorCountry: val })
          }
        },
        {
          label: 'Author Designation',
          key: 'correspondingAuthorDesignation',
          required: true,
          placeholder: 'Author Designation',
          options: DesignationList,
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ correspondingAuthorDesignation: val })
          }
        },
        {
          label: 'Institute Affiliated With',
          key: 'correspondingAuthorAffiliation',
          required: true,
          placeholder: 'Institute Affiliated With',
          type: 'textArea',
          rows: 3
        }
      ]

    }

    let columns = [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        render: (item, record) => {
          return (
            <React.Fragment>
              {record.title} {item}
              {record.contactNo ? <div>Mob : {record.contactNo}</div> : null}
              <div>
                {record.country}
              </div>

            </React.Fragment>
          )
        }
      },
      {
        title: 'Affiliated',
        key: 'instituteAffiliated',
        dataIndex: 'instituteAffiliated'
      },
      {
        title: 'Designation',
        key: 'designation',
        dataIndex: 'designation'
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
        render: (item, record) => {
          return (
            <React.Fragment>
              {item}
              {record.secondaryEmail ? <div>
                Sec. {record.secondaryEmail}
              </div> : null}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        render: (item, record, index) => {
          return (
            <React.Fragment>
              <Tooltip title='Remove Author'>
                <Button size={'small'} icon={'cross'} onClick={() => this.removeAuthorSubmit(index)} />
              </Tooltip>
            </React.Fragment>
          )
        }
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Update Article'}>
        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              <Col span={12}>
                <Card title='Article Details' bordered={true}>
                  <Row gutter={16}>
                    {inputSchma.fields.map((item, key) => {
                      return (
                        <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                             key={key}>
                          {item.customField ? item.customField :
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />}
                        </Col>
                      )
                    })}
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card title='Author Details' bordered={true}>
                  <Row gutter={16}>
                    {inputSchma.authorFields.map((item, key) => {
                      return (
                        <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                             key={key}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout} />
                        </Col>
                      )
                    })}
                    <Col span={24}>
                      <Button onClick={() => this.addMoreAuthor()}>
                        <Icon type={'plus'} /> Add Author
                      </Button>
                    </Col>
                    <Col span={24} className={'mt10'}>
                      <Table bordered={true} columns={columns} dataSource={this.state.authors} size={'small'}
                             pagination={false} />
                    </Col>
                  </Row>
                </Card>
              </Col>

            </Row>

            <Form.Item style={{ marginTop: 32 }}>
              <Button type='primary' htmlType='submit'>
                SUBMIT
              </Button>
            </Form.Item>

          </Form>


          {visibleAddAuthor ?
            <AddAuthor onClose={() => this.setState({ visibleAddAuthor: false })}
                       visible={visibleAddAuthor} addAuthorSubmit={this.addAuthorSubmit} /> : null}
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateArticle)
