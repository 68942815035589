import React, { Component, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Form, Row, Col
} from 'antd'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import { apiUrl } from '../../../settings'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { TableComp, FormUtils } from 'sz-react-utils-lite'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { DisplayDate, formItemLayout } from '../../../components/_utils/appUtils'
import { getAllArticle } from '../actions'
import { ArticleFilters } from '../articleView/articlePages'
import { SelectReviewer } from '../reviewer/selectReviewer'

const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class UnderProcessArticle extends Component {
  state = {
    edit: false,
    journalList: [],
    filters: {},
    ids: {},
    visibleSelectReview: false,
    article: {},
    total: ''
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let { dispatch, form: { getFieldValue } } = this.props
      let obj = {
        ...params,
        ...this.state.filters,
        articleStatus: 'Under Process'
      }
      let regExpFilters = []

      let data = await dispatch(getAllArticle({ ...obj, regExFilters: regExpFilters }))
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }

  events = {
    showDrawer: (article) => {
      let ids = {}
      _.each(article.selectedReviewer, (value) => {
        if (value.reviewerId && value.reviewerId._id) {
          ids[value.reviewerId._id] = true
        }
      })
      this.setState({
        ids, visibleSelectReview: true, article
      })

    },
    hideDrawer: () => {
      this.setState({
        ids: {}, visibleSelectReview: false, article: {}
      }, () => {
        this.reload()
      })
    }
  }

  deleteArticle = async (data) => {
    let resp = await Request.deleteArticle(data)
    this.reload()

  }

  getVolumeList = async (data) => {
    let obj = {
      slugJournal: data
    }
    let resp = await Request.journalVolume(obj)
    let volumeList = []
    _.each(resp, (item) => {
      volumeList.push({
        _id: item.slugVolume,
        value: item.slugVolume,
        display: item.name
      })
    })
    this.setState({
      volumeList,
      issueList: []
    })
  }

  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, (item) => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    this.setState({
      journalList
    })

  }
  getIssueList = async (data) => {
    let obj = {
      slugJournal: this.props.form.getFieldValue('slugJournal'),
      slugVolume: data
    }
    let resp = await Request.journalIssue(obj)
    let issueList = []
    _.each(resp, (item) => {
      issueList.push({
        _id: item.slugIssue,
        value: item.slugIssue,
        display: item.name
      })
    })
    this.setState({
      issueList
    })
  }

  reloadTable = (data) => {
    if (data.fromDate && data.toDate) {
      data['paymentDate'] = {
        $gte: data.fromDate,
        $lte: data.toDate
      }
      delete data['fromDate']
      delete data['toDate']
    }
    this.setState({ filters: data }, () => {
      this.reload()
    })
  }

  componentDidMount() {
  }

  render() {

    const { dispatch, form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading } = this.props
    const { journalList, visibleSelectReview, ids, article } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }
    const columns = [
      {
        key: 'actions',
        width: 150,
        render: (val, record) => (<React.Fragment>
          <a className={'roundSmallBtn'} href={`/articleView/${record._id}`}>
            View Details
          </a>
          <Button className={'roundSmallBtn'} onClick={() => this.events.showDrawer(record)}>
            Select Reviewer
          </Button>
        </React.Fragment>)

      },
      {
        title: 'Submitted Date',
        key: 'submittedDate',
        dataIndex: 'submittedDate',
        width: 100,
        render: (item) => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Article Id',
        key: 'articleNo',
        dataIndex: 'articleNo',
        width: '85px'
      },
      {
        title: 'P.C. Received',
        key: 'paymentDate',
        dataIndex: 'paymentDate',
        width: 100,
        render: (item) => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Title',
        key: 'manuScriptTitle',
        dataIndex: 'manuScriptTitle',
        render: (item) => {
          return item ? ReactHtmlParser(item) : null
        }
      },
      {
        title: 'Area Of Research',
        key: 'areaOfResearch',
        dataIndex: 'areaOfResearch'
      },
      {
        title: 'Author Name',
        key: 'correspondingAuthor',
        dataIndex: 'correspondingAuthor'
      },
      {
        title: 'Country',
        key: 'country',
        dataIndex: 'country'
      }


    ]

    const fil = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    return (
      <PageHeaderWrapper
        title={`Under Process Article (${this.state.total})`}>
        <Card bordered={true}>
          <ArticleFilters callback={(data) => {
            this.reloadTable(data)
          }} />
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest} />
        </Card>

        {visibleSelectReview ?
          <SelectReviewer article={article} ids={ids}
                          onClose={() => this.events.hideDrawer()}
                          visible={visibleSelectReview} /> : null}

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnderProcessArticle)
