import { notification } from 'antd'
import { JournalListUrl, CountriesUrl, journalUrl, getWebPageUrl, updateWebPageUrl } from '../apis'
import { getToken, customAxios as axios } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import update from 'immutability-helper'

export const JournalList = async (filters) => {
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(JournalListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const CountriesList = async () => {
  let { data } = await axios.get(CountriesUrl())
  return data
}


export const UpdateJournal = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(journalUrl(), valData, getToken())
  if (data.error) {
    dispatch(hidePageLoad())
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}


export const AddJournal = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(journalUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const GetJournal = (filter) => async (dispatch) => {
  let config = {
    params: { ...filter },
    ...getToken()
  }
  dispatch(showPageLoad())
  let { data } = await axios.get(journalUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const GetWebPage = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getWebPageUrl(), valData, getToken())
  /*if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }*/
  dispatch(hidePageLoad())
  return data
}
export const UpdateWebPage = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateWebPageUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}