import React, { PureComponent } from 'react'
import {
  Form,
  Button,
  notification,
  Card, Col, Row
} from 'antd'
import _ from 'lodash'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { addSubscription } from '../actions'
import GetEachFormFields from '../../../components/_utils/appFormUtils'


@Form.create()
class AddSubscription extends PureComponent {

  state = {
    journalList: [],
    nationalityArr: [{ value: 'Indian', display: 'Indian', _id: 'Indian' }, {
      value: 'Foreign',
      display: 'Foreign',
      _id: 'Foreign'
    }]

  }

  constructor(props) {
    super(props)
  }


  handleSubmit = e => {
    const { dispatch, form } = this.props
    let { journalList } = this.state
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        dispatch(showLoader())
        let findJournal = _.find(journalList, (item, key) => {
          return item._id == valData.journalId
        })
        if (findJournal) {
          valData.journalName = findJournal.display
        }
        let x = await dispatch(addSubscription(valData))
        let { error, message } = x
        dispatch(hideLoader())

        if (!error) {
          notification.success({
            message: message
          })

          form.resetFields()
        } else {
          notification.error({
            message: message,
            description: message
          })
        }


      }
    })
  }

  setFormValues = async (id) => {

    const { dispatch, form } = this.props

    dispatch(showLoader())

    let { error, data } = await Request.getSubscription({ id })

    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      form.setFieldsValue(data)
    }

    dispatch(hideLoader())

  }

  journalAjax = async () => {
    let journalListArr = await Request.getJournalAjax()
    let journalList = []
    _.each(journalListArr, (item, key) => {
      journalList.push({ value: item, display: item.name, _id: item._id })
    })

    this.setState({ journalList })
  }

  componentDidMount() {
    this.journalAjax()
  }


  render() {

    const { form: { getFieldDecorator, setFieldsValue } } = this.props
    const { journalList, nationalityArr } = this.state


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }

    }

    const inputSchma = {
      fields: [
        {
          label: 'Journal',
          key: 'journalId',
          required: true,
          placeholder: 'Journal',
          type: 'select',
          options: journalList,
          onChange: (val) => {
            setFieldsValue({ journalId: val })
          }
        },

        /*{
          label: 'Nationality',
          key: 'nationality',
          required: true,
          placeholder: 'Nationality',
          type: 'select',
          options: nationalityArr,
          onChange: (val) => {
            setFieldsValue({ nationality: val })
          }
        },*/
        {
          label: 'Curr. Vol Indian Online Price',
          key: 'indianOnlinePrice',
          required: true,
          placeholder: 'Curr. Vol Indian Online Price',
          type: 'number',
          // span: 4,
          onChange: (val) => {
            setFieldsValue({ indianOnlinePrice: val })
          }
        },
        {
          label: 'Curr. Vol Indian Print Price',
          key: 'indianPrintPrice',
          required: true,
          // span: 4,
          placeholder: 'Curr. Vol Indian Print Price',
          type: 'number',
          onChange: (val) => {
            setFieldsValue({ indianPrintPrice: val })
          }
        },
        {
          label: 'Curr. Vol Foreign Price',
          key: 'foreignPrice',
          required: true,
          placeholder: 'Curr. Vol Foreign Price',
          type: 'number',
          onChange: (val) => {
            setFieldsValue({ foreignPrice: val })
          }
        },
        {
          label: 'Single Article Indian Price',
          key: 'articleIndianPrice',
          required: true,
          placeholder: 'Single Article Indian Price',
          type: 'number',
          onChange: (val) => {
            setFieldsValue({ articleIndianPrice: val })
          }
        },
        {
          label: 'Single Article Foreign Price',
          key: 'articleForeignPrice',
          required: true,
          placeholder: 'Single Article Foreign Price',
          type: 'number',
          onChange: (val) => {
            setFieldsValue({ articleForeignPrice: val })
          }
        }
      ]

    }
    return (
      <PageHeaderWrapper
        title={'Add Subscription'}>


        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark>
            <Row gutter={16}>
              {inputSchma.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 6} md={item.span ? item.span : 6} sm={12} xs={24}
                       key={key}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout} />
                  </Col>
                )
              })}
            </Row>
            <Form.Item style={{ marginTop: 10 }}>
              <Button type='primary' htmlType='submit'>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>


      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSubscription)
