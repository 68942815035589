import React, { useEffect, useState } from 'react'
import { Modal, Select, notification, Button } from 'antd'
import { getSingleCompanyEmailNew } from '../../containers/onlineSubmission/actions'
import { useDispatch } from 'react-redux'
import { InputBox } from './appUtils'

let { Option } = Select
const { confirm } = Modal

const ChooseEmailMainComponent = (props) => {
  let { title } = props
  let [emails, setEmails] = useState([])
  let [email, setEmail] = useState('')
  useEffect(() => {
    loadEmails()
  }, [])

  const loadEmails = async () => {
    let { data } = await getSingleCompanyEmailNew()
    setEmails(data.mailers)
  }

  const submitFxn = () => {
    if (!email) {
      notification.error({ message: 'Select From Email' })
      return
    }
    props.onSubmit(email)
  }

  return (
    <div>
      <InputBox title={title ? 'Select From Email' : ''}>
        <Select onChange={(e) => setEmail(e)}>
          {emails && emails.length ? emails.map((item, key) => {
            return (
              <Option key={key} value={item.userName}>{item.userName}</Option>
            )
          }) : null}
        </Select>

      </InputBox>
      <div className={'alignRight'}>
        <Button onClick={() => props.onCancel()}>
          Cancel
        </Button> {'  '}
        <Button onClick={() => submitFxn()} type={'primary'}>
          Done
        </Button>
      </div>
    </div>
  )
}


const chooseEmailModalFxn = (props) => {
  let { title = null } = props
  let modalData = (
    Modal.info({
      title: title ? title : 'Select From Email',
      className: 'chooseEmailModal',
      content: (
        <ChooseEmailMainComponent
          title={title}
          onCancel={() => {
            modalData.destroy()
            if (props.cancel) {
              props.cancel()
            }
          }}
          onSubmit={(email) => {
            modalData.destroy()
            props.callback(email)
          }} />
      ),
      icon: false,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {

      }
    })
  )

  return (
    modalData
  )
}

export default chooseEmailModalFxn
