import React from 'react'
import styles from './login.less'
import {
  Form, Icon, Input, Button, Checkbox, notification
} from 'antd'

import Request from '../../request'
import { showLoader, hideLoader } from '../../modules/actions'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'

class ForgetPasswordForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  handleSubmit = (e) => {
    const { dispatch } = this.props
    e.preventDefault()

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        dispatch(showLoader())
        let obj = {
          email: values.email,
          from: 'software'
        }
        let x = await Request.forgotPassword({ obj })

        dispatch(hideLoader())

        if (!x.error) {
          localStorage.setItem('token', x.token)
          localStorage.setItem('user', JSON.stringify(x.user))
          dispatch({
            type: 'SET_CURRENT_USER',
            user: x.user
          })

          setTimeout(() => {
            dispatch(push('/'))
          }, 300)

        } else {
          notification.error({
            message: 'Invalid Login',
            description: x.message
          })

        }
      }
    })

  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { loading } = this.props
    return (
      <Form onSubmit={this.handleSubmit} className={styles.main}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please enter your email !' }]
          })(
            <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Email' />
          )}
        </Form.Item>
        <Form.Item>
          <Button type='primary' loading={loading} htmlType='submit' className={styles.button}>
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

const WrappedForgetPasswordForm = Form.create()(ForgetPasswordForm)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedForgetPasswordForm)
