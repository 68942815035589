import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, DatePicker
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import moment from 'moment'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'
// import PackageInfo from '../packageInfo/index';

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'
const defaultValue = [moment().startOf('month'), moment().endOf('month')]

class AllSubscribers extends Component {
  state = {
    showPackageInfo: false,
    packageInfo: {},
    time: {
      key: 'date',
      from: null,
      to: null
    }
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()

  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let objFilter = {
        ...params,
        regExFilters: ['name', 'country', 'status'],
        dateFilter: this.state.time
      }
      let data = await Request.getAllPayments(objFilter)
      resolve(data)
    })
  }

  openPackageInfo = (data) => {
    this.setState({ showPackageInfo: true, packageInfo: data })
  }

  closePackageInfo = () => {
    this.setState({ showPackageInfo: false })
  }

  packageInfoSubmit = (reminObj) => {
    this.closePackageInfo()
  }

  chooseRangerPicker = (date, dateString) => {
    this.setState({
      time: {
        key: 'date',
        from: date[0],
        to: date[1]
      }
    })
    if (date[0] == undefined) {
      setTimeout(() => {
        this.reload()
      }, 500)
    }
  }

  onOk = (value) => {
    setTimeout(() => {
      this.reload()
    }, 500)
  }

  reload = () => {
    this.table.current.reload()
  }

  componentDidMount() {
  }

  render() {
    const columns = [
      {
        title: 'User Info',
        key: 'name',
        dataIndex: 'name',
        width: 200,
        render: (item, record) => {
          return (
            <div>
              <div>Name : {item}</div>
              <div>Contact No : {record.phoneNo}</div>
              <div>Email : {record.email}</div>
            </div>
          )
        }
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: 350
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        filterRegex: true,
        searchTextName: 'status',
        render: (item) => {
          return (
            <div>
              {item == 'Initiated' || item == 'Paid' ?
                <div className={styles.warningLabel}>{item}</div> : null}


            </div>
          )
        }
      },
      {
        title: 'Payment Mode',
        dataIndex: 'paymentMode',
        key: 'paymentMode'
      },
      {
        title: 'Reference Id',
        dataIndex: 'referenceId',
        key: 'referenceId'
      },

      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 280,
        render: (item, record) => {
          return (
            <div>
              {item}
              <br />
              <br />
              {record.journal && record.journal.journalName ? <strong>
                {record.journal.journalName}
              </strong> : null}
            </div>
          )
        }
      },
      {
        title: 'Type Of Charges',
        dataIndex: 'typeOfCharges',
        key: 'typeOfCharges',
        width: 150
      },
      {
        title: 'Total Payment',
        dataIndex: 'totalPayment',
        key: 'totalPayment',
        render: (item) => {
          return (
            <div>
              Rs. {item}
            </div>
          )
        }
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (item) => {
          return (
            <div>
              {item ? moment(item).format('DD-MMM-YYYY') : null}
            </div>
          )
        }
      }

    ]
    return (
      <PageHeaderWrapper
        title={'All Payments'}>
        {/* {
                    this.state.showPackageInfo ? <PackageInfo packageInfo={this.state.packageInfo}
                        onClose={this.closePackageInfo}
                        onSubmit={this.packageInfoSubmit}
                    /> : null}*/}

        <Card style={{ marginBottom: 10 }}>
          <h4>FILTER BY DATE</h4>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format={dateFormat}
            defaultValue={defaultValue}
            placeholder={['Start Time', 'End Time']}
            onChange={this.chooseRangerPicker}
            onOk={this.onOk}
          />
        </Card>
        <Card bordered={true}>
          <TableComp columns={columns}
                     extraProps={{ scroll: { x: 1200 } }}
                     ref={this.table} apiRequest={this.apiRequest} />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllSubscribers)
